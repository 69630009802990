import Sidebar from './Sidebar'
import ContentHeader from './ContentHeader'
import Topbar from './Topbar'
import Statistics from './Statistics'
import ContentDashboard from './ContentDashboard'
import Footer from './Footer'
import { useDispatch} from 'react-redux'
import { useEffect, useState } from 'react'
import {  useNavigate } from 'react-router-dom'
import { disableBackButton } from '../features/auth/disableBackButton'
import { findAllUsers } from '../features/users/userSlice'
import jwt_decode from "jwt-decode"
import { countAllAudiences } from '../features/messageries/messagerieSlice'
import SidebarUser from './user_normal/SidebarUser'
import StatisticsUser from './user_normal/StatisticsUser'


const DashboardAdmin =  () => {
  const [authToken, setAuthToken] = useState(null) 
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const token = localStorage.getItem('token')
  const decodedToken = token ? jwt_decode(token) : null
 
 
 
  useEffect(() => {
    if (localStorage.getItem('token')) {
      setAuthToken(localStorage.getItem('token'))      

      //désactive le button retour du navigateur 
      disableBackButton()

      dispatch(findAllUsers())  
      dispatch(countAllAudiences())
     
      
    } else {
      navigate("/")
    }
  }, [authToken, navigate, dispatch])

  
  
  if (authToken && decodedToken?.roles.length === 2) {

       return (
         <div className='dashboard_wrapper'>
           <Sidebar />
           <div className='main_content'>
             <Topbar />
             <div className='content'>
               <ContentHeader />
               <Statistics />
               <ContentDashboard />
             </div>
             <Footer />
           </div>
         </div>
       )
  }
  if (authToken && decodedToken?.roles.length === 1) {
    return (
      <div className='dashboard_wrapper'>
        <SidebarUser />
        <div className='main_content'>
          <Topbar />
          <div className='content'>
            <ContentHeader />
            <StatisticsUser />
            <ContentDashboard />
          </div>
          <Footer />
        </div>
      </div>
    )
  }
   
}

export default DashboardAdmin