import { configureStore } from "@reduxjs/toolkit"

import agenceReducer from "../features/agences/agenceSlice"
import userReducer from "../features/users/userSlice"
import folderReducer from "../features/dossiers/folderSlice"
import messageReducer from "../features/messages/messageSlice"
import messagerieReducer from "../features/messageries/messagerieSlice"




const store = configureStore({
    reducer: {
        agence: agenceReducer,
        user: userReducer,
        folder: folderReducer,
        message: messageReducer,
        messagerie: messagerieReducer
        
    }
})

export default store