
import React from 'react'
import Topbar from '../../components/Topbar'
import Sidebar from '../../components/Sidebar'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { usersByAgence } from '../agences/agenceSlice'
import { transferFolder, transfertFolder } from './folderSlice'

const TransfertDossier = () => {

    const dispatch = useDispatch()

    const [authToken, setAuthToken] = useState(null)
    const navigate = useNavigate()
     const location = useLocation()
     const singleUser = location.state
       //console.log(singleUser)
    const { usersByAgences } = useSelector((state) => state.agence)
    const [users, setUsers] = useState([])
    const [idDestination, setIdDestination] = useState()

    useEffect(() => {
        if (localStorage.getItem('token')) {
            setAuthToken(localStorage.getItem('token'))
            dispatch(usersByAgence(singleUser.agence))

        } else {
            navigate("/")
        }
    }, [navigate, dispatch])
    useEffect(() => {
        setUsers(usersByAgences)

    }, [usersByAgences])
    const handleTransfert = (event) => { 
        event.preventDefault()
      
        let idCurrentUser = singleUser.id
        dispatch(transfertFolder({idCurrentUser,idDestination}))
        
    }
  
   // let newUserTable = users?.filter(user => user.email !== singleUser?.email)
    if (authToken) {
        return (
            <>
                <div className='dashboard_wrapper'>
                    <Sidebar />
                    <div className='main_content'>
                        <Topbar />
                        <div className='content'>
                            <div className="content_header text-light">
                                <h1 className="fs-2 kanit-font">Dossiers</h1>
                                <div className="flex opacity-5">
                                    <i className="bi-house-fill"></i>
                                    <span>- Dossier - Dossiers</span>
                                </div>
                            </div>
                            {/* <!-- right here is a wide card where the majority of thing will be placed --> */}
                            <div className='d-flex'>
                                <div className="card bg-white col-6 me-3 h-50">
                                    <div className="card-header bold ">
                                       Utilisateur(Propriétaire des dossiers)
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text text-muted">
                                            <strong>Civilité :</strong>{singleUser?.civilite}<br />
                                            <strong>Nom :</strong> {singleUser?.nom} <br />
                                            <strong>Prénom :</strong> {singleUser?.prenom} <br />
                                            <strong>Email :</strong> {singleUser?.email} <br />
                                            <strong>Agence :</strong> {singleUser?.agence?.nom} <br />
                                            <strong >Nombre de dossiers à transférer :</strong> <span className='fs-4 fw-bold'>{singleUser?.dossiers?.length} </span><br />
                                           
                                        </p>
                                    </div>
                                </div>
                                <div className="card bg-white col-6">
                                    <div className="card">
                                        <div className="card-header ">
                                            <h5 className="card-title">
                                              Selectionner l'utilisateur(cible) qui prendra en charge l'ensemble des dossiers à transférer
                                            </h5>
                                        </div>
                                        <div className="card-body px-0">
                                            <form className="ms-5" onSubmit={handleTransfert}>
                                                <div className="col-12 col-lg-6 mb-3">
                                                    <select
                                                        name="user"
                                                        id="user"
                                                         value={idDestination?.id}
                                                        onChange={(e) => setIdDestination(parseInt(e.target.value))}
                                                        className="form-control text-secondary"
                                                         style={{ height: "50px" }}
                                                    >    <option value="">-- Liste des utilisateurs de l'agence --</option>
                                                        <option value="">{(users?.nom + " " + users?.prenom)}</option>
                                                        {users?.map((user) => {
                                                            if (user.email !== singleUser?.email) {
                                                               return (
                                                                    <option key={user.id} value={user?.id}>{user.nom + " " + user.prenom}</option>
                                                                ) 
                                                            } 
                                                            return null
                                                        }                                                       
                                                        )}
                                                    </select>
                                                </div>
                                                <div className="text-center">
                                                    {
                                                        singleUser?.dossiers?.length > 0 ? <button type='submit' className="btn_custom col-6 d-flex justify-content-center "
                                                            style={{ backgroundColor: "#5e72e4" }}>Valider le transfert
                                                        </button>
                                                            : <span>Aucun dossier à transférer</span>
                                                    }
                                                 
                                                   
                                                </div>
                                            </form> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return null
    }
}

export default TransfertDossier