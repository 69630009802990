import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Sidebar from '../../components/Sidebar'
import Topbar from '../../components/Topbar'
import { useDispatch, useSelector } from 'react-redux'
import { usersByAgence } from './agenceSlice'
import Loader from '../../components/Loader'
import { Pagination } from 'reactstrap'

const UserByAgence = () => {

    const [currentPage, setCurrentPage] = useState(0)
    const [perPage, setPerPage] = useState(9)
    const location = useLocation()
    const singleAgence = location.state
    const { usersByAgences } = useSelector((state) => state.agence)
    const dispatch = useDispatch()
    const [authToken, setAuthToken] = useState(null)
    const navigate = useNavigate()
  
    useEffect(() => {

        const token = localStorage.getItem('token')
        if (token) {
            setAuthToken(token)
            dispatch(usersByAgence(singleAgence))
        } else {
            navigate("/")
        }
    }, [dispatch])
      
  if (authToken) {
      return (
          <div className='dashboard_wrapper'>
              <Sidebar />
              <div className='main_content'>
                  <Topbar />
                  <div className='content'>
                      <div className="content_header text-light">
                          <h1 className="fs-2 kanit-font">Utilisateurs</h1>
                          <div className="flex opacity-5">
                              <i className="bi-house-fill"></i>
                              <span>- Tables - Utilisateurs</span>
                          </div>
                      </div>
                      {/* <!-- right here is a wide card where the majority of thing will be placed --> */}
                      <div className="card bg-white">
                          <div className="card-header bg-light">
                              <div className="d-flex justify-content-between">
                                  <h2 className="fs-5 m-0 p-2">Liste des utilisateurs</h2>
                                  <Link to="/admin/utilisateurs/new" className="btn_custom">Créer un utilisateur</Link>
                              </div>
                          </div>
                          <div className="card-body">
                              <div className="table-responsive">
                                  <table className="table table-sortable">
                                      <thead>
                                          <tr>
                                              <th data-type="alpha" className='text-secondary small'>Nom</th>
                                              <th data-type="alpha" className='text-secondary small'>Prénom</th>
                                              <th data-type="alpha" className='text-secondary small'>Email</th>
                                              <th data-type="alpha" className='text-secondary small'>Agence</th>
                                              <th className='text-secondary'>Action</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {
                                              Object.keys(usersByAgences).length ?
                                                  usersByAgences.map((user) => {
                                                      return (
                                                          <tr key={user.id}>
                                                              <td className='small'>{user.nom}</td>
                                                              <td className='small'>{user.prenom}</td>
                                                              <td className='small'>{user.email}</td>
                                                              <td className='small'>{user.agence.nom}</td>
                                                              <td className='d-flex gap-1'>
                                                                  <Link to={`/admin/utilisateurs/${user.id}`} state={user}
                                                                      className="btn btn-primary" style={{ backgroundColor: "#5e72e4" }}
                                                                      data-toggle="tooltip"
                                                                      data-placement="top" title="Editer l'utilisateur">
                                                                      <i className="bi bi-gear-fill text-white"></i>
                                                                  </Link>
                                                                  <Link to={`/admin/transfertdossiers/${user.id}`} state={user}
                                                                      className="btn btn-secondary bg-secondary" data-toggle="tooltip"
                                                                      data-placement="top"
                                                                      title="Transfert des dossiers">
                                                                      <i className="bi bi-database-fill "></i>
                                                                  </Link>
                                                                  <Link to="/admin/userByDossier/1" className="btn btn-secondary"
                                                                      style={{ backgroundColor: "#135b6c" }} data-toggle="tooltip"
                                                                      data-placement="top"
                                                                      title="Liste des dossiers">
                                                                      <i className="bi bi-list text-white"></i>
                                                                  </Link>
                                                              </td>
                                                          </tr>
                                                      )
                                                  })
                                                  : <Loader colSpan={6} />
                                          }
                                      </tbody>
                                  </table>
                              </div>
                              <Pagination
                                  datadisplay={usersByAgences}
                                  perpage={perPage}
                                  setCurrentPage={setCurrentPage}
                              />
                          </div>
                      </div>
                  </div>

                  <Footer />
              </div>

          </div>
      )
  } else {
      return null
  }
}

export default UserByAgence