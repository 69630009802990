import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../images/logo-script.png'
import logoMobile from '../../images/logo-script-mobile.png'

const SidebarUser = () => {

    const navigate = useNavigate()

    const handleRedirect = () => {
        navigate("/admin/dashboard")
    }
    return (
        <div className="sidebar">
            {/* <!-- the sidebar header --> */}
            <div className="side_header">
                <div className="logo_md" onClick={handleRedirect}>
                    <img
                        src={logo}
                        srcSet={`${logoMobile} 480w, ${logo} 768w`}
                        alt="Logo Elbaz Gabay Cohen"
                        className="h-cover cursor-pointer"
                        draggable="false"
                    />
                </div>

                <div className="shrink" >
                    <i className="bi-list"></i>
                </div>
            </div>

            {/* the siedbar content */}
            <nav className="side_links">
                <Link to="/admin/dashboard" className="side_link active">
                    <i className="bi-speedometer text-primary me-3"></i>
                    <span>Tableau de bord</span>
                </Link>
                <Link to="/admin/dossiers" className="side_link">
                    <i className="bi-folder text-primary me-3"></i>
                    <span>Dossiers</span>
                </Link>

                <Link to="/admin/recherche" className="side_link">
                    <i className="bi-search text-primary me-3"></i>
                    <span>Recherche</span>
                </Link>

                <Link to="/admin/dossiersarchives" className="side_link">
                    <i className="bi-bookmarks text-primary me-3"></i>
                    <span>Dossiers archivés</span>
                </Link>

                <Link to="/admin/agenda" className="side_link">
                    <i className="bi-calendar2-week text-primary me-3"></i>
                    <span>Agenda</span>
                </Link>
            </nav>
        </div>
    )
}

export default SidebarUser