import React from 'react'
import './assets/css/style.css'
import LoginForm from './features/auth/LoginForm'
import DashboardAdmin from './components/DashboardAdmin'
import { Route, Routes } from 'react-router-dom'
import Agences from './features/agences/Agences'
import Profile from './features/users/Profile'
import AddAgence from './features/agences/AddAgence'
import UpdateAgence from './features/agences/UpdateAgence'
import ListUsers from './features/users/ListUsers'
import AddUsers from './features/users/AddUsers'
import UpdateUser from './features/users/UpdateUser'
import UserByAgence from './features/agences/UserByAgence'
import ListFolders from './features/dossiers/ListFolders'
import AddFolder from './features/dossiers/AddFolder'
import UpdateFolder from './features/dossiers/UpdateFolder'
import UserByFolder from './features/dossiers/UserByFolder'
import FolderToValidate from './features/dossiers/FolderToValidate'
import DossiersArchives from './features/dossiers/DossiersArchives'
import ListMessages from './features/messages/ListMessages'
import AddMessage from './features/messages/AddMessage'
import UpdateMessage from './features/messages/UpdateMessage'
import RechercheDossier from './features/dossiers/RechercheDossier'
import ConsulterDossier from './features/dossiers/ConsulterDossier'
import TransfertDossier from './features/dossiers/TransfertDossier'
import Messagerie from './features/messageries/Messagerie'
//import DashboardUser from './components/user_normal/DashboardUser'
import Agenda from './components/Agenda'
import ResetPassword from './features/auth/ResetPassword'
import Supports from './features/users/Supports'

function App() {
  return (

    <Routes>
      <Route path='/' exact element={<LoginForm />} />
      <Route path='/admin/forgotpassword' exact element={<ResetPassword />} />
      <Route path='/admin/dashboard' element={<DashboardAdmin />} />
      <Route path='/admin/agenda' element={<Agenda />} />
      <Route path='/admin/agences' element={<Agences />} />
      <Route path='/admin/agences/new' element={<AddAgence />} />
      <Route path='/admin/agences/:id' element={<UpdateAgence />} />
      <Route path='/admin/userByAgence/:id' element={<UserByAgence />} />
      <Route path='/admin/profile' element={<Profile />} />
      <Route path='/admin/support' element={<Supports />} />
      <Route path='/admin/utilisateurs' element={<ListUsers />} />
      <Route path='/admin/utilisateurs/new' element={<AddUsers />} />
      <Route path='/admin/utilisateurs/:id' element={<UpdateUser />} />
      <Route path='/admin/dossiers' element={<ListFolders />} /> 
      <Route path='/admin/dossiers/new' element={<AddFolder />} />
      <Route path='/admin/dossiers/view/:id' element={<ConsulterDossier />} />
      <Route path='/admin/dossiers/:id' element={<UpdateFolder />} />
      <Route path='/admin/userByDossier/:id' element={<UserByFolder />} />
      <Route path='/admin/dossiersvalider' element={<FolderToValidate />} />
      <Route path='/admin/dossiersarchives' element={<DossiersArchives />} />
      <Route path='/admin/transfertdossiers/:id' element={<TransfertDossier />} />
      <Route path='/admin/recherche' element={<RechercheDossier />} />
      <Route path='/admin/messages' element={<ListMessages />} />
      <Route path='/admin/messages/new' element={<AddMessage />} />
      <Route path='/admin/messages/:id' element={<UpdateMessage />} />
      <Route path='/admin/messageries/' element={<Messagerie />} />
      {/* <Route path='/user/dashboard' element={<DashboardUser />} /> */}

    </Routes>
  )
}

export default App
