import React, { useState } from 'react'
import { Button, Input } from 'reactstrap'
import Footer from '../../components/Footer'
import Sidebar from '../../components/Sidebar'
import Topbar from '../../components/Topbar'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { findAllAgences } from '../agences/agenceSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'
import { updateUser } from './userSlice'

const UpdateUser = () => {

    const dispatch = useDispatch()
    const { allAgences} = useSelector((state) => state.agence)
     
    const [authToken, setAuthToken] = useState(null)
    const navigate = useNavigate()
    const location = useLocation()
    const singleUser = location.state
   // console.log(singleUser)
    const [user, setUser] = useState({
        id: singleUser?.id,
        civilite: singleUser?.civilite,
        nom: singleUser?.nom,
        agence: singleUser?.agence["@id"],
        isAdmin: singleUser?.isAdmin,
        prenom: singleUser?.prenom,
        email: singleUser?.email,
        createdAt: singleUser?.createdAt,
        updatedAt: moment().format(),
        password: "",
        confirm_password: "",
        confirm_passwordState: "valid",
        passwordState: "valid",
        agenceState: "valid",
        status: singleUser?.status,
        dossiers: singleUser?.dossiers,
        roles: singleUser?.roles
        

    })

    useEffect(() => {
        if (localStorage.getItem('token')) {
            setAuthToken(localStorage.getItem('token'))
            dispatch(findAllAgences())

        } else {
            navigate("/")
        }
    }, [navigate, dispatch])
     
    const handleUpdate = (event) => {
        event.preventDefault()
        if (user.nom === "" || user.prenom === "" || user.email === "" || user.agence === "" || user.password === "" ||
            user.confirm_password === "" || user.civilite === "") {
            toast.error('Veuillez remplir tous les champs !', {
                position: toast.POSITION.TOP_CENTER
            })
        } else {
            if (user.password !== user.confirm_password) {
                toast.error('Le mot de passe ne correspond pas!', {
                    position: toast.POSITION.TOP_CENTER
                })
            } else {
                dispatch(updateUser(user))
                //navigate("/admin/utilisateurs")
                navigate("/admin/dashboard")
            }
        }
    }

    if (authToken) {
        return (
            <div className='dashboard_wrapper'>
                <Sidebar />
                <div className='main_content'>
                    <Topbar />
                    <div className='content'>
                        <div className="content_header text-light">
                            <h1 className="fs-2 kanit-font">Utilisateur</h1>
                            <div className="flex opacity-5">
                                <i className="bi-house-fill"></i>
                                <span>- Forms - Utilisateur</span>
                            </div>
                        </div>
                        <div className="card bg-white">
                            <div className="card-header bg-light">
                                <div className="d-flex justify-content-between">
                                    <h2 className="fs-5 m-0 p-2">Modifier un utilisateur</h2>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="px-0 px-md-4 px-lg-5 py-4">
                                    <form onSubmit={handleUpdate} >
                                        <div className="form-check">
                                            <Input className="form-check-input" type="radio" name="gender" id="madame" value="Mme"
                                                checked={user.civilite === "Mme"}
                                                onChange={(e) => setUser({...user, civilite: e.target.value})}
                                            />
                                            <label className="form-check-label ms-1" htmlFor="madame">
                                                Madame
                                            </label>
                                        </div>
                                        <div className="form-check mb-3 mt-2">
                                            <Input className="form-check-input" type="radio" name="gender" id="monsieur" value="M."
                                                checked={user.civilite === "M."}
                                                onChange={(e) => setUser({ ...user, civilite: e.target.value })}
                                            />
                                            <label className="form-check-label ms-1" htmlFor="monsieur">
                                                Monsieur
                                            </label>
                                        </div>


                                        <div className="row">
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="lastName" className="large fw-bolder mb-2"
                                                >Nom</label >
                                                <input
                                                    type="text"
                                                    value={user.nom}
                                                    placeholder="Nom"
                                                    name="lastName"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setUser({...user, nom: e.target.value})}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="agence" className="large fw-bolder mb-2"
                                                >Agence</label >
                                                <select
                                                    name="agence"
                                                    id="agence"
                                                    value={user.agence}
                                                    onChange={(e) => setUser({ ...user, agence: e.target.value })}
                                                    className="form-control text-secondary"
                                                    style={{ height: "50px" }}
                                                >
                                                    <option value="">{user.agence?.nom}</option>
                                                    {allAgences.map((agence) => (
                                                        <option key={agence.id} value={agence["@id"]}>{agence.nom}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="email" className="large fw-bolder mb-2"
                                                >Email</label >
                                                <input
                                                    type="email"
                                                    value={user.email}
                                                    placeholder="Email"
                                                    name="email"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setUser({...user, email: e.target.value})}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="firstName" className="large fw-bolder mb-2"
                                                >Prénom</label >
                                                <input
                                                    type="text"
                                                    value={user.prenom}
                                                    placeholder="Prénom"
                                                    name="firstName"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setUser({...user, prenom: e.target.value})}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="password" className="large fw-bolder mb-2"
                                                >Mot de passe</label >
                                                <input
                                                    type="password"
                                                    value={user.password}
                                                    placeholder="Mot de passe"
                                                    name="password"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setUser({...user, password: e.target.value})}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="confirm-password" className="large fw-bolder mb-2"
                                                >Confirmation du password</label >
                                                <input
                                                    type="password"
                                                    value={user.confirm_password}
                                                    placeholder="Confirmation du password"
                                                    name="confirm-password"
                                                    className="form-control text-secondary " style={{ height: "50px" }}
                                                    onChange={(e) => setUser({ ...user, confirm_password: e.target.value })}
                                                />
                                            </div>

                                        </div>
                                        <label className="form-check-label mb-2" htmlFor="admin-oui">
                                            Administrateur
                                        </label>
                                        <div className="form-check">
                                            <Input className="form-check-input" type="radio" name="admin" id="admin-oui"
                                                value={true}
                                                checked={user.isAdmin === true}
                                                onChange={(e) => setUser({ ...user, isAdmin: JSON.parse(e.target.value)})}
                                            />
                                            <label className="form-check-label" htmlFor="admin-oui">
                                                Oui
                                            </label>
                                        </div>
                                        <div className="form-check mb-2">
                                            <Input className="form-check-input" type="radio" name="admin" id="admin-non"
                                                value={false}
                                                checked={user.isAdmin === false}
                                                onChange={(e) => setUser({ ...user, isAdmin: JSON.parse(e.target.value)})}
                                            />
                                            <label className="form-check-label" htmlFor="admin-non">
                                                Non
                                            </label>
                                        </div>

                                        <div className="text-center">
                                            <Button type='submit' className="btn_custom col-2 d-flex justify-content-center "
                                                style={{ backgroundColor: "#5e72e4" }}>Valider
                                            </Button>
                                            <ToastContainer />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>

                    <Footer />
                </div>

            </div>
        )
    } else {
        return null
    }
}

export default UpdateUser