import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import Footer from '../../components/Footer'
import Sidebar from '../../components/Sidebar'
import Topbar from '../../components/Topbar'
import moment from 'moment'
import { api, deletMessageFolder, findFilesByMessage, findFilesByfolder, findMessagesByFolder, findOneFolder, sendMessageFolder, updateMessageFolder } from './folderSlice'
import { Button, ListGroup, Modal, ToastContainer } from 'react-bootstrap'
import { findAllMessages } from '../messages/messageSlice'
import axios from 'axios'
import { Input } from 'reactstrap'
import jwt_decode from "jwt-decode"
import SidebarUser from '../../components/user_normal/SidebarUser'
import { MEDIA_API } from '../../config'



const ConsulterDossier = () => {

    const MEDIA_URL = 'http://api.elbaz-associes.com/'
    const [authToken, setAuthToken] = useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const singleFolder = location.state
    // console.log(singleFolder)
    let idDossier = singleFolder?.dossier?.id || singleFolder?.dossier?.id || singleFolder?.id || singleFolder?.dossier_id

    const { allMessagesByFolder } = useSelector((state) => state.folder)
    const typeDossier = [
        {
            id: "RFE",
            text: "Expertise"
        },
        {
            id: "DHY",
            text: "Hypothèque"
        },
        {
            id: "DMC",
            text: "Mise en conformité"
        },
        {
            id: "LIP",
            text: "Loyer impayé/expulsion"
        },
        {
            id: "ANG",
            text: "Annulation d'assemblée générale"
        },
        {
            id: "DSI",
            text: "Saisie immobilière"
        },
        {
            id: "RCR",
            text: "Recouvrement de Créance"
        },
        {
            id: "DRC",
            text: "Recouvrement de charges"
        },
    ]
    const [updateMessage, setUpdateMessage] = useState({})
    const { allMessages } = useSelector((state) => state.message)
    const { oneFolder, filesFolder, filesMessage } = useSelector((state) => state.folder)

    const foundType = typeDossier.find(folder => folder.id === oneFolder?.type)
    const [ShowModalUpdate, setShowModalUpdate] = useState(false)
    const [ShowModalAdd, setShowModalAdd] = useState(false)
    const [showModalFiles, setShowModalFiles] = useState(false)
    const [showModalFilesMessage, setShowModalFilesMessage] = useState(false)

    const [dateAudience, setDateAudience] = useState("")
    const [hasFile, setHasFile] = useState(false)
    const [message, setMessage] = useState("")
    const [titreMessage, setTitreMessage] = useState("")
    const [filesByFolder, setFilesByFolder] = useState([])
    const [filesByMessage, setFilesByMessage] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([])
    const [idMessage, setIdMessage] = useState()


    const handleClose = () => setShowModalFiles(false)
   
    const handleCloseFilesMessage = () => setShowModalFilesMessage(false)
   // const handleShowFilesMessage = () => setShowModalFiles(true)
    const token = localStorage.getItem('token')
    const decodedToken = token ? jwt_decode(token) : null
  

    const handleShowModalUpdate = (item) => {
        setShowModalUpdate(true)
        setUpdateMessage(item)

    }
    const handleShowModalAdd = () => {
        setShowModalAdd(true)

    }

    const handleSendMessage = (event) => {
        event.preventDefault()

        let body = {
            dossier: oneFolder?.["@id"],
            dateAudience: dateAudience || "000-00-00",
            dateAudienceState: "valid",
            hasFile,
            logThreadId: "0",
            message,
            messageState: "valid",
            observation: singleFolder?.observation || "",
            tribunal: "",
            createdAt: moment().format(),
            type: 0
        }
       
       dispatch(sendMessageFolder({body,selectedFiles}))
        setShowModalAdd(false)
    }
    const handleUpdateMessage = (event) => {
        event.preventDefault()
        let body = {
            createdAt: moment().format(),
            dateAudience: updateMessage.dateAudience,
            dossier: undefined,
            hasFile,
            logThreadId: String(updateMessage.logThreadId),
            message: updateMessage.message,
            observation: updateMessage.observation,
            tribunal: updateMessage.tribunal,
            type: 0
        }
        let idMessage = updateMessage.id
        
        dispatch(updateMessageFolder({ idMessage, body,selectedFiles }))
        setShowModalUpdate(false)
    }
    const handleDelete = (id) => {

        dispatch(deletMessageFolder(id))
        navigate("/admin/dashboard")

    }
    // récupération du contenu du message lorsque l'utilisateur selection le titre
    const handleGetMessage = (event) => {

        const { value } = event.target
        const selectedMessage = allMessages.find(message => message.titre === value)
        if (selectedMessage) {
            setMessage(selectedMessage.message)
            setUpdateMessage({ ...updateMessage, message: selectedMessage.message })
        }
    }
    const handleFileSelect = (event) => {
       
        setSelectedFiles([...selectedFiles, ...event.target.files])
    }

    const handleFileDrop = (event) => {
        event.preventDefault()
        event.stopPropagation()
        // setHasFile(true)
        setSelectedFiles([...selectedFiles, ...event.dataTransfer.files])
    }
    const handleShow = () => {
        //setFilesByFolder([])
        setShowModalFiles(true)
    }
    const handleGetFiles = (idMessage) => { 
        setFilesByMessage([])
         setIdMessage(idMessage)
        setShowModalFilesMessage(true)
    }
    useEffect(() => {
        if (selectedFiles.length > 0) {
            setHasFile(true)
        } else {
            setHasFile(false)
        }
    }, [selectedFiles])

    useEffect(() => {
        if (localStorage.getItem('token')) {
            setAuthToken(localStorage.getItem('token'))
            dispatch(findMessagesByFolder(idDossier))
            dispatch(findOneFolder(idDossier))
            dispatch(findAllMessages())
            dispatch(findFilesByfolder(idDossier))
            dispatch(findFilesByMessage({ idMessage, idDossier }))


        } else {
            navigate("/")
        }

    }, [dispatch, navigate,idDossier,idMessage])
   

    const downloadFile = async (file) => {

        try {
            const urlToDownload = MEDIA_API + '/' + file.urlDownload + '/download'
           // console.log("urlToDownload", urlToDownload)
            const blobData = await  api.get(
                urlToDownload,
                {
                    responseType: 'blob'
                }
                ).then((response) => new Blob([response.data]))
           // console.log("blob data", blobData)
            const url = window.URL.createObjectURL(blobData)
            const link = document.createElement('a');
            link.href = url;
            //console.log("downloadfile ddddd", file)
            link.setAttribute('download', `${file.name}`);
            //console.log("link ddddd", link)
            document.body.appendChild(link);
            //console.log("body ddddd", document.body)
            link.click();
        } catch (error) {
            console.error(error);
        }     
    }

    // cette fonction rend l'affichage du fichier possible
    const constructList = (list) => {
       
        const arr = []
        arr.length = 0
        list?.map((f) => {
            const name = f.fileName;
            const preview = f.filePath
            const urlDownload = f['id']
            return arr.push({ name, preview, urlDownload })
        })

        return arr
    }

    useEffect(() => {

        if (filesMessage) {
                     
            setFilesByMessage(constructList(filesMessage))
        }
        
        if (filesFolder) {
          
            setFilesByFolder(constructList(filesFolder))
        }
    
       
    }, [filesFolder,filesMessage])
    //console.log("filesFolder: ", filesByFolder)
   // console.log("filesMessage: ", filesByMessage)
    if (authToken) {
        return (
            <>
                <div className='dashboard_wrapper'>
                    {
                        decodedToken?.roles.length > 1 ? <Sidebar /> : <SidebarUser />
                    }
                    <div className='main_content'>
                        <Topbar />
                        <div className='content'>
                            <div className="content_header text-light">
                                <h1 className="fs-2 kanit-font">Dossiers</h1>
                                <div className="flex opacity-5">
                                    <i className="bi-house-fill"></i>
                                    <span>- Dossier - Dossiers</span>
                                </div>
                            </div>
                            {/* <!-- right here is a wide card where the majority of thing will be placed --> */}
                            <div className='row'>
                                <div className="col-12 col-lg-6 mb-4">
                                    <div className="card bg-white me-3">
                                        <div className="card-header bold  d-flex justify-content-between ">
                                            <h5 className="card-title">
                                                {oneFolder?.refDossier}
                                            </h5>
                                            <button className="btn btn-secondary ms-3 bg-" data-toggle="tooltip"
                                                data-placement="top"
                                                onClick={handleShow}
                                                title="Afficher les fichiers joints du dossier">
                                                <i class="bi bi-folder2-open"></i>

                                            </button>
                                        </div>

                                        <div className="card-body">
                                            <p className="card-text">
                                                <strong>Type de dossier :</strong>{foundType?.text}<br />
                                                <strong>Référence :</strong> {oneFolder?.refDossier} <br />
                                                <strong>Adresse :</strong> {oneFolder?.adresse} <br />
                                                <strong>Code postal :</strong> {oneFolder?.codePostal} <br />
                                                <strong>Ville :</strong> {oneFolder?.ville} <br />
                                                <strong>Observation :</strong> {oneFolder?.observation} <br />
                                                <strong>identité du débiteur :</strong> {oneFolder?.identiteDebiteur} <br />
                                                <strong>adresse du débiteur :</strong> {oneFolder?.adresseDebiteur} <br />
                                                <strong>Lot Concerné :</strong> {oneFolder?.lotConcerne} <br />
                                                <strong>Origine de la dette :</strong> {oneFolder?.origineDette} <br />
                                                <strong>Montant de la dette :</strong>  {oneFolder?.montantDette}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="card bg-white">
                                        <div className="card-header d-flex justify-content-between ">
                                            <h5 className="card-title">
                                                Derniers messages
                                            </h5>
                                            <button className="btn text-white"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Envoyer un message"
                                                style={{ backgroundColor: "#17616B" }}
                                                onClick={handleShowModalAdd}
                                            >
                                                <i className="bi bi-send "></i>
                                            </button>
                                        </div>
                                        <div className="card-body px-0">

                                            {
                                                allMessagesByFolder?.map((item) => {
                                                    return (
                                                        <div key={item?.id} className='row' >
                                                            <div className="col-8">
                                                                <div className="message_box" onClick={() => handleShowModalUpdate(item)} >
                                                                    <div className="small text-muted">
                                                                        <i className="bi-envelope me-1"></i>
                                                                        <span className="me-4"> {item?.dossier.user?.nom} </span>
                                                                        <span> {moment(item?.createdAt).format("DD/MM/YYYY")} </span>
                                                                    </div>
                                                                    <div className="mb-2 bold">
                                                                        {item?.dossier.refDossier}
                                                                    </div>
                                                                    <div className="text-muted">
                                                                        {item?.message}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className='d-flex gap-1'>
                                                                    <button className="btn btn-secondary" data-toggle="tooltip"
                                                                        data-placement="top"
                                                                        title="Afficher les fichiers joints du message"
                                                                        style={{ backgroundColor: "#17616B" }}
                                                                        onClick={() => handleGetFiles(item.id)}
                                                                    >
                                                                        <i class="bi bi-folder2-open"></i>

                                                                    </button>
                                                                    <button className="btn btn-secondary"
                                                                        data-toggle="tooltip" data-placement="top"
                                                                        onClick={() => handleDelete(item.id)}
                                                                        title="Supprimer le message">
                                                                        <i className="bi bi-trash-fill"></i>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <Modal show={ShowModalUpdate} onHide={() => setShowModalUpdate(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Modifier le message</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="card-text text-muted">
                                    <strong>Type de dossier :</strong>{foundType?.text}<br />
                                    <strong>Référence :</strong> {updateMessage.dossier?.refDossier} <br />
                                    <strong>Nom :</strong> {updateMessage.dossier?.user?.nom} <br />
                                    <strong>Prénom :</strong> {updateMessage.dossier?.user?.prenom} <br />
                                    <strong>Email :</strong> {updateMessage.dossier?.user?.email} <br />
                                </p>
                                <form onSubmit={handleUpdateMessage} >

                                    <div className="col-12 col-lg-6 mb-3">
                                        <label htmlFor="lastName" className="large fw-bolder mb-2"
                                        >Date d'audience</label >
                                        <input
                                            type="date"
                                            value={updateMessage.dateAudience}
                                            name="dateAudience"
                                            className="form-control text-secondary" style={{ height: "50px" }}
                                            onChange={(e) => setUpdateMessage({ ...updateMessage, dateAudience: e.target.value.split('T')[0] })}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6 mb-3">
                                        <label htmlFor="agence" className="large fw-bolder mb-2"
                                        >Liste des messages</label >
                                        <select
                                            value={titreMessage}
                                            onChange={handleGetMessage}
                                            name="titreMessage"
                                            id="titreMessage"
                                            className="form-control text-secondary"
                                            style={{ height: "50px" }}
                                        >
                                            <option value="">-- Liste des messages --</option>
                                            {allMessages?.map((message) => (
                                                <option key={message.id} value={message.titre}>{message.titre}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-12 col-lg-12 mb-3">
                                        <textarea className="form-control mt-2" id="exampleFormControlTextarea1" rows="6"
                                            value={updateMessage.message}
                                            placeholder="Message"
                                            name="message"
                                            onChange={(e) => setUpdateMessage({ ...updateMessage, message: e.target.value })}
                                        ></textarea>
                                    </div>
                                    <div className='col-12 col-lg-6 mb-3 ' controlId="file-upload">
                                        <label className='mb-3'>
                                            Sélectionnez ou déposez des fichiers ici
                                        </label>
                                        <Input type="file"
                                            multiple
                                            onChange={handleFileSelect}
                                            onDrop={handleFileDrop}
                                        />
                                    </div>
                                    {selectedFiles.length > 0 && (
                                        <ul>
                                            {selectedFiles.map((file, index) => (
                                                <div key={file.index} className='file-preview d-flex'>
                                                    <li>{file.name}</li>

                                                    <img src={URL.createObjectURL(file)} alt={file.name} style={{ maxWidth: '30%', maxHeight: '100px' }} />


                                                    <Button className='btn btn-danger ms-2 p-1 tex-small mb-2 ' onClick={() => {

                                                        const newFiles = [...selectedFiles]
                                                        newFiles.splice(index, 1)
                                                        setSelectedFiles(newFiles)
                                                    }}>
                                                        <i class="bi bi-trash-fill"></i>
                                                        Supprimer</Button>
                                                </div>
                                            ))}
                                        </ul>
                                    )}
                                    <Button type='submit' variant="primary"
                                        style={{ backgroundColor: "#5e72e4" }}>
                                        Modifier
                                    </Button>
                                    <ToastContainer />
                                </form>
                            </Modal.Body>
                        </Modal>
                        <Modal show={ShowModalAdd} onHide={() => setShowModalAdd(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Envoyer message</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="card-text text-muted">
                                    <strong>Type de dossier :</strong>{foundType?.text}<br />
                                    <strong>Référence :</strong> {oneFolder.refDossier} <br />
                                    <strong>Nom :</strong>{oneFolder.user?.nom}<br />
                                    <strong>Prénom :</strong> {oneFolder.user?.prenom} <br />
                                    <strong>Email :</strong> {oneFolder.user?.email} <br />
                                </p>
                                <form onSubmit={handleSendMessage} >

                                    <div className="col-12 col-lg-6 mb-3">
                                        <label htmlFor="lastName" className="large fw-bolder mb-2"
                                        >Date d'audience</label >
                                        <input
                                            type="date"
                                            name="dateAudience"
                                            value={dateAudience}
                                            onChange={(e) => setDateAudience(e.target.value.split('T')[0])}
                                            className="form-control text-secondary" style={{ height: "50px" }}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6 mb-3">
                                        <label htmlFor="agence" className="large fw-bolder mb-2"
                                        >Liste des messages</label >
                                        <select
                                            value={titreMessage}
                                            onChange={handleGetMessage}
                                            name="titreMessage"
                                            id="titreMessage"
                                            className="form-control text-secondary"
                                            style={{ height: "50px" }}
                                        >
                                            <option value="">-- Liste des messages --</option>
                                            {allMessages?.map((message) => (
                                                <option key={message.id} value={message.titre}>{message.titre}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-12 col-lg-12 mb-3">
                                        <textarea className="form-control mt-2" id="exampleFormControlTextarea1" rows="6"
                                            value={message}
                                            placeholder="Message"
                                            name="message"
                                            onChange={(e) => setMessage(e.target.value)}

                                        ></textarea>
                                    </div>
                                    <div className='col-12 col-lg-6 mb-3 ' controlId="file-upload">
                                        <label className='mb-3'>
                                            Sélectionnez ou déposez des fichiers ici
                                        </label>
                                        <Input type="file"
                                            multiple
                                            onChange={handleFileSelect}
                                            onDrop={handleFileDrop}
                                        />
                                    </div>
                                    {selectedFiles.length > 0 && (
                                        <ul>
                                            {selectedFiles.map((file, index) => (
                                                <div key={file.index} className='file-preview d-flex'>
                                                    <li>{file.name}</li>

                                                    <img src={URL.createObjectURL(file)} alt={file.name} style={{ maxWidth: '30%', maxHeight: '100px' }} />


                                                    <Button className='btn btn-danger ms-2 p-1 tex-small mb-2 ' onClick={() => {

                                                        const newFiles = [...selectedFiles]
                                                        newFiles.splice(index, 1)
                                                        setSelectedFiles(newFiles)
                                                    }}>
                                                        <i class="bi bi-trash-fill"></i>
                                                        Supprimer</Button>
                                                </div>
                                            ))}
                                        </ul>
                                    )}
                                    <Button type='submit' variant="primary"
                                        style={{ backgroundColor: "#5e72e4" }}>
                                        Envoyer
                                    </Button>
                                    <ToastContainer />
                                </form>
                            </Modal.Body>
                        </Modal>
                        <Modal show={showModalFiles} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Les fichiers joints du dossier</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ListGroup>
                                    {filesByFolder?.map((file) => (
                                        <ListGroup.Item key={Math.random()} className="px-0">
                                            <div className="row align-items-center justify-content-between">
                                                <div className="col-auto">
                                                    <div className="avatar">
                                                        <img
                                                            alt={file.name}
                                                            className="avatar-img rounded"
                                                            data-dz-thumbnail
                                                            src={`${MEDIA_URL}media/${file.preview}/${file.name}`}
                                                        // onClick={() => window.open(`${MEDIA_URL}media/${file.preview}/${file.name}`)}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col ml--3" style={{ maxWidth: '70%' }}>
                                                    <h4 className="text-truncate mb-1" data-dz-name>
                                                        {file.name}
                                                    </h4>
                                                    <p className="small text-muted mb-0" data-dz-size>
                                                        {file.size}
                                                    </p>
                                                </div>
                                                <div className="col-auto me-3">
                                                    <button
                                                        className="btn btn-sm btn-danger data-dz-remove"
                                                        title="Télécharger"
                                                        onClick={() => downloadFile(file)}
                                                    >
                                                        <i class="bi bi-file-arrow-down"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Modal.Body>
                            <Modal.Footer>

                            </Modal.Footer>
                        </Modal>
                        <Modal show={showModalFilesMessage} onHide={handleCloseFilesMessage}>
                            <Modal.Header closeButton>
                                <Modal.Title>Les fichiers joints du message</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ListGroup>
                                    {filesByMessage?.map((file) => (
                                        <ListGroup.Item key={Math.random()} className="px-0">
                                            <div className="row align-items-center justify-content-between">
                                                <div className="col-auto">
                                                    <div className="avatar">
                                                        <img
                                                            alt={file?.name}
                                                            className="avatar-img rounded"
                                                            data-dz-thumbnail
                                                            src={`${MEDIA_URL}media/${file.preview}/${file.name}`}
                                                        // onClick={() => window.open(`${MEDIA_URL}media/${file.preview}/${file.name}`)}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col ml--3" style={{ maxWidth: '70%' }}>
                                                    <h4 className="text-truncate mb-1" data-dz-name>
                                                        {file.name}
                                                    </h4>
                                                    <p className="small text-muted mb-0" data-dz-size>
                                                        {file.size}
                                                    </p>
                                                </div>
                                                <div className="col-auto me-3">
                                                    <button
                                                        className="btn btn-sm btn-danger data-dz-remove"
                                                        title="Télécharger"
                                                        onClick={() => downloadFile(file)}
                                                    >
                                                        <i class="bi bi-file-arrow-down"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Modal.Body>
                            <Modal.Footer>

                            </Modal.Footer>
                        </Modal>
                        <Footer />
                    </div>
                </div>
            </>
        )
    } else {
        return null
    }
}

export default ConsulterDossier