import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Topbar from '../../components/Topbar';
import Footer from '../../components/Footer';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { findAllFoldersLogs, findLawerLogs, sendNewMessageLogs } from './messagerieSlice';
import { Button, Col } from 'react-bootstrap';
import { findAllFolders } from '../dossiers/folderSlice';
import { findAllMessages } from '../messages/messageSlice';
import { Input } from 'reactstrap';

const Messagerie = () => {

    const [authToken, setAuthToken] = useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [searchText, setSearchText] = useState("")
    const [currentPageSend, setCurrentPageSend] = useState(0)
    const [perPageSend, setPerPageSend] = useState(10)

    const [selectedFiles, setSelectedFiles] = useState([])

    const { allFoldersLogs, lawerLogs } = useSelector((state) => state.messagerie)
    const { allFolders } = useSelector((state) => state.folder)
    const { allMessages } = useSelector((state) => state.message)

    const [newMessage, setNewMEssage] = useState({
        dateAudience: "000-00-00",
        dossier: "",
        logThreadId: "0",
        message: "",
        observation: "",
        tribunal: "",
        type:0
    })
    // récupération du contenu du message lorsque l'utilisateur selection le titre
    const handleGetMessage = (event) => {

        const { value } = event.target
        const selectedMessage = allMessages.find(message => message.titre === value)
        if (selectedMessage) {
    
            setNewMEssage({ ...newMessage, message: selectedMessage.message })
        }
    }

    const handleFileSelect = (event) => {
        // setHasFile(true)
        setSelectedFiles([...selectedFiles, ...event.target.files])
    }

    const handleFileDrop = (event) => {
        event.preventDefault()
        event.stopPropagation()
        // setHasFile(true)
        setSelectedFiles([...selectedFiles, ...event.dataTransfer.files])
    }

    const handleSendMessage = (event) => {
        event.preventDefault()
        dispatch(sendNewMessageLogs({ newMessage, selectedFiles }))
        navigate("/admin/dashboard")
        
     }
    useEffect(() => {
        if (localStorage.getItem('token')) {
            setAuthToken(localStorage.getItem('token'))
            dispatch(findAllFoldersLogs())
            dispatch(findLawerLogs())
            dispatch(findAllFolders())
            dispatch(findAllMessages())

        } else {
            navigate("/")
        }

    }, [dispatch, navigate])

    // filtre pour la boite de reception
   
    const offset = currentPage * perPage
    const filteredFolders = allFoldersLogs?.filter((folder) => {
        const lowerCaseSearchText = searchText?.toLowerCase()
        const searchRefMessage = folder?.dossier.refDossier + "" + folder?.message
        return searchRefMessage.toLowerCase().includes(lowerCaseSearchText)
    })
    const allCurrentFolders = searchText ? filteredFolders : allFoldersLogs
    const pageCount = allCurrentFolders ? Math.ceil(allCurrentFolders.length / perPage) : 0
    const currentFoldersLogs = allCurrentFolders ? allCurrentFolders.slice(offset, offset + perPage) : []


    // filtre pour les éléments envoyés

    const offsetSend = currentPageSend * perPageSend
    const filteredAllLawerLogs = lawerLogs?.filter((folder) => {
        const lowerCaseSearchText = searchText?.toLowerCase()
        const searchRefMessage = folder?.dossier.refDossier + "" + folder?.message
        return searchRefMessage.toLowerCase().includes(lowerCaseSearchText)
    })
    const allCurrentLawerLogs = searchText ? filteredAllLawerLogs : lawerLogs
    const pageCountSend = allCurrentLawerLogs ? Math.ceil(allCurrentLawerLogs.length / perPageSend) : 0
    const currentLawerLogs = allCurrentLawerLogs ? allCurrentLawerLogs.slice(offsetSend, offsetSend + perPageSend) : []

 
    if (authToken) {
        return (
            <div className='dashboard_wrapper'>
                <Sidebar />
                <div className='main_content'>
                    <Topbar />
                    <div className='content'>
                        <div className="content_header text-light">
                            <h1 className="fs-2 kanit-font">Messagerie</h1>
                            <div className="flex opacity-5">
                                <i className="bi-house-fill"></i>
                                <span>- Consultation - Messagerie</span>
                            </div>
                        </div>
                        {/* <!-- right here is a wide card where the majority of thing will be placed --> */}
                        <div className="card bg-white">
                            <div className="card-header bg-light">
                                <div className="d-flex justify-content-between">
                                    <h2 className="fs-5 m-0 p-2">Messagerie</h2>

                                </div>
                            </div>
                            <div className="card-body">
                                <div className="mt-5">
                                    {/* <!-- le contenu de tabs se trouvent ici -->

                                <!-- this is the tab list --> */}
                                    <ul
                                        className="nav nav-tabs custom-nav-tabs gap-3 nav-fill mb-3"
                                        id="myTab"
                                        role="tablist"
                                    >
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className="nav-link custom-nav"
                                                id="new-message-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#new-message-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="new-message-tab-pane"
                                                aria-selected="true"
                                            >
                                                <i className="bi-plus me-1"></i> Nouveau message
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className="nav-link custom-nav active"
                                                id="inbox-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#inbox-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="inbox-tab-pane"
                                                aria-selected="false"
                                            >
                                                <i className="bi-box me-1"></i> Boite de reception
                                            </button>
                                        </li>

                                        <li className="nav-item" role="presentation">
                                            <button
                                                className="nav-link custom-nav"
                                                id="outbox-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#outbox-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="outbox-tab-pane"
                                                aria-selected="false"
                                            >
                                                <i className="bi bi-send-check-fill me-2"></i>
                                                Eléments envoyés
                                            </button>
                                        </li>
                                    </ul>

                                    {/* <!-- the tab content goes right here --> */}
                                    <div
                                        className="tab-content mb-5 mt-5"
                                        id="myTabContent"
                                    >
                                        <div
                                            className="tab-pane fade "
                                            id="new-message-tab-pane"
                                            role="tabpanel"
                                            aria-labelledby="new-message-tab"
                                            tabindex="0"
                                        >
                                            <form  className="container text-muted" onSubmit={handleSendMessage}>
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 mb-3">
                                                        <div className="col-12 col-lg-12 mb-3">
                                                            <label htmlFor="folder" className="large fw-bolder mb-2"
                                                            >Référence du dossier</label >
                                                            <select
                                                                name="folder"
                                                                id="folder"
                                                                value={newMessage.dossier}
                                                                onChange={(e) => setNewMEssage({ ...newMessage, dossier: e.target.value })}
                                                                className="form-control text-secondary"
                                                                style={{ height: "40px" }}
                                                            >
                                                                <option value="">Sélectionner la Référence du dossier</option>
                                                                
                                                                {allFolders.map((folder) => (
                                                                    <option key={folder.id} value={folder["@id"]}>{folder.refDossier}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                             <label htmlFor="folder-reference" className="small bold">Date d'audience</label>
                                                            <input type="date" placeholder="Refence du dossier"
                                                                className="form-control text-secondary" style={{ height: "50px" }} 
                                                                id="folder-reference"
                                                                value={newMessage.dateAudience?.split('T')[0]}
                                                                onChange={(e) => setNewMEssage({...newMessage, dateAudience:e.target.value})}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-6 mb-3">
                                                        <div className="mb-3">
                                                            <label htmlFor="folder-reference" className="small bold">Liste des messages</label>
                                                            <select
                                                              // value={newMessage.message}
                                                                onChange={handleGetMessage}
                                                                name="titreMessage"
                                                                id="titreMessage"
                                                                className="form-control text-secondary"
                                                                style={{ height: "50px" }}
                                                            >
                                                                <option value="">-- Liste des messages --</option>
                                                                {allMessages?.map((message) => (
                                                                    <option key={message.id} value={message.titre}>{message.titre}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className="col-12 col-lg-12 mb-3">
                                                                <textarea className="form-control mt-2" id="exampleFormControlTextarea1" rows="6"
                                                                      value={newMessage.message}
                                                                    placeholder="Message"
                                                                    name="message"
                                                                 onChange={(e) => setNewMEssage({ ...newMessage, message: e.target.value })}
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 col-lg-6 mb-3 ' controlId="file-upload">
                                                            <label className='mb-3'>
                                                                Sélectionnez ou déposez des fichiers ici
                                                            </label>
                                                            <Input type="file"
                                                                multiple
                                                                onChange={handleFileSelect}
                                                                onDrop={handleFileDrop}
                                                            />
                                                        </div>
                                                        {selectedFiles.length > 0 && (
                                                            <ul>
                                                                {selectedFiles.map((file, index) => (
                                                                    <div key={file.index} className='file-preview d-flex'>
                                                                        <li>{file.name}</li>

                                                                        <img src={URL.createObjectURL(file)} alt={file.name} style={{ maxWidth: '30%', maxHeight: '100px' }} />


                                                                        <Button className='btn btn-danger ms-2 p-1 tex-small mb-2 ' onClick={() => {

                                                                            const newFiles = [...selectedFiles]
                                                                            newFiles.splice(index, 1)
                                                                            setSelectedFiles(newFiles)
                                                                        }}>
                                                                            <i class="bi bi-trash-fill"></i>
                                                                            Supprimer</Button>
                                                                    </div>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <button type='submit'
                                                            className="btn_custom col-2 d-flex justify-content-center "
                                                            style={{ backgroundColor: "#5e72e4" }}>Valider
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div
                                            className="tab-pane fade show active "
                                            id="inbox-tab-pane"
                                            role="tabpanel"
                                            aria-labelledby="inbox-tab"
                                            tabIndex="0"
                                        >
                                            <div className="d-flex justify-content-end mb-3 mt-2">
                                                <div className="d-flex align-items-center col-5">
                                                    <div className="input-group me-3">
                                                        <input type="text" className="form-control" placeholder="Recherche"
                                                            aria-label="Recherche" aria-describedby="basic-addon2"
                                                            value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                      <table className="table table-sortable">
                                                <thead>
                                                    <tr className='small'>
                                                        <th data-type="alpha" className='text-secondary'>RÉFÉRENCE / DÉBITEUR</th>
                                                        <th data-type="alpha" className='text-secondary'>MESSAGE</th>
                                                        <th data-type="alpha" className='text-secondary'>DATE CRÉATION</th>
                                                        <th className='text-secondary '>ACTION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        Object.keys(currentFoldersLogs).length ?
                                                            currentFoldersLogs.map((folder) => {
                                                                return (
                                                                    <tr key={folder.id}>
                                                                        <td className='small'>{folder.dossier.refDossier} / {folder.dossier.identiteDebiteur} </td>
                                                                        <td className='small'>{folder.message}</td>
                                                                        <td className='small'>{moment(folder.createdAt).format("DD/MM/YYYY")}</td>
                                                                        <td className='col-2 '>
                                                                            <Link to={`/admin/dossiers/view/${folder.dossier.id}`} state={folder} className="btn btn-secondary ms-3" style={{ backgroundColor: "#135b6c" }} data-toggle="tooltip" data-placement="top"
                                                                                title="Consulter le dossier">
                                                                                <i className="bi bi-list text-white"></i>
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            : <Loader colSpan={4} />
                                                    }




                                                </tbody>
                                            </table>
                                      </div>
                                            <ReactPaginate
                                                previousLabel={'previous'}
                                                nextLabel={'next'}
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={4}
                                                onPageChange={(data) => {
                                                    setCurrentPage(data.selected);
                                                }}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                            />
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="outbox-tab-pane"
                                            role="tabpanel"
                                            aria-labelledby="outbox-tab"
                                            tabIndex="0"
                                        >
                                            <div className="d-flex justify-content-end mb-3 mt-2">
                                                <div className="d-flex align-items-center col-5">
                                                    <div className="input-group me-3">
                                                        <input type="text" className="form-control" placeholder="Recherche"
                                                            aria-label="Recherche" aria-describedby="basic-addon2"
                                                            value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-sortable">
                                                    <thead>
                                                        <tr className='small'>
                                                            <th data-type="alpha" className='text-secondary'>RÉFÉRENCE / DÉBITEUR</th>
                                                            <th data-type="alpha" className='text-secondary'>MESSAGE</th>
                                                            <th data-type="alpha" className='text-secondary'>DATE CRÉATION</th>
                                                            <th className='text-secondary '>ACTION</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            Object.keys(currentLawerLogs).length ?
                                                                currentLawerLogs.map((folder) => {
                                                                    return (
                                                                        <tr key={folder.id}>
                                                                            <td className='small'>{folder.dossier.refDossier} / {folder.dossier.identiteDebiteur} </td>
                                                                            <td className='small'>{folder.message}</td>
                                                                            <td className='small'>{moment(folder.createdAt).format("DD/MM/YYYY")}</td>
                                                                            <td className='col-2 '>
                                                                                <Link to={`/admin/dossiers/view/${folder.dossier.id}`} state={folder} className="btn btn-secondary ms-3" style={{ backgroundColor: "#135b6c" }} data-toggle="tooltip" data-placement="top"
                                                                                    title="Consulter le dossier">
                                                                                    <i className="bi bi-list text-white"></i>
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                : <Loader colSpan={4} />
                                                        }
                                                    </tbody>
                                                </table>
                                           </div>
                                            <ReactPaginate
                                                previousLabel={'previous'}
                                                nextLabel={'next'}
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={pageCountSend}
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={4}
                                                onPageChange={(data) => {
                                                    setCurrentPageSend(data.selected);
                                                }}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>

            </div>
        )
    } else {
        return null
    }
}

export default Messagerie