import { css } from '@emotion/react';
import React from 'react'
import { ClipLoader } from 'react-spinners';

const Loader = ({colSpan}) => {

    const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
  return (
      <tr className="w-100">
          <td colSpan={colSpan} className="w-100">
              <div className="d-flex align-items-center justify-content-center flex-column">
                <ClipLoader color={'#5e72e4'} css={override} size={30} />
                <span className="small">Chargement...</span>
              </div>
          </td>

      </tr>
  )
}

export default Loader