import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import { Link, useNavigate } from 'react-router-dom'
import Sidebar from '../../components/Sidebar'
import Topbar from '../../components/Topbar'
import { useDispatch, useSelector } from 'react-redux'
import { activeFolder, findAllFoldersArchives } from './folderSlice'
import Loader from '../../components/Loader'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import jwt_decode from "jwt-decode"
import SidebarUser from '../../components/user_normal/SidebarUser'

const DossiersArchives = () => {

    const [authToken, setAuthToken] = useState(null)

    const token = localStorage.getItem('token')
    const decodedToken = token ? jwt_decode(token) : null

    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [searchText, setSearchText] = useState("")
    const { allFoldersArchives } = useSelector((state) => state.folder)
    const dispatch = useDispatch()

    const handleActive = (id, status) => {
        let body = {
            status: true
        }
       
        dispatch(activeFolder({ id, body }))
        navigate("/admin/dashboard")

    }
    
    useEffect(() => {
        if (localStorage.getItem('token')) {
            setAuthToken(localStorage.getItem('token'))
            dispatch(findAllFoldersArchives())

        } else {
            navigate("/")
        }

    }, [dispatch, navigate])
    const offset = currentPage * perPage
    const filteredFoldersArchives = allFoldersArchives.filter((folder) => {
        const lowerCaseSearchText = searchText?.toLowerCase()
        return folder.identiteDebiteur?.toLowerCase().includes(lowerCaseSearchText)
    })
    const allCurrentFoldersArchives = searchText ? filteredFoldersArchives : allFoldersArchives
    const pageCount = allCurrentFoldersArchives ? Math.ceil(allCurrentFoldersArchives.length / perPage) : 0
    const currentFoldersArchives = allCurrentFoldersArchives ? allCurrentFoldersArchives.slice(offset, offset + perPage) : []
    // console.log(currentFolders)
  if (authToken) {
      return (
          <div className='dashboard_wrapper'>
              {
                  decodedToken?.roles.length > 1 ? <Sidebar /> : <SidebarUser />
              }
              <div className='main_content'>
                  <Topbar />
                  <div className='content'>
                      <div className="content_header text-light">
                          <h1 className="fs-2 kanit-font">Dossiers Archivés</h1>
                          <div className="flex opacity-5">
                              <i className="bi-house-fill"></i>
                              <span>- Tables - Dossiers Archivés</span>
                          </div>
                      </div>
                      {/* <!-- right here is a wide card where the majority of thing will be placed --> */}
                      <div className="card bg-white">
                          <div className="card-header bg-light">
                              <div className="d-flex justify-content-between">
                                  <h2 className="fs-5 m-0 p-2">Liste des dossiers</h2>
                                  <Link to="/admin/dossiers/new" href="" className="btn_custom">Créer un nouveau dossier</Link>
                              </div>
                          </div>
                          <div className="card-body">
                              <div className="d-flex justify-content-end mb-3 mt-2">
                                  <div className="d-flex align-items-center col-5">
                                      <div className="input-group me-3">
                                          <input type="text" className="form-control" placeholder="Recherche"
                                              aria-label="Recherche" aria-describedby="basic-addon2"
                                              value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                      </div>
                                  </div>
                              </div>
                              <div className="table-responsive">
                                  <table className="table table-sortable">
                                      <thead>
                                          <tr>
                                              <th data-type="alpha" className='text-secondary small'>RÉFÉRENCE</th>
                                              <th data-type="alpha" className='text-secondary small'>ADRESSE</th>
                                              <th data-type="alpha" className='text-secondary small'>VILLE</th>
                                              <th data-type="alpha" className='text-secondary small'>	DÉBITEUR</th>
                                              <th data-type="alpha" className='text-secondary small'>	TYPE DE DOSSIER</th>
                                              <th data-type="alpha" className='text-secondary small'>	DATE CRÉATION</th>
                                              <th className='text-secondary '>Actions</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {
                                              Object.keys(currentFoldersArchives).length ?
                                                  currentFoldersArchives.map((folder) => {
                                                      return (
                                                          <tr key={folder.id}>
                                                              <td className='small'>{folder.refDossier}</td>
                                                              <td className='small'>{folder.adresse}</td>
                                                              <td className='small'>{folder.ville}</td>
                                                              <td className='small'>{folder.identiteDebiteur}</td>
                                                              <td className='small'>{folder.type}</td>
                                                              <td className='small'>{moment(folder.createdAt).format("DD/MM/YYYY")}</td>
                                                              <td className='d-flex gap-1'>
                                                                  <Link to={`/admin/dossiers/${folder.id}`} state={folder}
                                                                      className="btn btn-primary" style={{ backgroundColor: "#5e72e4" }}
                                                                      data-toggle="tooltip"
                                                                      data-placement="top" title="Editer le dossier">
                                                                      <i className="bi bi-gear-fill text-white"></i>
                                                                  </Link>
                                                                  <button className="btn btn-secondary bg-success"
                                                                      data-toggle="tooltip" data-placement="top"
                                                                      title="Activer"
                                                                      onClick={() => handleActive(folder.id, folder.status)}
                                                                  >
                                                                      <i className="bi bi-star-fill"></i>
                                                                  </button>
                                                                  <Link to={`/admin/dossiers/view/${folder.id}`} state={folder}
                                                                      className="btn btn-secondary " style={{ backgroundColor: "#135b6c" }}
                                                                      data-toggle="tooltip" data-placement="top"
                                                                      title="Consulter le dossier">
                                                                      <i className="bi bi-list text-white"></i>
                                                                  </Link>
                                                              </td>
                                                          </tr>
                                                      )
                                                  })
                                                  : <Loader colSpan={7} />
                                          }




                                      </tbody>
                                  </table>
                             </div>
                              <ReactPaginate
                                  previousLabel={'previous'}
                                  nextLabel={'next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={4}
                                  onPageChange={(data) => {
                                      setCurrentPage(data.selected);
                                  }}
                                  containerClassName={'pagination'}
                                  activeClassName={'active'}
                              />

                          </div>
                      </div>
                  </div>

                  <Footer />
              </div>

          </div>
      )
  } else {
      return null
  }
}

export default DossiersArchives