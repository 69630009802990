import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import { Button } from 'reactstrap'
import Sidebar from '../../components/Sidebar'
import Topbar from '../../components/Topbar'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch } from 'react-redux'
import { updateAgence } from './agenceSlice'

const UpdateAgence = () => {

    const [error, setError] = useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [authToken, setAuthToken] = useState(null)
    const location = useLocation()
    const singleAgence = location.state
    const [agence, setAgence] = useState({
        id: singleAgence?.id,
        nom: singleAgence?.nom,
        codePostal: singleAgence?.codePostal,
        ville: singleAgence?.ville,
        fax: singleAgence?.fax,
        adresse: singleAgence?.adresse,
        telephone: singleAgence?.telephone,
        codeAgence: singleAgence?.codeAgence,
    })

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token) {
            setAuthToken(token)
        } else {
            navigate("/")
        }
    }, [navigate])

    const handleUpdate = (event) => {
        event.preventDefault()
        if (agence.nom === "" || agence.codePostal === "" || agence.ville === "" || agence.fax === "" ||
            agence.adresse === "" || agence.telephone === "") {
            toast.error('Veuillez remplir tous les champs !', {
                position: toast.POSITION.TOP_CENTER
            })
        } else {
            if (agence.codePostal.length < 5) {
                toast.error('Le code postal doit avoir au moins 5 caractères !', {
                    position: toast.POSITION.TOP_CENTER
                })
            } else {
                dispatch(updateAgence(agence))
                navigate("/admin/dashboard")

            }
           
        }
    }

    if (authToken) {
        return (
            <div className='dashboard_wrapper'>
                <Sidebar />
                <div className='main_content'>
                    <Topbar />
                    <div className='content'>
                        <div className="content_header text-light">
                            <h1 className="fs-2 kanit-font">Agence</h1>
                            <div className="flex opacity-5">
                                <i className="bi-house-fill"></i>
                                <span>- Forms - Agence</span>
                            </div>
                        </div>
                        <div className="card bg-white">
                            <div className="card-header bg-light">
                                <div className="d-flex justify-content-between">
                                    <h2 className="fs-5 m-0 p-2">Modifier une agence</h2>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="px-0 px-md-4 px-lg-5 py-4">
                                    <form onSubmit={handleUpdate} >
                                        <div className="row">
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="society-name" className="large fw-bolder mb-2"
                                                >Nom de la société/Syndic</label >
                                                <input
                                                    type="text"
                                                    value={agence.nom}
                                                    placeholder="Nom de la société/Syndic"
                                                    name="society-name"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setAgence({ ...agence, nom: e.target.value })}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="code-postal" className="large fw-bolder mb-2"
                                                >Code Postal</label >
                                                <input
                                                    type="text"
                                                    value={agence.codePostal}
                                                    placeholder="Code Postal"
                                                    name="code-postal"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setAgence({ ...agence, codePostal: e.target.value })}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="ville" className="large fw-bolder mb-2"
                                                >Ville</label >
                                                <input
                                                    type="text"
                                                    value={agence.ville}
                                                    placeholder="Ville"
                                                    name="ville"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setAgence({ ...agence, ville: e.target.value })}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="fax" className="large fw-bolder mb-2"
                                                >Fax</label >
                                                <input
                                                    type="text"
                                                    value={agence.fax}
                                                    placeholder="Fax"
                                                    name="fax"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setAgence({ ...agence, fax: e.target.value })}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="adresse" className="large fw-bolder mb-2"
                                                >Adresse</label >
                                                <input
                                                    type="text"
                                                    value={agence.adresse}
                                                    placeholder="Adresse"
                                                    name="adresse"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setAgence({ ...agence, adresse: e.target.value })}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="phone" className="large fw-bolder mb-2"
                                                >Téléphone</label >
                                                <input
                                                    type="text"
                                                    value={agence.telephone}
                                                    placeholder="Téléphone"
                                                    name="phone"
                                                    className="form-control text-secondary " style={{ height: "50px" }}
                                                    onChange={(e) => setAgence({ ...agence, telephone: e.target.value })}
                                                />
                                            </div>

                                        </div>
                                        <div className="text-center">
                                            <Button type='submit' className="btn_custom col-2 d-flex justify-content-center " style={{ backgroundColor: "#5e72e4" }}>Valider</Button>
                                        </div>
                                        <ToastContainer />
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>

                    <Footer />
                </div>

            </div>
        )
    } else {
        return null
    }
}

export default UpdateAgence