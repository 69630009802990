import React from 'react'

const ContentHeader = () => {
  return (
        // < !--the content header-- >
        <div className="content_header text-light">
            <h1 className="fs-2 kanit-font">Accueil</h1>
            <div className="flex opacity-5">
                <i className="bi-house-fill"></i>
                <span>- Tableau de bord - Accueil</span>
            </div>
        </div>
  )
}

export default ContentHeader