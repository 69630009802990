import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import { Link, useNavigate } from 'react-router-dom'
import Topbar from '../../components/Topbar'
import Sidebar from '../../components/Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import moment from 'moment/moment'
import ReactPaginate from 'react-paginate'
import { deleteMessage, findAllMessages } from './messageSlice'

const ListMessages = () => {
    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [authToken, setAuthToken] = useState(null)
    const [currentPage, setCurrentPage] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [searchText, setSearchText] = useState("")

    const { allMessages} = useSelector((state) => state.message)

    useEffect(() => {
        if (localStorage.getItem('token')) {
            setAuthToken(localStorage.getItem('token'))
           dispatch(findAllMessages())

        } else {
            navigate("/")
        }

    }, [dispatch,navigate])

    const handleDelete = (id) => {         
    
        dispatch(deleteMessage(id))
         navigate("/admin/dashboard")
    }

 

    
    
    const offset = currentPage * perPage
    const filteredMessages = allMessages?.filter((message) => {
        const lowerCaseSearchText = searchText.toLowerCase()
        return message.titre.toLowerCase().includes(lowerCaseSearchText)
    })
    const allCurrentMessages = searchText ? filteredMessages : allMessages
    const pageCount = allCurrentMessages ? Math.ceil(allCurrentMessages.length / perPage) : 0
    const currentMessages = allCurrentMessages ? allCurrentMessages.slice(offset, offset + perPage) : []
  // console.log("currentMessages", allMessages)

   if (authToken) {

       return (
           <div className='dashboard_wrapper'>
               <Sidebar />
               <div className='main_content'>
                   <Topbar />
                   <div className='content'>
                       <div className="content_header text-light">
                           <h1 className="fs-2 kanit-font">Messages</h1>
                           <div className="flex opacity-5">
                               <i className="bi-house-fill"></i>
                               <span>- Consultation - Messages</span>
                           </div>
                       </div>
                       {/* <!-- right here is a wide card where the majority of thing will be placed --> */}
                       <div className="card bg-white ">
                           <div className="card-header bg-light">
                               <div className="d-flex justify-content-between">
                                   <h2 className="fs-5 m-0 p-2">Liste des Messages</h2>
                                   <Link to="/admin/messages/new"  className="btn_custom">Ajouter un nouveau message</Link>
                               </div>
                           </div>
                           <div className="card-body">
                               <div className="d-flex justify-content-end mb-3 mt-2">
                                   <div className="d-flex align-items-center col-5">
                                       <div className="input-group me-3">
                                           <input type="text" className="form-control" placeholder="Recherche"
                                               aria-label="Recherche" aria-describedby="basic-addon2"
                                               value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                       </div>
                                   </div>
                               </div>
                               <div className="table-responsive">
                                   <table className="table table-sortable">
                                       <thead>
                                           <tr className='small'>
                                               <th data-type="alpha" className='text-secondary'>Objet</th>
                                               <th data-type="alpha" className='text-secondary'>Message</th>
                                               <th data-type="alpha" className='text-secondary'>Date</th>
                                               <th className='text-secondary'>Action</th>
                                           </tr>
                                       </thead>
                                       <tbody>
                                           {
                                               Object.keys(currentMessages).length ?
                                                   currentMessages.map((message) => {
                                                       return (
                                                           <tr className='small' key={message.id}>
                                                               <td>{message.titre}</td>
                                                               <td> {message.message} <br /></td>
                                                               <td>{moment(message.createdAt).format("DD/MM/YYYY")}</td>

                                                               <td className="d-flex gap-1">
                                                                   <Link to={`/admin/messages/${message.id}`} state={message}
                                                                       className="btn btn-primary " style={{ backgroundColor: "#5e72e4" }}
                                                                       data-toggle="tooltip"
                                                                       data-placement="top" title="Editer le message">
                                                                       <i className="bi bi-gear-fill text-white"></i>
                                                                   </Link>
                                                                   <button className="btn btn-secondary bg-danger"
                                                                       data-toggle="tooltip"
                                                                       data-placement="top"
                                                                       onClick={() => handleDelete(message.id)}
                                                                       title="Supprimer">
                                                                       <i className="bi bi-trash-fill"></i>

                                                                   </button>
                                                               </td>
                                                           </tr>
                                                       )
                                                   })
                                                   : <Loader colSpan={4} />
                                           }
                                       </tbody>
                                   </table>
                              </div>
                               <ReactPaginate
                                   previousLabel={'previous'}
                                   nextLabel={'next'}
                                   breakLabel={'...'}
                                   breakClassName={'break-me'}
                                   pageCount={pageCount}
                                   marginPagesDisplayed={1}
                                   pageRangeDisplayed={4}
                                   onPageChange={(data) => {
                                  setCurrentPage(data.selected);
                                   }}
                                   containerClassName={'pagination'}
                                   activeClassName={'active'}
                               />

                           </div>
                       </div>
                   </div>

                   <Footer />
               </div>

           </div>
       )
   } else {
       return null
   }
}

export default ListMessages