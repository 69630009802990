import React, { useEffect, useState } from 'react'
import { Button, Input } from 'reactstrap'
import Sidebar from '../../components/Sidebar'
import Topbar from '../../components/Topbar'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { activeFolder, archiveFolder, searchFoldersPage } from './folderSlice'
import moment from 'moment'
import Loader from '../../components/Loader'
import ReactPaginate from 'react-paginate'
import jwt_decode from "jwt-decode"
import SidebarUser from '../../components/user_normal/SidebarUser'

const RechercheDossier = () => {
    const [authToken, setAuthToken] = useState(null)
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const dispatch = useDispatch()

    const [type, setType] = useState('')
    const [refDossier, setRefDossier] = useState('')
    const [adresse, setAdresse] = useState('')
    const [identiteDebiteur, setIdentiteDebiteur] = useState('')
    const [status, setStatus] = useState(1)
    const [display, setDisplay] = useState(1)
    const token = localStorage.getItem('token')
    const [foldersSearch, setFoldersSearch] = useState([])

    const decodedToken = token ? jwt_decode(token) : null

    const typeDossier = [
        {
            id: "RFE",
            text: "Expertise"
        },
        {
            id: "DHY",
            text: "Hypothèque"
        },
        {
            id: "DMC",
            text: "Mise en conformité"
        },
        {
            id: "LIP",
            text: "Loyer impayé/expulsion"
        },
        {
            id: "ANG",
            text: "Annulation d'assemblée générale"
        },
        {
            id: "DSI",
            text: "Saisie immobilière"
        },
        {
            id: "RCR",
            text: "Recouvrement de Créance"
        },
        {
            id: "DRC",
            text: "Recouvrement de charges"
        },
    ]

    const { allFoldersSearch, loading } = useSelector((state) => state.folder)

    useEffect(() => {
        if (localStorage.getItem('token')) {
            setAuthToken(localStorage.getItem('token'))

        } else {
            navigate("/")
        }

    }, [dispatch, navigate])

    const handleActive = (id) => {


        let body = {
            status: true
        }

        dispatch(activeFolder({ id, body }))
        navigate("/admin/dashboard")

    }

    const handleArchive = (id) => {

        let body = {
            status: false
        }

        dispatch(archiveFolder({ id, body }))
        navigate("/admin/dashboard")

    }

    const handleSubmit = (event) => {
        event.preventDefault()
        let body = {
            type,
            refDossier,
            adresse,
            identiteDebiteur,
            status
        }
        setDisplay(status)
        dispatch(searchFoldersPage(body))

    }

    useEffect(() => {
        setFoldersSearch(allFoldersSearch)
    }, [allFoldersSearch]) 
    
    useEffect(() => {
        setFoldersSearch([])
    }, [navigate]) 
    
    const offset = currentPage * perPage
    const pageCount = foldersSearch ? Math.ceil(foldersSearch.length / perPage) : 0
    const currentFoldersSearch = foldersSearch ? foldersSearch.slice(offset, offset + perPage) : []

    if (authToken) {
        return (
            <div className="dashboard_wrapper">
                {
                    decodedToken?.roles.length > 1 ? <Sidebar /> : <SidebarUser />
                }
                <div className="main_content">
                    <Topbar />
                    <div className="content">
                        <div className="content_header text-light">
                            <h1 className="fs-2 kanit-font">Recherche</h1>
                            <div className="flex opacity-5">
                                <i className="bi-house-fill"></i>
                                <span>- Dossiers - Recherche</span>
                            </div>
                        </div>
                        <div className="card bg-white p-3">
                            <form onSubmit={handleSubmit} >
                                <div className="row" style={{ marginTop: "5px" }}>
                                    <div className="col-sm-6">
                                        <label className="form-check-label">
                                            Type de dossier:
                                        </label>
                                    </div>
                                    <div className="col-sm-6">
                                        <select name="type" id="type"
                                            value={type}
                                            onChange={(e) => setType(e.target.value)}
                                            className="form-control text-secondary"
                                            style={{ height: "50px" }}
                                        >

                                            <option value="">-- Sélectionner le type du dossier --</option>
                                            {typeDossier.map((dossier) => (
                                                <option key={dossier.id} value={dossier.id}>{dossier.text}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row sm" style={{ marginTop: "5px" }}>
                                    <div className="col-sm-6">
                                        <label>Référence du dossier: </label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Input
                                            value={refDossier}
                                            bsSize="sm"
                                            className="mb-3"
                                            placeholder="Référence du dossier"
                                            onChange={(e) => setRefDossier(e.target.value)}

                                        />
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "5px" }}>
                                    <div className="col-sm-6">
                                        <label>Adresse de l'immeuble:</label>{" "}
                                    </div>
                                    <div className="col-sm-6">
                                        <Input
                                            value={adresse}
                                            bsSize="sm"
                                            className="mb-3"
                                            placeholder="Adresse de l'immeuble"
                                            onChange={(e) => setAdresse(e.target.value)}

                                        />
                                    </div>
                                </div>
                                <div className="row sm">
                                    <div className="col-sm-6">
                                        <label> Nom du débiteur ou du demandeur:</label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Input
                                            value={identiteDebiteur}
                                            bsSize="sm"
                                            className="mb-3"
                                            placeholder="Nom du débiteur ou du demandeur"
                                            onChange={(e) => setIdentiteDebiteur(e.target.value)}

                                        />
                                    </div>
                                </div>

                                <div className="row sm">
                                    <div className="col-sm-6">
                                        Recherche dans les dossiers :
                                    </div>
                                    <div
                                        className="col-sm-6"
                                    >
                                        <div className="form-check form-check-inline">
                                            <Input className="form-check-input" type="radio" name="status" id="actif-oui"
                                                value={1}
                                                defaultChecked
                                                onChange={(e) => setStatus(1)}

                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="actif-oui"
                                            >
                                                Actifs
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <Input className="form-check-input" type="radio" name="status" id="archive-non"
                                                value={0}
                                                onChange={(e) => setStatus(0)}

                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="archive-non"
                                            >
                                                Archivés
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row sm">
                                    <div className="col-sm-6"></div>
                                    <div className="col-sm-6 ">
                                        <div className="text-center">
                                            <Button
                                                type='submit'
                                                className="btn_custom col-3 mt-2 d-flex justify-content-center ">
                                                {
                                                    loading ? <span>Chargement...</span> : <span>Rechercher</span>
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-sortable">
                                        <thead>
                                            <tr className='small'>
                                                <th data-type="alpha" className='text-secondary'>RÉFÉRENCE</th>
                                                <th data-type="alpha" className='text-secondary'>ADRESSE</th>
                                                <th data-type="alpha" className='text-secondary'>VILLE</th>
                                                <th data-type="alpha" className='text-secondary'>	DÉBITEUR</th>
                                                <th data-type="alpha" className='text-secondary'>	TYPE DE DOSSIER</th>
                                                <th data-type="alpha" className='text-secondary'>	DATE CRÉATION</th>
                                                <th className='text-secondary '>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                currentFoldersSearch.length ?
                                                    currentFoldersSearch.map((folder) => {
                                                        return (
                                                            <tr key={folder.id}>
                                                                <td className='small'>{folder.refDossier}</td>
                                                                <td className='small'>{folder.adresse}</td>
                                                                <td className='small'>{folder.ville}</td>
                                                                <td className='small'>{folder.identiteDebiteur}</td>
                                                                <td className='small'>{folder.type}</td>
                                                                <td className='small'>{moment(folder.createdAt).format("DD/MM/YYYY")}</td>
                                                                <td className='d-flex gap-1'>
                                                                    <Link to={`/admin/dossiers/${folder.id}`} state={folder}
                                                                        className="btn btn-primary" style={{ backgroundColor: "#5e72e4" }}
                                                                        data-toggle="tooltip"
                                                                        data-placement="top" title="Editer le dossier">
                                                                        <i className="bi bi-gear-fill text-white"></i>
                                                                    </Link>
                                                                    {display === 1 ? (
                                                                        <button
                                                                            className="btn btn-secondary bg-secondary"
                                                                            data-toggle="tooltip"
                                                                            data-placement="top"
                                                                            title="Archiver"
                                                                            onClick={() => handleArchive(folder.id, folder.status)}
                                                                        >
                                                                            <i className="bi bi-database-fill "></i>
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            className="btn btn-secondary bg-success"
                                                                            data-toggle="tooltip"
                                                                            data-placement="top"
                                                                            title="Activer"
                                                                            onClick={() => handleActive(folder.id, folder.status)}
                                                                        >
                                                                            <i className="bi bi-star-fill"></i>
                                                                        </button>
                                                                    )}

                                                                    <Link to={`/admin/dossiers/view/${folder.id}`} state={folder}
                                                                        className="btn btn-secondary"
                                                                        style={{ backgroundColor: "#135b6c" }} data-toggle="tooltip"
                                                                        data-placement="top"
                                                                        title="Consulter le dossier">
                                                                        <i className="bi bi-list text-white"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : <div className='mt-5'>
                                                        <span>Aucun dossier chargé</span>
                                                    </div>
                                            }

                                        </tbody>
                                    </table>
                              </div>
                                <ReactPaginate
                                    previousLabel={'previous'}
                                    nextLabel={'next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={4}
                                    onPageChange={(data) => {
                                        setCurrentPage(data.selected);
                                    }}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default RechercheDossier