import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import Sidebar from '../../components/Sidebar'
import Topbar from '../../components/Topbar'
import { Button } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch} from 'react-redux'
import { addAgence } from './agenceSlice'
import { getRandomIntInclusive } from './getRandom'


const AddAgence = () => {

  

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [authToken, setAuthToken] = useState(null)
    const [nom, setNom] = useState("")
    const [codePostal, setCodePostal] = useState("")
    const [ville, setVille] = useState("")
    const [fax, setFax] = useState("")
    const [adresse, setAdresse] = useState("")
    const [telephone, setTelephone] = useState("")
   // const[codeAgence, setCodeAgence] = useState("")

    // const { error } = useSelector((state) => state.agence?.error)

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token) {
            setAuthToken(token)
        } else {
            navigate("/")
        }
    }, [navigate])

    const handleAdd =  (event) => {
        event.preventDefault()
        if (nom === "" || codePostal === "" || ville === "" || fax === "" || adresse === "" || telephone === "") {
            toast.error('Veuillez remplir tous les champs !', {
                position: toast.POSITION.TOP_CENTER
            })
        } else {
            if (codePostal.length < 5) {
                toast.error('Le code postal doit avoir au moins 5 caractères !', {
                    position: toast.POSITION.TOP_CENTER
                })
            } else {
                let random = getRandomIntInclusive(1000, 9999)
                let codeAgence = (nom.length > 4 ? nom.substring(0, 3) : nom) + random
                codeAgence = codeAgence.replace(/[^A-Z0-9]+/gi, '')
                let body = {
                    nom,
                    codePostal,
                    ville,
                    fax,
                    adresse,
                    telephone,
                    codeAgence
                }
                    dispatch(addAgence(body))
              
                setNom("")
                setAdresse("")
                setCodePostal("")
                setFax("")
                setVille("")
                setTelephone("")
                navigate("/admin/dashboard")
            }
         
        }
      
       
    }
    //   if (error) {
    //       console.log("erreur",error)
    //   }
  
    if (authToken) {
        return (
            <div className='dashboard_wrapper'>
                <Sidebar />
                <div className='main_content'>
                    <Topbar />
                    <div className='content'>
                        <div className="content_header text-light">
                            <h1 className="fs-2 kanit-font">Agence</h1>
                            <div className="flex opacity-5">
                                <i className="bi-house-fill"></i>
                                <span>- Forms - Agence</span>
                            </div>
                        </div>
                        <div className="card bg-white">
                            <div className="card-header bg-light">
                                <div className="d-flex justify-content-between">
                                    <h2 className="fs-5 m-0 p-2">Créer une agence</h2>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="px-0 px-md-4 px-lg-5 py-4">
                                    <form onSubmit={handleAdd} >
                                        <div className="row">
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="society-name" className="large fw-bolder mb-2"
                                                >Nom de la société/Syndic</label >
                                                <input
                                                    type="text"
                                                    value={nom}
                                                    placeholder="Nom de la société/Syndic"
                                                    name="society-name"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setNom(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="code-postal" className="large fw-bolder mb-2"
                                                >Code Postal</label >
                                                <input
                                                    type="text"
                                                    value={codePostal}
                                                    placeholder="Code Postal"
                                                    name="code-postal"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setCodePostal(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="ville" className="large fw-bolder mb-2"
                                                >Ville</label >
                                                <input
                                                    type="text"
                                                    value={ville}
                                                    placeholder="Ville"
                                                    name="ville"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setVille(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="fax" className="large fw-bolder mb-2"
                                                >Fax</label >
                                                <input
                                                    type="text"
                                                    value={fax}
                                                    placeholder="Fax"
                                                    name="fax"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setFax(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="adresse" className="large fw-bolder mb-2"
                                                >Adresse</label >
                                                <input
                                                    type="text"
                                                    value={adresse}
                                                    placeholder="Adresse"
                                                    name="adresse"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setAdresse(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="phone" className="large fw-bolder mb-2"
                                                >Téléphone</label >
                                                <input
                                                    type="text"
                                                    value={telephone}
                                                    placeholder="Téléphone"
                                                    name="phone"
                                                    className="form-control text-secondary " style={{ height: "50px" }}
                                                    onChange={(e) => setTelephone(e.target.value)}
                                                />
                                            </div>

                                        </div>
                                        <div className="text-center">
                                            <Button type='submit' className="btn_custom col-2 d-flex justify-content-center " style={{ backgroundColor: "#5e72e4" }}>Valider</Button>
                                        </div>
                                        <ToastContainer />
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>

                    <Footer />
                </div>

            </div>
        )
    } else {
        return null
    }
 
}

export default AddAgence