import React from 'react'
import { countAllFoldersActifs } from '../../features/dossiers/folderSlice'
import { countAllAudiences } from '../../features/messageries/messagerieSlice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const StatisticsUser = () => {

    const dispatch = useDispatch()
    const { numberFoldersActifs } = useSelector((state) => state.folder)
    const { numberAudiences} = useSelector((state) => state.messagerie)
    useEffect(() => {
        dispatch(countAllFoldersActifs())
        dispatch(countAllAudiences())
    }, [dispatch])
    
    return (
        <div className="row mb-5 d-flex justify-content-between">
            <div className="col-12 col-sm-6 col-lg-4 col-xl-6 mb-3">
                <div className="stat_card">
                    <div
                        className="d-flex align-items-start justify-content-between mb-5"
                    >
                        <div className="stat_numbers">
                            <div className="opacity-5 d-block small">
                                DOSSIERS ACTIFS
                            </div>
                            <div className="kanit-font fs-2">{numberFoldersActifs}</div>
                        </div>
                        <div className="stat_icon">
                            <i className="bi-folder"></i>
                        </div>
                    </div>
                    <div className="progression">
                        <div
                            className="progression_status"
                            style={{ width: '60%' }}
                        ></div>
                    </div>
                </div>
            </div>         

            <div className="col-12 col-sm-6 col-lg-4 col-xl-6 mb-3">
                <div className="stat_card cyan">
                    <div
                        className="d-flex align-items-start justify-content-between mb-5"
                    >
                        <div className="stat_numbers">
                            <div className="opacity-5 d-block small">
                                NOMBRE D"AUDIENCES
                            </div>
                            <div className="kanit-font fs-2">{numberAudiences}</div>
                        </div>
                        <div className="stat_icon">
                            <i className="bi-calendar2-week"></i>
                        </div>
                    </div>
                    <div className="progression">
                        <div
                            className="progression_status"
                            style={{ width: '60%' }}
                        ></div>
                    </div>
                </div>
            </div>

           
            
        </div>
    )
}

export default StatisticsUser