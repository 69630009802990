import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import { Button, Input } from 'reactstrap'
import Topbar from '../../components/Topbar'
import Sidebar from '../../components/Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { findAllAgences } from '../agences/agenceSlice'
import { ToastContainer, toast } from 'react-toastify'
import { addUser } from './userSlice'
import moment from 'moment/moment'

const AddUsers = () => {

    const { allAgences } = useSelector((state) => state.agence)
    const [authToken, setAuthToken] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [civilite, setCivilite] = useState("")
    const [nom, setNom] = useState("")
    const [agence, setAgence] = useState("")
    const [email, setEmail] = useState("")
    const [prenom, setPrenom] = useState("")
    const [password, setPassword] = useState("")
    const [confirm_password, setconfirm_password] = useState("")
    const [roles, setRoles] = useState(['ROLE_USER'])
    const [isAdmin, setIsAdmin] = useState(false)


    useEffect(() => {
        if (localStorage.getItem('token')) {
            setAuthToken(localStorage.getItem('token'))
            dispatch(findAllAgences())

        } else {
            navigate("/")
        }
    }, [navigate, dispatch])
    
    const handleSubmit = (event) => {
        event.preventDefault()
        if (nom === "" || prenom === "" || email === "" || agence === "" || password === "" ||
            confirm_password === "" || civilite === "") {
            toast.error('Veuillez remplir tous les champs !', {
                position: toast.POSITION.TOP_CENTER
            })
        } else {
            if (password !== confirm_password) {
                toast.error('Le mot de passe ne correspond pas !', {
                    position: toast.POSITION.TOP_CENTER
                })
            } else {
                let body = {
                    nom,
                    prenom,
                    email,
                    agence,
                    civilite,
                    isAdmin,
                    roles,
                    password,
                    status: true,
                    confirm_password,
                    agenceState: "valid",
                    civiliteState: "valid",
                    confirm_passwordState: "valid",
                    emailState: "valid",
                    isAdminState: "valid",
                    nomState: "valid",
                    passwordState: "valid",
                    prenomState: "valid",
                    updatedAt: moment().format(),
                    createdAt: moment().format(),
                }
                dispatch(addUser(body))
                navigate("/admin/dashboard")
                setAgence("")
                setCivilite("")
                setEmail("")
                setIsAdmin("")
                setNom("")
                setconfirm_password("")
                setPassword("")
                setPrenom("")
            }          

            
        }
    }

        if (authToken) {
            return (
                <div className='dashboard_wrapper'>
                    <Sidebar />
                    <div className='main_content'>
                        <Topbar />
                        <div className='content'>
                            <div className="content_header text-light">
                                <h1 className="fs-2 kanit-font">Utilisateur</h1>
                                <div className="flex opacity-5">
                                    <i className="bi-house-fill"></i>
                                    <span>- Forms - Utilisateur</span>
                                </div>
                            </div>
                            <div className="card bg-white">
                                <div className="card-header bg-light">
                                    <div className="d-flex justify-content-between">
                                        <h2 className="fs-5 m-0 p-2">Ajouter un utilisateur</h2>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="px-0 px-md-4 px-lg-5 py-4">
                                        <form onSubmit={handleSubmit} >
                                            <div className="form-check">
                                                <Input className="form-check-input" type="radio" name="gender"
                                                    id="madame" value="Mme"
                                                    onChange={(e) => setCivilite(e.target.value)}
                                                />
                                                <label className="form-check-label ms-1" htmlFor="madame">
                                                    Madame
                                                </label>
                                            </div>
                                            <div className="form-check mb-3 mt-2">
                                                <Input className="form-check-input" type="radio" name="gender"
                                                    id="monsieur" value="M."
                                                    onChange={(e) => setCivilite(e.target.value)}
                                                />
                                                <label className="form-check-label ms-1" htmlFor="monsieur">
                                                    Monsieur
                                                </label>
                                            </div>


                                            <div className="row">
                                                <div className="col-12 col-lg-6 mb-3">
                                                    <label htmlFor="lastName" className="large fw-bolder mb-2"
                                                    >Nom</label >
                                                    <input
                                                        type="text"
                                                        value={nom}
                                                        placeholder="Nom"
                                                        name="lastName"
                                                        className="form-control text-secondary" style={{ height: "50px" }}
                                                        onChange={(e) => setNom(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-6 mb-3">
                                                    <label htmlFor="agence" className="large fw-bolder mb-2"
                                                    >Agence</label >
                                                    <select
                                                        name="agence"
                                                        id="agence"
                                                        value={agence}
                                                        onChange={(e) => setAgence(e.target.value)}
                                                        className="form-control text-secondary"
                                                        style={{ height: "50px" }}
                                                    >
                                                        <option value="">-- Sélectionner une agence --</option>
                                                        {allAgences.map((agence) => (
                                                            <option key={agence.id} value={agence["@id"]}>{agence.nom}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-12 col-lg-6 mb-3">
                                                    <label htmlFor="email" className="large fw-bolder mb-2"
                                                    >Email</label >
                                                    <input
                                                        type="email"
                                                        value={email}
                                                        placeholder="Email"
                                                        name="email"
                                                        className="form-control text-secondary" style={{ height: "50px" }}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-6 mb-3">
                                                    <label htmlFor="firstName" className="large fw-bolder mb-2"
                                                    >Prénom</label >
                                                    <input
                                                        type="text"
                                                        value={prenom}
                                                        placeholder="Prénom"
                                                        name="firstName"
                                                        className="form-control text-secondary" style={{ height: "50px" }}
                                                        onChange={(e) => setPrenom(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-6 mb-3">
                                                    <label htmlFor="password" className="large fw-bolder mb-2"
                                                    >Mot de passe</label >
                                                    <input
                                                        type="password"
                                                        value={password}
                                                        placeholder="Mot de passe"
                                                        name="password"
                                                        className="form-control text-secondary" style={{ height: "50px" }}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-6 mb-3">
                                                    <label htmlFor="confirm-password" className="large fw-bolder mb-2"
                                                    >Confirmation du mot de passe</label >
                                                    <input
                                                        type="password"
                                                        value={confirm_password}
                                                        placeholder="Confirmation du mot de passe"
                                                        name="confirm-password"
                                                        className="form-control text-secondary " style={{ height: "50px" }}
                                                        onChange={(e) => setconfirm_password(e.target.value)}
                                                    />
                                                </div>

                                            </div>
                                            <label className="form-check-label mb-2" htmlFor="admin-oui">
                                                Administrateur
                                            </label>
                                            <div className="form-check">
                                                <Input className="form-check-input" type="radio" name="admin"
                                                    id="admin-oui" value={true}
                                                    onChange={(e) => setIsAdmin(e.target.value === "true")} />
                                                <label className="form-check-label" htmlFor="admin-oui">
                                                    Oui
                                                </label>
                                            </div>
                                            <div className="form-check mb-2">
                                                <Input className="form-check-input" type="radio" name="admin"
                                                    id="admin-non" value={false}
                                                    onChange={(e) => setIsAdmin(e.target.value === "false")}
                                                />
                                                <label className="form-check-label" htmlFor="admin-non">
                                                    Non
                                                </label>
                                            </div>

                                            <div className="text-center">
                                                <Button type='submit' className="btn_custom col-2 d-flex justify-content-center " style={{ backgroundColor: "#5e72e4" }}>Valider</Button>
                                            </div>
                                            <ToastContainer />
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <Footer />
                    </div>

                </div>
            )
        } else {
            return null
        }
    }

export default AddUsers