import React from 'react'
import Footer from '../../components/Footer'
import Sidebar from '../../components/Sidebar'
import Topbar from '../../components/Topbar'
import { Link } from 'react-router-dom'

const FolderToValidate = () => {
  return (
      <div className='dashboard_wrapper'>
          <Sidebar />
          <div className='main_content'>
              <Topbar />
              <div className='content'>
                  <div className="content_header text-light">
                      <h1 className="fs-2 kanit-font">Dossiers à valider</h1>
                      <div className="flex opacity-5">
                          <i className="bi-house-fill"></i>
                          <span>- Consultations - Dossiers à valider</span>
                      </div>
                  </div>
                  {/* <!-- right here is a wide card where the majority of thing will be placed --> */}
                  <div className="card bg-white">
                      <div className="card-header bg-light">
                          <div className="d-flex justify-content-between">
                              <h2 className="fs-5 m-0 p-2">Liste des dossiers à valider</h2>
                          </div>
                      </div>
                      <div className="card-body">
                          <table class="table table-sortable">
                              <thead>
                                  <tr>
                                      <th data-type="alpha" className='text-secondary small'>RÉFÉRENCE</th>
                                      <th data-type="alpha" className='text-secondary small'>ADRESSE</th>
                                      <th data-type="alpha" className='text-secondary small'>VILLE</th>
                                      <th data-type="alpha" className='text-secondary small'>	DÉBITEUR</th>
                                      <th data-type="alpha" className='text-secondary small'>	TYPE DE DOSSIER</th>
                                      <th data-type="alpha" className='text-secondary small'>	DATE CRÉATION</th>
                                      <th className='text-secondary '>Action</th>
                                  </tr>
                              </thead>
                          
                          </table>

                      </div>
                  </div>
              </div>

              <Footer />
          </div>

      </div>
  )
}

export default FolderToValidate