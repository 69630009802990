import React, { useEffect, useState } from 'react'
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import { useDispatch, useSelector } from 'react-redux'
import { findAllFoldersLogs, findFoldersLogsLimit } from '../features/messageries/messagerieSlice'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
// import Chart from 'chart.js/auto'
// import {  Bar, Scatter } from 'react-chartjs-2'
import { findAllFolders, findTypeFolders } from '../features/dossiers/folderSlice'
import jwt_decode from "jwt-decode"
import allLocales from '@fullcalendar/core/locales-all'
import { FormGroup, Input } from 'reactstrap'
import { Modal } from 'react-bootstrap'
import FullCalendar from '@fullcalendar/react'
import { ResponsiveContainer, BarChart, Bar, XAxis,YAxis, Tooltip, Cell } from 'recharts'

const ContentDashboard = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [showEvent, setShowEvent] = useState()

    const [showModalEvent, setShowModalEvent] = useState(false)

    const currentDate = new Date()
    const dayOfWeek = currentDate.toLocaleDateString('fr-FR', { weekday: 'long' })
    const month = currentDate.toLocaleDateString('fr-FR', { month: 'long' })
    const year = currentDate.getFullYear()

    const { logsLimit } = useSelector((state) => state.messagerie)
    const { typeDossiers, allFolders } = useSelector((state) => state.folder)
    const { allFoldersLogs } = useSelector((state) => state.messagerie)
    const token = localStorage.getItem('token')
    const decodedToken = token ? jwt_decode(token) : null
   
    const typesDossier = Object.keys(typeDossiers)
    
    const colors = {

        "DRC": "rgb(245, 54, 92)",
        "LIP": "rgb(251, 99, 64)",
        "ANG": "rgb(45, 206, 137)",
        "DMC": "rgb(19, 91, 108)",
        "RCR":"rgb()",
        "RFE": "rgb(244, 245, 247)",
        "DSI": "rgb(255, 255, 0)",
        "DHY": "rgb(94, 114, 228)",
  
      
    }

    let dataArray = []
 
    for (let i = 0; i < typesDossier.length; i++) {
        let count = 0
        for (let j = 0; j < allFolders.length; j++) {
               
            if (allFolders[j].type === typesDossier[i]) {
                count++
            }
        }
        const color = colors[typesDossier[i]] // récupère la couleur correspondante
        dataArray.push({ type: typesDossier[i], quantity: count, fill: color })
    }
 

    
    const handleViewDossier = (item) => {
        navigate(`/admin/dossiers/view/${item.dossier_id}`,{state:item})

    }      
    useEffect(() => {
        dispatch(findFoldersLogsLimit(30))
        dispatch(findTypeFolders())
        dispatch(findAllFolders())
        //dispatch(findAllFoldersLogs(decodedToken))
        
    }, [dispatch])


    const handleDateSelect = (arg) => {

        setShowEvent({
            reference: arg.event._def.title,
            message: arg.event._def.extendedProps.message
        })
        setShowModalEvent(true)

    }

    const eventData = allFoldersLogs?.map((folder) => {
        return { title: folder?.dossier?.refDossier, start: folder?.dateAudience, message: folder?.message }
    })

    const eventDataUpdate = eventData
        ?.map((item) => {
            if (item.start === undefined) {
                return null; // Exclut les objets avec 'start' égal à 'undefined'
            }
            const start = moment(item.start).tz('Europe/Paris').format('YYYY-MM-DD')
            return { ...item, start }
        })
        .filter(Boolean); // Supprime les valeurs 'null' du tableau
   
  return (
      <div className="row widgets">
          <div className="col-12 col-lg-6 col-xl-4 mb-4">
              <div className="card">
                  <div className="card-header">
                      <h5 className="card-title">
                          Répartition de dossiers
                      </h5>
                  </div>
            
                  <div className="">
                      <ResponsiveContainer width="100%" height="100%" aspect={1}>
                          <BarChart data={dataArray}>
                              <XAxis dataKey="type"  />
                              <YAxis />
                              <Tooltip className="recharts-tooltip-wrapper" background="transparent" />
                              <Bar dataKey="quantity" barSize={10} radius={5} >
                                  {
                                      dataArray.map((data, index) => (
                                          <Cell key={`cell-${index}`} fill={data.fill} />
                                      ))
                                  }
                              </Bar>
                          </BarChart>
                      </ResponsiveContainer> 
                  </div>
              </div>
          </div>

          <div className="col-12 col-lg-6 col-xl-4 mb-4">
              <div className="card">
                  <div className="card-header">
                      <h5 className="card-title">
                          Derniers messages
                      </h5>
                  </div>
                  <div className="card-body px-0">  
                      {
                          logsLimit &&
                          logsLimit.slice(0, 5).map((item) => {
                              return (
                                  <div className="message_box" key={Math.random()}
                                      onClick={() => handleViewDossier(item)}
                                  >
                                      <div className="small">
                                          <i className="bi-envelope me-1"></i>
                                          <span className="me-4">{item.user_nom}</span>
                                          <span>{moment(item?.dossierLogs_createdAt).format("DD/MM/YYYY")}</span>
                                      </div>
                                      <div className="mb-2 bold">
                                          {item?.dossier_refDossier}
                                      </div>
                                      <div className="text-muted">
                                          {item?.dossierLogs_message}
                                      </div>
                                  </div>
                            )
                        })
                     }

                  </div>
              </div>
          </div>
          <div className="col-12 col-lg-6 col-xl-4 mb-4">
              <div className="card">
                  <div className="card-header">
                      <h5 className="card-title mb-0">
                          Audiences du mois
                      </h5>
                      <span className="small text-muted opacity-5"
                      >{dayOfWeek} {currentDate.getDate()} {month} {year}</span>
                  </div>
                  <div className="card-body"></div>
                  <div >
                      <FullCalendar
                          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                          initialView={"dayGridMonth"}
                          locales={allLocales}
                          locale={"fr"}
                          headerToolbar={{
                              start: "",
                              center: "title",
                              end: "",
                             
                          
                          }}
                          
                          events={eventDataUpdate}
                          height={"90vh"}
                          eventClick={handleDateSelect}
                          eventClassNames="cursor-pointer hoverable"
                          eventContent={(arg) => {
                              return (
                                  <div className="fc-event-main" style={{ backgroundColor: "green" }}>
                                      <div className="fc-event-title-container">
                                          <div className="fc-event-title fc-sticky">{arg.event.title}</div>
                                      </div>
                                  </div>
                              );
                          }}
                          datesSet={(info) => {
                              const { start, end } = info.view.activeStart;
                              const filteredEvents = eventDataUpdate.filter((event) => {
                                  const eventStart = new Date(event.start);
                                  const eventEnd = new Date(event.end);
                                  return eventStart >= start && eventEnd <= end;
                              });
                              info.calendar?.removeAllEvents();
                              info.calendar?.addEventSource(filteredEvents);
                          }}
                      /> 


                  </div>
              </div>
          </div>
          <Modal show={showModalEvent} onHide={() => setShowModalEvent(false)}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>

                  <FormGroup>
                      <label className="form-control-label">
                          La référence du dossier
                      </label>
                      <Input
                          className="form-control-alternative edit-event--title"
                          readOnly="readOnly"
                          type="text"
                          value={showEvent?.reference}

                      />
                  </FormGroup>
                  <label className="form-control-label">
                      Message
                  </label>
                  <div className="col-12 col-lg-12 mb-3">
                      <textarea className="form-control mt-2" id="exampleFormControlTextarea1" rows="6"
                          value={showEvent?.message}
                          readOnly="readOnly"
                      ></textarea>
                  </div>



              </Modal.Body>
          </Modal>
      </div>
  )
}

export default ContentDashboard