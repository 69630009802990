import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import { Button, Input } from 'reactstrap'
import Sidebar from '../../components/Sidebar'
import Topbar from '../../components/Topbar'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { ToastContainer, toast } from 'react-toastify'
import {findOneFolder, updateFolder } from './folderSlice'
import jwt_decode from "jwt-decode"
import SidebarUser from '../../components/user_normal/SidebarUser'

const UpdateoneFolder = () => {

    const dispatch = useDispatch()
    const { allUsers } = useSelector((state) => state.user)

    const [authToken, setAuthToken] = useState(null)
    const navigate = useNavigate()
    const location = useLocation()
    const singleFolder = location.state
   // console.log(singleFolder)
    const { oneFolder } = useSelector((state) => state.folder)
  //  const [hasFile, setHasFile] = useState(false)
    
    const [folder, setFolder] = useState(null)
    const [decodedToken, setDecodedToken] = useState()

    const typeDossier = [
        {
            id: "RFE",
            text: "Expertise"
        },
        {
            id: "DHY",
            text: "Hypothèque"
        },
        {
            id: "DMC",
            text: "Mise en conformité"
        },
        {
            id: "LIP",
            text: "Loyer impayé/expulsion"
        },
        {
            id: "ANG",
            text: "Annulation d'assemblée générale"
        },
        {
            id: "DSI",
            text: "Saisie immobilière"
        },
        {
            id: "RCR",
            text: "Recouvrement de Créance"
        },
        {
            id: "DRC",
            text: "Recouvrement de charges"
        },
    ]
    const [selectedFiles, setSelectedFiles] = useState([])

    const handleFileSelect = (event) => {
      
        setSelectedFiles([...selectedFiles, ...event.target.files])
    }

    const handleFileDrop = (event) => {
        event.preventDefault()
        event.stopPropagation()
      
        setSelectedFiles([...selectedFiles, ...event.dataTransfer.files])
    }
    // useEffect(() => {
    //     if (selectedFiles.length > 0) {
    //         setHasFile(true)
    //     } else {
    //         setHasFile(false)
    //     }
    // }, [selectedFiles])
 
  
    useEffect(() => {
        if (localStorage.getItem('token')) {
            setAuthToken(localStorage.getItem('token'))
           //  dispatch(findAllUsers())
            dispatch(findOneFolder(singleFolder.id))
            setDecodedToken(jwt_decode(localStorage.getItem('token')))
            
        } else {
            navigate("/")
        }
    }, [navigate, dispatch])

    useEffect(() => {
        if (oneFolder) {
            setFolder(oneFolder)
        }
    }, [oneFolder])
    //console.log(folder)
    const handlUpdate = (event) => {
        event.preventDefault()
     
      
        
        if (folder.adresse === "" || folder.adresseCreancier === "" || folder.adresseDebiteur === "" ||
            folder.codePostal === "" || folder.identiteCreancier === "" || folder.identiteDebiteur === "" ||
            folder.observation === "" || folder.origineDette === "" || folder.refDossier === "" || folder.type === "" || folder.ville === "" ||
            folder.rcs === "" || folder.dateAg === "" || folder.dateRc === "" || folder.lotConcerne === "" || folder.siege === "" ||
            folder.societe === "") {
          
            toast.error('Veuillez remplir tous les champs !', {
                position: toast.POSITION.TOP_CENTER
            })
            
           
        } else {
            
            let id = folder.id
            
            dispatch(updateFolder({ id, folder,selectedFiles }))
            navigate("/admin/dashboard")
        }
    }
  if (authToken) {
      return (
          <div className='dashboard_wrapper'>
              {
                  decodedToken?.roles.length > 1 ? <Sidebar /> : <SidebarUser />
              }
              <div className='main_content'>
                  <Topbar />
                  <div className='content'>
                      <div className="content_header text-light">
                          <h1 className="fs-2 kanit-font">Dossiers</h1>
                          <div className="flex opacity-5">
                              <i className="bi-house-fill"></i>
                              <span>- Forms - Dossiers</span>
                          </div>
                      </div>
                      <div className="card bg-white">
                          <div className="card-header bg-light">
                              <div className="d-flex justify-content-between">
                                  <h2 className="fs-5 m-0 p-2">Modifier un dossier</h2>
                              </div>
                          </div>
                          <div className="card-body">
                              <div className="px-0 px-md-4 px-lg-5 py-4">
                                  <form onSubmit={handlUpdate} >
                                      <div className="row">
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="user" className="large fw-bolder mb-2"
                                              >Utilisateur</label >
                                              <select
                                                  name="user"
                                                  id="user"
                                                  value={folder.user?.["@id"]}
                                                  onChange={(e) => setFolder({ ...folder, user: e.target.value })}
                                                  className="form-control text-secondary"
                                                  style={{ height: "50px" }}
                                              >
                                                  <option value="">{folder?.user?.nom  + " " + folder.user?.prenom}</option>
                                                  {allUsers.map((user) => (
                                                      <option key={user.id} value={user?.["@id"]}>{user.nom + " " + user.prenom}</option>
                                                  ))}
                                              </select>
                                          </div>
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="type-dossier" className="large fw-bolder mb-2"
                                              >Type de dossier</label >
                                              <select name="type" id="type"
                                                  value={folder?.type}
                                                  onChange={(e) => setFolder({ ...folder, type: e.target.value })}
                                                  className="form-control text-secondary"
                                                  style={{ height: "50px" }}
                                              >

                                                  <option value="">{ folder?.type }</option>
                                                  {typeDossier.map((dossier) => (
                                                      <option key={dossier.id} value={dossier.id}>{dossier.text}</option>
                                                  ))}
                                              </select>
                                          </div>
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="reference" className="large fw-bolder mb-2"
                                              >Référence</label >
                                              <input
                                                  type="text"
                                                  value={folder?.refDossier}
                                                  placeholder="Référence"
                                                  name="reference"
                                                  className="form-control text-secondary" style={{ height: "50px" }}
                                                  onChange={(e) => setFolder({...folder, refDossier: e.target.value})}
                                              />
                                          </div>
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="adress" className="large fw-bolder mb-2"
                                              >Adresse</label >
                                              <input
                                                  type="text"
                                                  value={folder?.adresse}
                                                  placeholder="Adresse"
                                                  name="adress"
                                                  className="form-control text-secondary" style={{ height: "50px" }}
                                                  onChange={(e) => setFolder({ ...folder, adresse: e.target.value })}
                                              />
                                          </div>
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="code-postal" className="large fw-bolder mb-2"
                                              >Code postal</label >
                                              <input
                                                  type="text"
                                                  value={folder?.codePostal}
                                                  placeholder="Code postal"
                                                  name="code-postal"
                                                  className="form-control text-secondary" style={{ height: "50px" }}
                                                  onChange={(e) => setFolder({ ...folder, codePostal: e.target.value })}
                                              />
                                          </div>
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="ville" className="large fw-bolder mb-2"
                                              >Ville</label >
                                              <input
                                                  type="text"
                                                  value={folder?.ville}
                                                  placeholder="Ville"
                                                  name="ville"
                                                  className="form-control text-secondary" style={{ height: "50px" }}
                                                  onChange={(e) => setFolder({ ...folder, ville: e.target.value })}
                                              />
                                          </div>
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="observation" className="large fw-bolder mb-2"
                                              >Observation</label >
                                              <input
                                                  type="text"
                                                  value={folder?.observation}
                                                  placeholder="Observation"
                                                  name="observation"
                                                  className="form-control text-secondary" style={{ height: "60px" }}
                                                  onChange={(e) => setFolder({ ...folder, observation: e.target.value })}
                                              />
                                          </div>
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="identite-creancier" className="large fw-bolder mb-2"
                                              >Identité du créancier</label >
                                              <input
                                                  type="text"
                                                  value={folder?.identiteCreancier}
                                                  placeholder="Identité du créancier"
                                                  name="identite-creancier"
                                                  className="form-control text-secondary" style={{ height: "60px" }}
                                                  onChange={(e) => setFolder({ ...folder, identiteCreancier: e.target.value })}
                                              />
                                          </div>
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="adress-creancier" className="large fw-bolder mb-2"
                                              >Adresse du créancier</label >
                                              <input
                                                  type="text"
                                                  value={folder?.adresseCreancier}
                                                  placeholder="Adresse du créancier"
                                                  name="adress-creancier"
                                                  className="form-control text-secondary" style={{ height: "60px" }}
                                                  onChange={(e) => setFolder({ ...folder, adresseCreancier: e.target.value })}
                                              />
                                          </div>
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="identite-debiteur" className="large fw-bolder mb-2"
                                              >Identité du débiteur</label >
                                              <input
                                                  type="text"
                                                  value={folder?.identiteDebiteur}
                                                  placeholder="Identité du débiteur"
                                                  name="identite-debiteur"
                                                  className="form-control text-secondary" style={{ height: "60px" }}
                                                  onChange={(e) => setFolder({ ...folder, identiteDebiteur: e.target.value })}
                                              />
                                          </div>
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="adress-debiteur" className="large fw-bolder mb-2"
                                              >Adresse du débiteur</label >
                                              <input
                                                  type="text"
                                                  value={folder?.adresseDebiteur}
                                                  placeholder="Adresse du débiteur"
                                                  name="adress-debiteur"
                                                  className="form-control text-secondary" style={{ height: "60px" }}
                                                  onChange={(e) => setFolder({ ...folder, adresseDebiteur: e.target.value })}
                                              />
                                          </div>
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="societe" className="large fw-bolder mb-2"
                                              >Société</label >
                                              <input
                                                  type="text"
                                                  value={folder?.societe}
                                                  placeholder="Société"
                                                  name="societe"
                                                  className="form-control text-secondary" style={{ height: "50px" }}
                                                  onChange={(e) => setFolder({ ...folder, societe: e.target.value })}
                                              />
                                          </div>
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="capital" className="large fw-bolder mb-2"
                                              >Capital</label >
                                              <input
                                                  type="number"
                                                  value={folder?.capital}
                                                  placeholder="Capital"
                                                  name="capital"
                                                  className="form-control text-secondary" style={{ height: "50px" }}
                                                  onChange={(e) => setFolder({ ...folder, capital: parseFloat(e.target.value) })}
                                              />
                                          </div>
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="rcs" className="large fw-bolder mb-2"
                                              >RCS</label >
                                              <input
                                                  type="text"
                                                  value={folder?.rcs}
                                                  placeholder="RCS"
                                                  name="rcs"
                                                  className="form-control text-secondary" style={{ height: "50px" }}
                                                  onChange={(e) => setFolder({ ...folder, rcs: e.target.value })}
                                              />
                                          </div>
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="siege" className="large fw-bolder mb-2"
                                              >Siège</label >
                                              <input
                                                  type="text"
                                                  value={folder?.siege}
                                                  placeholder="Siège"
                                                  name="siege"
                                                  className="form-control text-secondary" style={{ height: "50px" }}
                                                  onChange={(e) => setFolder({ ...folder, siege: e.target.value })}
                                              />
                                          </div>
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="lot-concerne" className="large fw-bolder mb-2"
                                              >Lot concerné</label >
                                              <input
                                                  type="text"
                                                  value={folder?.lotConcerne}
                                                  placeholder="Lot concerné"
                                                  name="lot-concerne"
                                                  className="form-control text-secondary" style={{ height: "50px" }}
                                                  onChange={(e) => setFolder({ ...folder, lotConcerne: e.target.value })}
                                              />
                                          </div>
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="origine-dette" className="large fw-bolder mb-2"
                                              >Origine de la dette</label >
                                              <input
                                                  type="text"
                                                  value={folder?.origineDette}
                                                  placeholder="Origine de la dette"
                                                  name="origine-dette"
                                                  className="form-control text-secondary" style={{ height: "50px" }}
                                                  onChange={(e) => setFolder({ ...folder, origineDette: e.target.value })}
                                              />
                                          </div>
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="date-rc" className="large fw-bolder mb-2"
                                              >Date RC</label >
                                              <input
                                                  type="date"
                                                  value={folder?.dateRc?.substring(0, 10)}
                                                  placeholder="Date RC"
                                                  name="date-rc"
                                                  className="form-control text-secondary" style={{ height: "50px" }}
                                                  onChange={(e) => setFolder({ ...folder, dateRc: e.target.value })}
                                              />
                                          </div>
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="date-assemblee" className="large fw-bolder mb-2"
                                              >Date de l'assemblée générale</label >
                                              <input
                                                  type="date"
                                                  value={folder?.dateAg?.substring(0, 10)}
                                                  name="date-assmeblee"
                                                  className="form-control text-secondary" style={{ height: "50px" }}
                                                  onChange={(e) => setFolder({ ...folder, dateAg: e.target.value })}
                                              />
                                          </div>

                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="montant-dette" className="large fw-bolder mb-2"
                                              >Montant de la dette</label >
                                              <input
                                                  type="number"
                                                  value={folder?.montantDette}
                                                  name="montant-dette"
                                                  className="form-control text-secondary" style={{ height: "50px" }}
                                                  onChange={(e) => setFolder({ ...folder, montantDette: parseFloat(e.target.value) })}
                                              />
                                          </div>


                                      </div>
                                      <div className='col-12 col-lg-6 mb-3'>
                                          <label className="form-check-label mb-2" htmlFor="status-oui">
                                              Statut
                                          </label>
                                          <div className="form-check">
                                              <Input className="form-check-input" type="radio" name="status" id="status-actif"
                                                  value={true}
                                                  checked={folder.status === true}
                                                  onChange={(e) => setFolder({ ...folder, status: JSON.parse(e.target.value) })}
                                              />
                                              <label className="form-check-label" htmlFor="status-actif">
                                                  Actif
                                              </label>
                                          </div>
                                          <div className="form-check mb-2">
                                              <Input className="form-check-input" type="radio" name="status" id="status-archive"
                                                  value={false}
                                                  checked={folder.status === false}
                                                  onChange={(e) => setFolder({ ...folder, status: JSON.parse(e.target.value) })}
                                              />
                                              <label className="form-check-label" htmlFor="status-archive">
                                                  Archivé
                                              </label>
                                          </div>
                                      </div>
                                      <div className='col-12 col-lg-6 mb-3 ' controlId="file-upload">
                                          <label className='mb-3'>
                                              Sélectionnez ou déposez des fichiers ici
                                          </label>
                                          <Input type="file"
                                              multiple
                                              onChange={handleFileSelect}
                                              onDrop={handleFileDrop}
                                          />
                                      </div>
                                      {selectedFiles.length > 0 && (
                                          <ul>
                                              {selectedFiles.map((file, index) => (
                                                  <div key={file.index} className='file-preview d-flex'>
                                                      <li>{file.name}</li>

                                                      <img src={URL.createObjectURL(file)} alt={file.name} style={{ maxWidth: '30%', maxHeight: '100px' }} />


                                                      <Button className='btn btn-danger ms-2 p-1 tex-small mb-2 ' onClick={() => {

                                                          const newFiles = [...selectedFiles]
                                                          newFiles.splice(index, 1)
                                                          setSelectedFiles(newFiles)
                                                      }}>
                                                          <i class="bi bi-trash-fill"></i>
                                                          Supprimer</Button>
                                                  </div>
                                              ))}
                                          </ul>
                                      )}

                                      <div >
                                          <Button type='submit' className="btn_custom col-2 d-flex justify-content-center " style={{ backgroundColor: "#5e72e4" }}>Valider</Button>
                                      </div>
                                      <ToastContainer />
                                  </form>
                              </div>
                          </div>
                      </div>

                  </div>

                  <Footer />
              </div>

          </div>
      )
  } else {
      return null
  }
}

export default UpdateoneFolder