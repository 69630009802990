import React, { useEffect, useState } from 'react'
import { Button, Col } from 'reactstrap'
import Footer from '../../components/Footer'
import Sidebar from '../../components/Sidebar'
import Topbar from '../../components/Topbar'
import ReactQuill from 'react-quill'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { addMessage } from './messageSlice'
import moment from 'moment'

const AddMessage = () => {

    const [message,setMessage] = useState("")
    const [titre,setTitre] = useState("")

     const dispatch = useDispatch()
    const navigate = useNavigate()
    const [authToken, setAuthToken] = useState(null)

    useEffect(() => {
        if (localStorage.getItem('token')) {
            setAuthToken(localStorage.getItem('token'))
        } else {
            navigate("/")
        }

    }, [dispatch, navigate])

    const handleSubmit = (event) => { 
        event.preventDefault()

    if (message == "" || titre == "") {
        toast.error('Veuillez remplir tous les champs !', {
            position: toast.POSITION.TOP_CENTER
        })
    } else {
        if (titre.length < 4) {
            toast.error('Le titre du message doit avoir au moins 4 caractères" !', {
                position: toast.POSITION.TOP_CENTER
            })
        } else {
            let body = {
                message,
                titre,
                messageState: "valid",
                titreState: "valid"


            }
            dispatch(addMessage(body))
            navigate("/admin/dashboard")
        }
   

    }

    }

 if (authToken) {
     return (
         <div className='dashboard_wrapper'>
             <Sidebar />
             <div className='main_content'>
                 <Topbar />
                 <div className='content'>
                     <div className="content_header text-light">
                         <h1 className="fs-2 kanit-font">Message</h1>
                         <div className="flex opacity-5">
                             <i className="bi-house-fill"></i>
                             <span>- Forms - Message</span>
                         </div>
                     </div>
                     <div className="card bg-white">
                         <div className="card-header bg-light">
                             <div className="d-flex justify-content-between">
                                 <h2 className="fs-5 m-0 p-2">Ajouter un message</h2>
                             </div>
                         </div>
                         <div className="card-body">
                             <div className="px-0 px-md-4 px-lg-5 py-4">
                                 <form onSubmit={handleSubmit} >
                                     <div className="row">
                                         <div className="col-12 col-lg-6 mb-3">
                                             <label htmlFor="title" className="large fw-bolder mb-2"
                                             >Titre</label >
                                             <input
                                                 type="text"
                                                 value={titre}
                                                 placeholder="Titre"
                                                 name="title"
                                                 className="form-control text-secondary" style={{ height: "50px" }}
                                                 onChange={(e) => setTitre(e.target.value)}
                                             />
                                         </div>
                                         <Col md="6">
                                             <label
                                                 className="large fw-bolder"
                                                 htmlFor="message"
                                             >
                                                 Message
                                             </label>
                                             <textarea className="form-control mt-2" id="exampleFormControlTextarea1" rows="5"
                                                 value={message}
                                                 placeholder="Message"
                                                 name="message"
                                                 onChange={(e) => setMessage(e.target.value)}
                                             ></textarea>                                            
                                         </Col>

                                     </div>
                                     <div className="text-center">
                                         <Button type='submit' className="btn_custom col-2 d-flex justify-content-center " style={{ backgroundColor: "#5e72e4" }}>Valider</Button>
                                     </div>
                                     <ToastContainer />
                                 </form>
                             </div>
                         </div>
                     </div>

                 </div>

                 <Footer />
             </div>

         </div>
     )
 } else {
    return null
 }
}

export default AddMessage