import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import jwtDecode from 'jwt-decode'
import { API_URL, SEND_MAIL_SUPPORT, USERS_API } from "../../config"




const api = axios.create({
    baseURL: API_URL,
})
 // to check if token is expired before doing a request
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token')

        if (token) {
            const decodedToken = jwtDecode(token)
            const now = Date.now() / 1000
           // console.log("hour now: " + now)
            // console.log("token hour: ", decodedToken)

            if (now > decodedToken.exp) {
               // console.log("token expire")
                localStorage.removeItem('token')
                window.location.reload()
            } else {
                config.headers.Authorization = `Bearer ${token}`
            }
        }

        return config;
    },
    (error) => Promise.reject(error)
)
export const findAllUsers = createAsyncThunk(
    "gets/findAllUsers",
    async (thunkApi) => {
        try {
           // const token = localStorage.getItem('token')
            const response = await api.get(USERS_API + "/?timestamp=" + new Date().getTime())
           // console.log(response.data['hydra:member'])
            return response.data['hydra:member']
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const countAllClientsActifs = createAsyncThunk(
    "gets/countAllClientsActifs",
    async (thunkApi) => {
        //const token = localStorage.getItem("token")
        try {
            const response = await api.get(USERS_API + "/count/1")
            // console.log("count ", response)
            return response.data
        } catch (error) {
            //console.log(error)
            return thunkApi.rejectWithValue(error.message)
        }
    }
)

export const addUser = createAsyncThunk(
    "posts/addUser",
    async (userData, thunkApi) => {
        try {
           // const token = localStorage.getItem('token')
            const response = await api.post(USERS_API,userData)
         
           // console.log("response", response.data["hydra:member"])
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const updateUser = createAsyncThunk(
    "post/updateUser",
    async (userData, thunkApi) => {
        try {
           // const token = localStorage.getItem('token')
            const response = await api.put(USERS_API + "/" + userData.id,userData)
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
     }
)

export const contactSupport = createAsyncThunk(
    "post/contactSupport",
    async (mail, thunkApi) => { 
       try {
            await api.post(SEND_MAIL_SUPPORT, mail)
        
       } catch (error) {
        
       }
    }
)
  


const initialState = {
    allUsers: [],
    error: null,
    user: {},
    numberClientsActifs: null,
    loading: true,

}

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(findAllUsers.pending, (state, action) => {
                state.loading = true
             })
            .addCase(findAllUsers.fulfilled, (state, action) => {
                state.allUsers = action.payload
                state.loading = false
            })
            .addCase(findAllUsers.rejected, (state, action) => {
                state.error = action.payload
            })
            // .addCase(findUserByEmail.pending, (state, action) => {
            //     state.loading = true
            //  })
            // .addCase(findUserByEmail.fulfilled, (state, action) => {
            //     state.user = action.payload
            //     state.loading = false
            // })
            // .addCase(findUserByEmail.rejected, (state, action) => {
            //     state.error = action.payload
            // })
            .addCase(addUser.pending, (state, action) => {
                state.loading = true
             })
            .addCase(addUser.fulfilled, (state, action) => {
                state.allUsers.push(action.payload)
            })
            .addCase(addUser.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(updateUser.pending, (state, action) => {
                state.loading = true
             })
            .addCase(updateUser.fulfilled, (state, action) => {
                const updateUser = action.payload
                const index = state.allUsers.findIndex(a => a.id === updateUser.id)
                if (index !== -1) {
                  
                    state.allUsers[index] = updateUser
                }
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(countAllClientsActifs.pending, (state, action) => {
                state.loading = true
            })
            .addCase(countAllClientsActifs.fulfilled, (state, action) => {
                state.loading = false
                state.numberClientsActifs = action.payload
            })
            .addCase(countAllClientsActifs.rejected, (state, action) => {
                state.error = action.payload
            })
    }
})


export default userSlice.reducer

