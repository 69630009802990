import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import {  MESSAGES_API, api } from "../../config"




export const findAllMessages = createAsyncThunk(
    "gets/findAllMessages",
    async (thunkApi) => {
        try {
          //  const token = localStorage.getItem("token")
            const response = await api.get(MESSAGES_API)
             //  console.log("all response", response.data["hydra:member"])
            return response.data["hydra:member"]
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const addMessage = createAsyncThunk(
    "post/addMessage",
    async (message, thunkApi) => {

        try {

            //const token = localStorage.getItem('token')
            const response = await api.post(MESSAGES_API, message)
            //console.log(response)
           return response.data
        } catch (error) {
          
            return thunkApi.rejectWithValue(error.message)
        }
    }
)
export const updateMessage = createAsyncThunk(
    "puts/updateMessage",
    async (message, thunkApi) => {

        try {

           // const token = localStorage.getItem('token')
            const response = await api.put(MESSAGES_API +"/" + message.id, message)
          
            return response.data
        } catch (error) {
          
            return thunkApi.rejectWithValue(error.message)
        }
    }
)
export const deleteMessage = createAsyncThunk(
    "delete/deleteMessage",
    async (id, thunkApi) => {

        try {

          //  const token = localStorage.getItem('token')
            const response = await api.delete(MESSAGES_API + "/" + id)
          
           // console.log("delete message", response)
           // return response.data
        } catch (error) {
            console.log(error)
            return thunkApi.rejectWithValue(error.message)
        }
    }
)

const initialState = {
    allMessages: [],
    error: null,
    loading: true,
}


const messageSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(findAllMessages.pending, (state, action) => {
               // state.allMessages = action.payload
                state.loading = true
            })
            .addCase(findAllMessages.fulfilled, (state, action) => {
                state.allMessages = action.payload
                state.loading = false
               
            })
            .addCase(findAllMessages.rejected, (state, action) => {

                state.error = action.payload
            })
            .addCase(addMessage.pending, (state, action) => {
            
                state.loading = true
            })
            .addCase(addMessage.fulfilled, (state, action) => {
                state.allMessages.push(action.payload)
                state.loading = false
               
            })
            .addCase(addMessage.rejected, (state, action) => {

                state.error = action.payload
            })
            .addCase(updateMessage.pending, (state, action) => { 
                state.loading = true
            })
            .addCase(updateMessage.fulfilled, (state, action) => { 
                const updateMessage = action.payload
                const index = state.allMessages.findIndex(a => a.id === updateMessage.id)
                //  console.log("out if",index)
                if (index !== -1) {
                    // console.log("index if: " + index)
                   state.allMessages[index] = updateMessage
                }
            })
           
    }
})

export default messageSlice.reducer
