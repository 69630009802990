import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from 'reactstrap'
import jwt_decode from "jwt-decode"


const Topbar = () => {

    const navigate = useNavigate()
    const token = localStorage.getItem('token')

    const decodedToken = jwt_decode(token)

    const logout = () => {
        localStorage.removeItem("token")
        navigate("/")
    }
  return ( 
      <div className="topbar dashboard_bg">
          <div className="toggler">
              <div className="shrink light">
                  <i className="bi-list"></i>
              </div>
          </div>
          <div className="d-flex align-items-center">
              <div className="profile_pic">
                  {/* <!-- this is like a fallback whenever there is no profile picture --> */}
                  <i className="bi-person-fill fs-5 text-light"></i>

                  {/* <!-- and this is the profile picture --> */}
                  {/* <!-- <img src="linmk/of/the/image" alt="" className="cover"> --> */}
              </div>

              <div className="dropdown ms-3">
                  <a href='/#'
                      className="nav-link dropdown-toggle dropdown_button text-light"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      role="button"
                  >
                    {decodedToken?.firstName} {decodedToken?.lastName}
                  </a>
                  <ul className="dropdown-menu">
                      <li>
                          <h5
                              className="fs-5 dropdown-item text-primary bg_none"
                          >
                              Bienvenue !
                          </h5>
                      </li>
                      <li><hr className="dropdown-divider" /></li>
                      <li>
                          <Link to="/admin/profile" className="dropdown-item text-muted" >
                              <i className="bi-person-fill me-1"></i>
                              <span>Mon profil</span>
                          </Link>
                      </li>
                      <li>
                          <a
                              href="/#"
                              className="dropdown-item text-muted"
                          >
                              <i className="bi-calendar2-week me-1"></i>
                              <span>Mon activité</span>
                          </a>
                      </li>
                      <li>
                          <Link to="/admin/support"
                              className="dropdown-item text-muted"
                          >
                              <i className="bi-question-circle me-1"></i>
                              <span>Support</span>
                          </Link>
                      </li>
                      <li><hr className="dropdown-divider" /></li>
                      <li>
                          <Button
                              className="dropdown-item text-muted"
                              onClick={logout}
                          >
                              <i className="bi-door-closed me-1"></i>
                              <span>Déconnexion</span>
                          </Button>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  )
}

export default Topbar