import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar'
import Topbar from '../../components/Topbar'
import Footer from '../../components/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { findAgence } from '../agences/agenceSlice'
import jwt_decode from "jwt-decode"
import { findAllUsers } from './userSlice'
import SidebarUser from '../../components/user_normal/SidebarUser'

const Profile = () => {


    const token = localStorage.getItem('token')

    const decodedToken = jwt_decode(token)


    const { allUsers } = useSelector((state) => state.user)
    const { oneAgence } = useSelector((state) => state.agence)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [authToken, setAuthToken] = useState(null)
    const [idAgency, setIdAgency] = useState(null)
   
 

    const loadData = async () => { 
        const foundUser = allUsers?.find(u => u.email === decodedToken.username)
        const agencyId = foundUser?.agence?.['@id']?.split('/').pop()
        setIdAgency(agencyId)
         dispatch(findAgence(idAgency))
    }
    useEffect(() => {
        if (localStorage.getItem('token')) {
            setAuthToken(localStorage.getItem('token'))
            dispatch(findAllUsers())
            loadData()

        } else {
            navigate("/")
        }

    }, [allUsers,dispatch])

   if (authToken) {
       return (
           <div className='dashboard_wrapper'>
               {
                   decodedToken.roles.length > 1 ? <Sidebar /> : <SidebarUser />
               }
               <div className='main_content'>
                   <Topbar />
                   <div className='content'>
                       <div className="content_header text-light">
                           <h1 className="fs-2 kanit-font">Profile</h1>
                           <div className="flex opacity-5">
                               <i className="bi-house-fill"></i>
                               <span>- Pages - Profil</span>
                           </div>
                       </div>
                       <div className="card bg-white">
                           <div className="card-header bg-light">
                               <div className="d-flex justify-content-between">
                                   <h2 className="fs-5 m-0 p-2">Profil</h2>
                               </div>
                           </div>
                           <div className="card-body">
                               <div className="px-0 px-md-4 px-lg-5 py-4">
                                   <p className="text-muted">
                                       INFORMATIONS UTILISATEUR
                                   </p>
                                   <div className="row">
                                       <div className="col-12 col-lg-6 mb-3">
                                           <label htmlFor="email" className="small"
                                           >Email</label>
                                           <input
                                               type="email"
                                               value={decodedToken?.username}
                                               disabled
                                               placeholder="Email"
                                               name="email"
                                               className="form-control"
                                           />
                                       </div>
                                       <div className="col-12 col-lg-6 mb-3">
                                           <label htmlFor="first-name" className="small"
                                           >Prénom</label>
                                           <input
                                               type="text"
                                               disabled
                                               value={decodedToken?.firstName}
                                               placeholder="Prénom"
                                               name="first-name"
                                               className="form-control"
                                           />
                                       </div>
                                       <div className="col-12 col-lg-6 mb-3">
                                           <label htmlFor="name" className="small"
                                           >Nom</label>
                                           <input
                                               type="text"
                                               disabled
                                               value={decodedToken?.lastName}
                                               placeholder="Nom"
                                               name="name"
                                               className="form-control"
                                           />
                                       </div>
                                   </div>
                               </div>
                               <div className="px-0 px-md-4 px-lg-5 py-4">
                                   <p className="text-muted">
                                       INFORMATIONS AGENCE
                                   </p>
                                   <div className="row">
                                       <div className="col-12 col-lg-6 mb-3">
                                           <label htmlFor="adresse" className="small"
                                           >Adresse </label>
                                           <input
                                               type="text"
                                               disabled
                                               value={oneAgence?.adresse}
                                               placeholder="adresse"
                                               name="adresse"
                                               className="form-control"
                                           />
                                       </div>
                                       <div className="col-12 col-lg-6 mb-3">
                                           <label htmlFor="ville" className="small"
                                           >Ville </label>
                                           <input
                                               type="text"
                                               disabled
                                               value={oneAgence.ville}
                                               placeholder="ville"
                                               name="ville"
                                               className="form-control"
                                           />
                                       </div>
                                       <div className="col-12 col-lg-6 mb-3">
                                           <label htmlFor="codePostal" className="small"
                                           >Code Postal</label>
                                           <input
                                               type="text"
                                               disabled
                                               value={oneAgence.codePostal}
                                               placeholder="code Postal"
                                               name="codePostal"
                                               className="form-control"
                                           />
                                       </div>
                                   </div>
                               </div>

                           </div>
                       </div>

                   </div>

                   <Footer />
               </div>

           </div>
       )
   } else {
    return null
   }
}

export default Profile