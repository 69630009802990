import React, { useEffect, useState } from 'react'
import Topbar from '../../components/Topbar'
import Sidebar from '../../components/Sidebar'
import Footer from '../../components/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { findAllAgences } from './agenceSlice'
import Loader from '../../components/Loader'
import ReactPaginate from 'react-paginate'


const Agences = () => {

    const [currentPage, setCurrentPage] = useState(0)
    const [perPage, setPerPage] = useState(9)

    const [authToken, setAuthToken] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { allAgences } = useSelector(state => state.agence)
    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token) {
            setAuthToken(token)
            dispatch(findAllAgences())
        } else {
            navigate("/")
        }
    }, [navigate, dispatch])
    const offset = currentPage * perPage
    const filteredAgences = allAgences.filter((agence) => {
        const lowerCaseSearchText = searchText.toLowerCase()
        return agence.nom.toLowerCase().includes(lowerCaseSearchText)
    })
    const allCurrentAgences = searchText ? filteredAgences : allAgences
    const pageCount = allCurrentAgences ? Math.ceil(allCurrentAgences.length / perPage) : 0
    const currentAgences = allCurrentAgences ? allCurrentAgences.slice(offset, offset + perPage) : []


    if (authToken) {
        return (
            <div className='dashboard_wrapper'>
                <Sidebar />
                <div className='main_content'>
                    <Topbar />
                    <div className='content'>
                        <div className="content_header text-light">
                            <h1 className="fs-2 kanit-font">Agences</h1>
                            <div className="flex opacity-5">
                                <i className="bi-house-fill"></i>
                                <span>- Consultation - Agences</span>
                            </div>
                        </div>
                        {/* <!-- right here is a wide card where the majority of thing will be placed --> */}
                        <div className="card bg-white">
                            <div className="card-header bg-light">
                                <div className="d-flex justify-content-between">
                                    <h2 className="fs-5 m-0 p-2">Liste des agences</h2>
                                    <Link to="/admin/agences/new" href="" className="btn_custom">Créer une agence</Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-flex justify-content-end mb-3 mt-2">
                                    <div className="d-flex align-items-center col-5">
                                        <div className="input-group me-3">
                                            <input type="text" className="form-control" placeholder="Recherche"
                                                aria-label="Recherche" aria-describedby="basic-addon2"
                                                value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-sortable">
                                        <thead>
                                            <tr>
                                                <th data-type="alpha" className='text-secondary small'>Nom</th>
                                                <th data-type="alpha" className='text-secondary small'>Adresse</th>
                                                <th data-type="numeric" className='text-secondary small'>Code Postal</th>
                                                <th data-type="alpha" className='text-secondary small'>Ville</th>
                                                <th data-type="alpha" className='text-secondary small'>Agence</th>
                                                <th className='text-secondary'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(currentAgences).length ?
                                                    currentAgences.map((agence) => {
                                                        return (
                                                            <tr key={agence.id}>
                                                                <td className='small'>{agence.nom}</td>
                                                                <td className='small'>{agence.adresse}</td>
                                                                <td className='small'>{agence.codePostal}</td>
                                                                <td className='small'>{agence.ville}</td>
                                                                <td className='small'>{agence.codeAgence}</td>
                                                                <td className='d-flex gap-1'>
                                                                    <Link to={`/admin/agences/${agence?.id}`} state={agence}
                                                                        className="btn btn-primary" style={{ backgroundColor: "#5e72e4" }}
                                                                        data-toggle="tooltip"
                                                                        data-placement="top" title="Editer l'agence">
                                                                        <i className="bi bi-gear-fill text-white"></i>
                                                                    </Link>
                                                                    <Link to={`/admin/userByAgence/${agence?.id}`} state={agence}
                                                                        className="btn btn-secondary" style={{ backgroundColor: "#135b6c" }}
                                                                        data-toggle="tooltip" data-placement="top"
                                                                        title="Liste des utilisateurs">
                                                                        <i className="bi bi-list text-white"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : <Loader colSpan={6} />
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                <ReactPaginate
                                    previousLabel={'previous'}
                                    nextLabel={'next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={4}
                                    onPageChange={(data) => {
                                    setCurrentPage(data.selected)
                                    }}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />

                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>

            </div>
        )
    } else {
        return null
    }

}

export default Agences