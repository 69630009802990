import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { AGENCES_API, API_URL, DOSSIERS_API, DOSSIERS_TYPE, MEDIA_API, MESSAGERIE_API, USERS_API } from "../../config"

 export const api = axios.create({
    baseURL: API_URL,
})
// to check if token is expired before doing a request
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token')

        if (token) {
            const decodedToken = jwtDecode(token)
            const now = Date.now() / 1000
            // console.log("hour now: " + now)
            // console.log("token hour: ", decodedToken)

            if (now > decodedToken.exp) {
                // console.log("token expire")
                localStorage.removeItem('token')
                window.location.reload()
            } else {
                config.headers.Authorization = `Bearer ${token}`
            }
        }

        return config;
    },
    (error) => Promise.reject(error)
)


export const findAllFoldersActifs = createAsyncThunk(
    "gets/findAllFoldersActifs",
    async (thunkApi) => {
       // const token = localStorage.getItem("token")
        try {
            const response = await api.get(DOSSIERS_API + "/user?", {
                params: {
                    status: 1,
                }
            })
           //  console.log("folders", response.data.data)
            return response.data.data
        } catch (error) {
            return thunkApi.rejectWithValue(error.message)
        }
    }
)


// export const findAllFoldersLogs = createAsyncThunk(
//     "gets/findAllFoldersLogs",
//     async (thunkApi) => {
//        // const token = localStorage.getItem("token")
//         try {
//             const response = await api.get(apiGetAllFoldersLogs)
//              console.log("folders", response)
//           //  return response.data.data
//         } catch (error) {
//             return thunkApi.rejectWithValue(error.message)
//         }
//     }
// )
export const findTypeFolders = createAsyncThunk(
    "gets/findTypeFolders",
    async (thunkApi) => {
       // const token = localStorage.getItem("token")
        try {
            const response = await api.get(DOSSIERS_TYPE)
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error.message)
        }
    }
)
export const countAllFoldersActifs = createAsyncThunk(
    "gets/countAllFoldersActifs",
    async (thunkApi) => {
       // const token = localStorage.getItem("token")
        try {
            const response = await api.get(DOSSIERS_API + "/count/1")
            // console.log("count ", response)
            return response.data
        } catch (error) {
            //console.log(error)
            return thunkApi.rejectWithValue(error.message)
        }
    }
)
export const findAllFolders = createAsyncThunk(
    "gets/findAllFolders",
    async (thunkApi) => {
       // const token = localStorage.getItem("token")
        try {
            const response = await api.get(DOSSIERS_API + "/?timestamp=" + new Date().getTime())
        
            return response.data["hydra:member"]
        } catch (error) {
            return thunkApi.rejectWithValue(error.message)
        }
    }
)
export const findOneFolder = createAsyncThunk(
    "gets/findOneFolder",
    async (idDossier, thunkApi) => {
        //const token = localStorage.getItem("token")
        try {
            const response = await api.get(DOSSIERS_API + "/" + idDossier)
            // console.log("folder", response)
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error.message)
        }
    }
)
export const findFilesByfolder = createAsyncThunk(
    "get/findFilesByfolder",
    async (idDossier, thunkApi) => {
       // const token = localStorage.getItem("token")
        try {
            const response = await api.get(MEDIA_API, {
                params: {
                    dossier: idDossier,
                    dossierLogs: 'null'
                }
            })
            // console.log("folder", response)
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error.message)
        }
    }

)
export const findFilesByMessage = createAsyncThunk(
    "get/findFilesByMessage",
    async ({ idMessage , idDossier}, thunkApi) => {
        //const token = localStorage.getItem("token")
        
        try {
            const response = await api.get(MEDIA_API, {
                params: {
                    dossier: idDossier,
                    dossierLogs: idMessage
                }
            })
            // console.log("ffiless b", response)
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error.message)
        }
    }

)
export const addFolder = createAsyncThunk(
    "posts/addFolder",
    async ({ body, selectedFiles }, thunkApi) => {
       // const token = localStorage.getItem("token")
        // Create a new folder without a file
        try {
            if (selectedFiles.length === 0) {
                const response = await api.post(DOSSIERS_API, body)
                return response.data
            }
        } catch (error) {
            // console.log("create folder whithout file: " + error)
            return null
        }

        // create a new folder with file
        try {

            if (selectedFiles.length > 0) {
                const response = await api.post(DOSSIERS_API, body)
                let dossier = response.data
                //  console.log("dossier create",dossier)
                const user = await api.get(USERS_API + "/" + dossier.user.id)
                //  console.log("user find", user)
                const allAgences = await api.get(AGENCES_API + '/?timestamp=' + new Date().getTime())
                // console.log("all agences", allAgences)
                const agenceUser = allAgences?.data['hydra:member'].find((it) => {

                    return String(it['@id']) === String(user?.data.agence['@id'])
                })

                await Promise.all(
                    selectedFiles.map(async (f) => {

                        const formData = new FormData()
                        formData.append("file_path", f)
                        formData.append("rep", agenceUser.codeAgence)
                        formData.append("target", agenceUser.codeAgence)
                        formData.append("createdAt", new Date().getTime())
                        formData.append("dossierId", dossier.id)
                      //  console.log('send file', Object.fromEntries(formData.entries()))
                        const fileCreated = await api.post(MEDIA_API, formData, {
                            headers: {
                                 'Content-Type': 'multipart/form-data',
                            },
                        })
                        //  console.log("dossier file created", dossierFile)
                        return fileCreated


                    })
                )
                return response.data
            }

        } catch (error) {
            // console.log("erreur create with file", error)
            return thunkApi.rejectWithValue(error.message)
        }
    }
)
export const sendMessageFolder = createAsyncThunk(
    "posts/sendMessageFolder",
    async ({ body, selectedFiles }, thunkApi) => {
       // const token = localStorage.getItem("token")
        if (selectedFiles.length === 0) {
            try {
                const response = await api.post(MESSAGERIE_API, body)

                return response.data
            } catch (error) {
               // console.log("send message without file", error)
                return thunkApi.rejectWithValue(error.message)
            }
        }

        if (selectedFiles.length > 0) {
            try {
                const response = await api.post(MESSAGERIE_API, body)
                let dossierLog = response.data
              //  console.log("dossier log", dossierLog)
                const user = await api.get(USERS_API + "/" + dossierLog.dossier.user.id)
                //console.log("user find", user)
                const allAgences = await api.get(AGENCES_API + '/?timestamp=' + new Date().getTime())
              //  console.log("all agences", allAgences)
                const agenceUser = allAgences?.data['hydra:member'].find((it) => {
                    if (String(it['@id']) === String(user?.data.agence['@id'])) {
                        return it
                    }
                    return null
                    // return String(it['@id']) === String(user?.data.agence['@id'])
                })
               // console.log("agence user: " + agenceUser.codeAgence)

                await Promise.all(
                    selectedFiles.map(async (f) => {

                        const formData = new FormData()
                        formData.append("file_path", f)
                        formData.append("rep", agenceUser.codeAgence)
                        formData.append("target", agenceUser.codeAgence)
                        formData.append("createdAt", new Date().getTime())
                        formData.append("dossierLogsId", dossierLog.id)
                       // console.log('send file', Object.fromEntries(formData.entries()))
                        const fileCreated = await api.post(MEDIA_API, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'

                            },
                        })
                        //  console.log("dossier file created", dossierFile)
                        return fileCreated                         


                    })

                )
                return response.data

            } catch (error) {
               // console.log("send message with file", error)
                return thunkApi.rejectWithValue(error.message)
            }
        }

    }
)
export const updateMessageFolder = createAsyncThunk(
    "post/updateMessageFolder",
    async ({ idMessage, body, selectedFiles }, thunkApi) => {
       // const token = localStorage.getItem("token")
        
        if (selectedFiles.length === 0) {
            try {
                const response = await api.put(MESSAGERIE_API + "/" + idMessage, body)

                return response.data
            } catch (error) {
                //  console.log("erreur",error)
                return thunkApi.rejectWithValue(error.message)
            }
        }

        if (selectedFiles.length > 0) {
            try {
                const response = await api.put(MESSAGERIE_API + "/" + idMessage, body)
                let dossierLog = response.data
                 // console.log("dossier log",dossierLog)
                
                const user = await api.get(USERS_API + "/" + dossierLog.dossier.user.id)
                //  console.log("user find", user)
                const allAgences = await api.get(AGENCES_API + '/?timestamp=' + new Date().getTime())
                // console.log("all agences", allAgences)
                const agenceUser = allAgences?.data['hydra:member'].find((it) => {
                    return String(it['@id']) === String(user?.data.agence['@id'])
                })

                await Promise.all(
                    selectedFiles.map(async (f) => {

                        const formData = new FormData()
                        formData.append("file_path", f)
                        formData.append("rep", agenceUser.codeAgence)
                        formData.append("target", agenceUser.codeAgence)
                        formData.append("createdAt", new Date().getTime())
                        formData.append("dossierLogsId", dossierLog.id)
                   //  console.log('send file', Object.fromEntries(formData.entries()))
                        const fileCreated = await api.post(MEDIA_API, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'

                            },
                        })
                        //  console.log("dossier file created", dossierFile)
                        return fileCreated


                    })
                )
                return response.data
            } catch (error) {
                 // console.log("erreur update with file",error)
                return thunkApi.rejectWithValue(error.message)
            }
        }

      
    }
)



export const updateFolder = createAsyncThunk(
    "post/updateFolder",
    async ({ id, folder, selectedFiles }, thunkApi) => {

       // const token = localStorage.getItem("token")
        const newFolder = { ...folder, user: folder.user["@id"] }

        if (selectedFiles.length === 0) {
            try {
                const response = await api.put(DOSSIERS_API + "/" + id, newFolder)
                // console.log("response",response.data)
                return response.data
            } catch (error) {
                //  console.log("erreur with out file",error)
                return thunkApi.rejectWithValue(error.message)
            }
        }
        try {

            if (selectedFiles.length > 0) {
                const response = await api.put(DOSSIERS_API + "/" + id, newFolder)
                let dossier = response.data
                //  console.log("dossier create",dossier)
                const user = await api.get(USERS_API + "/" + folder.user.id)
                //  console.log("user find", user)
                const allAgences = await api.get(AGENCES_API + '/?timestamp=' + new Date().getTime())
                // console.log("all agences", allAgences)
                const agenceUser = allAgences?.data['hydra:member'].find((it) => {
                    return String(it['@id']) === String(user?.data.agence['@id'])
                })

                await Promise.all(
                    selectedFiles.map(async (f) => {

                        const formData = new FormData()
                        formData.append("file_path", f)
                        formData.append("rep", agenceUser.codeAgence)
                        formData.append("target", agenceUser.codeAgence)
                        formData.append("createdAt", new Date().getTime())
                        formData.append("dossierId", dossier.id)
                          //console.log('send file', Object.fromEntries(formData.entries()))
                        const fileCreated = await api.post(MEDIA_API, formData, {
                            headers: {
                                 'Content-Type': 'multipart/form-data'

                            },
                        })
                        //  console.log("dossier file created", dossierFile)
                        return fileCreated


                    })
                )
                return response.data
            }

        } catch (error) {
           // console.log("erreur update with file", error)
            return thunkApi.rejectWithValue(error.message)
        }

    }
)
export const transfertFolder = createAsyncThunk(
    "post/transfertFolder",
    async ({ idCurrentUser, idDestination }, thunkApi) => {
      //  const token = localStorage.getItem("token")
   console.log(idCurrentUser, idDestination)
        try {
            const response = await api.put(USERS_API + '/' + idCurrentUser + '/dossiers/' + idDestination)
            //console.log("response", response)
            return response.data
        } catch (error) {
           // console.log("erreur", error)
            return thunkApi.rejectWithValue(error.message)
        }
    }
) 

export const deletMessageFolder = createAsyncThunk(
    "delete/deleteMessage",
    async (idMessage, thunkApi) => {
       // const token = localStorage.getItem("token")

        try {
             await api.delete(MESSAGERIE_API + "/" + idMessage)
           // console.log(response)

        } catch (error) {
            //  console.log("erreur",error)
            return thunkApi.rejectWithValue(error.message)
        }
    }
)

export const userByFolder = createAsyncThunk(
    "gets/userByFolder",
    async (userId, thunkApi) => {
        try {
           // const token = localStorage.getItem('token')
            const response = await api.get(USERS_API + '/' + userId + '/dossiers')
            //  console.log("response",response.data["hydra:member"])
            return response.data["hydra:member"]
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const findAllFoldersArchives = createAsyncThunk(
    "gets/findAllFoldersArchives",
    async (thunkApi) => {
       // const token = localStorage.getItem("token")
        try {
            const response = await api.get(DOSSIERS_API + "/user?",
                {
                    params: {
                        status: 0,
                        createdAtBegin: "2020-01-01",
                        start: 0,
                    }
                })
            // console.log("folders archives",response)
            return response.data.data
        } catch (error) {
            return thunkApi.rejectWithValue(error.message)
        }
    }
)
export const findMessagesByFolder = createAsyncThunk(
    "gets/findMessagesByFolder",
    async (dossierId, thunkApi) => {
       // const token = localStorage.getItem("token")
        try {
            const response = await api.get(MESSAGERIE_API,
                {
                    params: {
                        dossier: dossierId,
                        timestamp: new Date().getTime()
                    }
                })
            //console.log("messages by folder", response.data["hydra:member"])
            return response.data["hydra:member"]
        } catch (error) {
            return thunkApi.rejectWithValue(error.message)
        }
    }
)

export const archiveFolder = createAsyncThunk(
    "post/archiveFolder",
    async ({ id, body }, thunkApi) => {
       // const token = localStorage.getItem("token")
        try {
            const response = await api.put(DOSSIERS_API + "/ " + id, body)
            // console.log("response",response.data)
            return response.data
        } catch (error) {
            //  console.log("erreur",error)
            return thunkApi.rejectWithValue(error.message)
        }
    }
)
export const activeFolder = createAsyncThunk(
    "post/activeFolder",
    async ({ id, body }, thunkApi) => {
      //  const token = localStorage.getItem("token")
        try {
            const response = await api.put(DOSSIERS_API + "/ " + id, body)
            //console.log("response",response.data.data)
            return response.data
        } catch (error) {
            //  console.log("erreur",error)
            return thunkApi.rejectWithValue(error.message)
        }
    }
)
export const searchFoldersPage = createAsyncThunk(
    "gets/searchFoldersPage",
    async ({ type, refDossier, adresse, status, identiteDebiteur }, thunkApi) => {

       // const token = localStorage.getItem("token")
        try {
            const response = await api.get(DOSSIERS_API + "/user?",
                {
                    params: {
                        type: type,
                        status: status,
                        refDossier: refDossier,
                        adresse: adresse,
                        identiteDebiteur: identiteDebiteur,
                    }
                })
            // console.log("folders search",response.data.data)
            return response.data.data
        } catch (error) {
            return thunkApi.rejectWithValue(error.message)
        }
    }
)


const initialState = {
    allFolders: [],
    allFoldersActifs: [],
    userByFolders: [],
    allFoldersArchives: [],
    allFoldersSearch: [],
    allMessagesByFolder: [],
    oneFolder: {},
    numberFoldersActifs: null,
    filesFolder: [],
    filesMessage: [],
    typeDossiers: [],
    fileDownload: "",
    loading: false,
    error: null
}

const folderSlice = createSlice({
    name: "folder",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(findAllFoldersActifs.pending, (state, action) => {
                state.loading = true
            })
            .addCase(findAllFoldersActifs.fulfilled, (state, action) => {
                state.loading = false
                state.allFoldersActifs = action.payload
            })
            .addCase(findAllFoldersActifs.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(findTypeFolders.pending, (state, action) => {
                state.loading = true
            })
            .addCase(findTypeFolders.fulfilled, (state, action) => {
                state.loading = false
                state.typeDossiers = action.payload
            })
            .addCase(findTypeFolders.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(findFilesByfolder.pending, (state, action) => {
                state.loading = true
            })
            .addCase(findFilesByfolder.fulfilled, (state, action) => {
                state.loading = false
                state.filesFolder = action.payload
            })
            .addCase(findFilesByfolder.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(findFilesByMessage.pending, (state, action) => {
                state.loading = true
            })
            .addCase(findFilesByMessage.fulfilled, (state, action) => {
                state.loading = false
                state.filesMessage = action.payload
            })
            .addCase(findFilesByMessage.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(findAllFolders.pending, (state, action) => {
                state.loading = true
            })
            .addCase(findAllFolders.fulfilled, (state, action) => {
                state.loading = false
                state.allFolders = action.payload
            })
            .addCase(findAllFolders.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(findOneFolder.pending, (state, action) => {
                state.loading = true
            })
            .addCase(findOneFolder.fulfilled, (state, action) => {
                state.loading = false
                state.oneFolder = action.payload
            })
            .addCase(findOneFolder.rejected, (state, action) => {
                state.error = action.payload
            })
            // .addCase(downloadFileFolder.pending, (state, action) => {
            //     state.loading = true
            // })
            // .addCase(downloadFileFolder.fulfilled, (state, action) => {
            //     state.loading = false
            //     state.fileDownload = action.payload

            // })
            // .addCase(downloadFileFolder.rejected, (state, action) => {
            //     state.error = action.payload
            // })
            .addCase(countAllFoldersActifs.pending, (state, action) => {
                state.loading = true
            })
            .addCase(countAllFoldersActifs.fulfilled, (state, action) => {
                state.loading = false
                state.numberFoldersActifs = action.payload
            })
            .addCase(countAllFoldersActifs.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(findMessagesByFolder.pending, (state, action) => {
                state.loading = true
            })
            .addCase(findMessagesByFolder.fulfilled, (state, action) => {
                state.loading = false
                state.allMessagesByFolder = action.payload
            })
            .addCase(findMessagesByFolder.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(findAllFoldersArchives.pending, (state, action) => {
                state.loading = true
            })
            .addCase(findAllFoldersArchives.fulfilled, (state, action) => {
                state.loading = false
                state.allFoldersArchives = action.payload
            })
            .addCase(findAllFoldersArchives.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(addFolder.pending, (state, action) => {
                state.loading = true
            })
            .addCase(addFolder.fulfilled, (state, action) => {
                state.loading = false
                //  console.log("fulfilled folder okay: " + JSON.stringify(action))
                state.allFolders.push(action.payload)
            })
            .addCase(addFolder.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(sendMessageFolder.pending, (state, action) => {
                state.loading = true
            })
            .addCase(sendMessageFolder.fulfilled, (state, action) => {
                state.loading = false

                state.allMessagesByFolder.push(action.payload)
            })
            .addCase(sendMessageFolder.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(updateFolder.pending, (state, action) => {
                state.loading = true
            })
            .addCase(updateFolder.fulfilled, (state, action) => {
                const updatefolder = action.payload
                const index = state.allFolders.findIndex(a => a.id === updatefolder.id)
                if (index !== -1) {
                    state.allFolders[index] = updatefolder
                }
            })
            .addCase(updateFolder.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(updateMessageFolder.pending, (state, action) => {
                state.loading = true
            })
            .addCase(updateMessageFolder.fulfilled, (state, action) => {
                const updateMessage = action.payload
                const index = state.allMessagesByFolder.findIndex(a => a.id === updateMessage.id)
                if (index !== -1) {
                    state.allMessagesByFolder[index] = updateMessage
                }
            })
            .addCase(updateMessageFolder.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(userByFolder.pending, (state, action) => {
                state.loading = true
            })
            .addCase(userByFolder.fulfilled, (state, action) => {
                state.loading = false
                state.userByFolders = action.payload
            })
            .addCase(userByFolder.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(archiveFolder.pending, (state, action) => {
                state.loading = true
            })
            .addCase(archiveFolder.fulfilled, (state, action) => {
                //  console.log(action)
                // const updatefolder = action.payload
                // const index = state.allFoldersArchives.findIndex(a => a.id === updatefolder.id)
                // if (index !== -1) {
                //     state.allFoldersArchives[index] = updatefolder
                // }
            })
            .addCase(archiveFolder.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(activeFolder.pending, (state, action) => {
                state.loading = true
            })
            .addCase(activeFolder.fulfilled, (state, action) => {

                // const updatefolder = action.payload
                // const index = state.allFolders.findIndex(a => a.id === updatefolder.id)
                // if (index !== -1) {
                //     state.allFolders[index] = updatefolder
                // }
            })
            .addCase(activeFolder.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(searchFoldersPage.pending, (state, action) => {
                state.loading = true
            })
            .addCase(searchFoldersPage.fulfilled, (state, action) => {
                state.loading = false
                state.allFoldersSearch = action.payload
            })
            .addCase(searchFoldersPage.rejected, (state, action) => {
                state.error = action.payload
            })
    }
})

export default folderSlice.reducer
