import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Row } from 'reactstrap'
import Footer from '../../components/Footer'
import Sidebar from '../../components/Sidebar'
import Topbar from '../../components/Topbar'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import jwt_decode from "jwt-decode"
import { useState } from 'react'
import SidebarUser from '../../components/user_normal/SidebarUser'
import { MAIL_ADMIN } from '../../config'
import { contactSupport } from './userSlice'

const Supports = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [authToken, setAuthToken] = useState(null)
    const [titre, setTitre] = useState("")
    const [objet, setObjet] = useState("")
    const [body, setBody] = useState("")

    const token = localStorage.getItem('token')

    const decodedToken = token ? jwt_decode(token) : null

    const listTitles = [
        { id: 'TECH', text: 'Problème technique' },
        { id: 'DIF', text: 'Demande d\'informations' },
        { id: 'AUT', text: 'Autre' },
    ]

    const hanldeSend = (event) => { 
        event.preventDefault()
        
        let mail = {
            title: titre,
            object: objet,
            body,
            to: MAIL_ADMIN,
            'Content-Type': 'multipart/form-data'
        }
         dispatch(contactSupport(mail))
 
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {
            setAuthToken(localStorage.getItem('token'))
            

        } else {
            navigate("/")
        }

    }, [authToken,navigate])
  
    if (authToken) {
        return (
            <div className='dashboard_wrapper'>
                {
                    decodedToken?.roles.length > 1 ? <Sidebar /> : <SidebarUser />
                }
                <div className='main_content'>
                    <Topbar />
                    <div className='content'>
                        <div className="content_header text-light">
                            <h1 className="fs-2 kanit-font">Support</h1>
                            <div className="flex opacity-5">
                                <i className="bi-house-fill"></i>
                                <span>- Forms - Support</span>
                            </div>
                        </div>
                        <Container className="mt--6" fluid>
                            <Row>
                                <div className="col">
                                    <div className="card-wrapper">
                                        <Card>
                                            <CardHeader>
                                                <h5 className="mb-0">Contacter le support</h5>
                                            </CardHeader>
                                            <CardBody>
                                                <Form onSubmit={hanldeSend} >
                                                    <Row>
                                                        <Col className="mb-3" md="6">
                                                            <label htmlFor="type-dossier" className="large fw-bolder mb-2"
                                                            >Titre</label >
                                                            <select name="type" id="type"
                                                                value={titre}
                                                                onChange={(e) => setTitre(e.target.value)}
                                                                className="form-control text-secondary"
                                                                style={{ height: "50px" }}
                                                            >

                                                                <option value="">-- Sélectionner le titre du message --</option>
                                                                {listTitles.map((title) => (
                                                                    <option key={title.id} value={title.id}>{title.text}</option>
                                                                ))}
                                                            </select>

                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="nom"
                                                            >
                                                                Objet
                                                            </label>
                                                            <Input
                                                                name="object"
                                                                id="object"
                                                                placeholder="Object"
                                                                type="text"
                                                                value={objet}
                                                                onChange={(e) => setObjet(e.target.value)}

                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="6">
                                                            <label
                                                                className="form-control-labels"
                                                                htmlFor="adresse"
                                                            >
                                                                Message
                                                            </label>
                                                            <textarea className="form-control mt-2" id="exampleFormControlTextarea1" rows="6"
                                                                 value={body}
                                                                placeholder="Message"
                                                                name="message"
                                                            onChange={(e) => setBody(e.target.value)}
                                                            ></textarea>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                    <Button
                                                        className='btn_custom col-2 d-flex justify-content-center'
                                                        style={{ backgroundColor: "#5e72e4" }}
                                                    >
                                                        Valider
                                                    </Button>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </Row>
                        </Container>

                    </div>

                    <Footer />
                </div>

            </div>
        )
    } else {
        return null
    }
}

export default Supports