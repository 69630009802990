import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import jwtDecode from "jwt-decode"
import moment from "moment"
import { AGENCES_API, API_URL, DOSSIERLOGS_API, MEDIA_API, MESSAGERIE_API, USERS_API } from "../../config"


const api = axios.create({
    baseURL: API_URL,
})
// to check if token is expired before doing a request
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token')

        if (token) {
            const decodedToken = jwtDecode(token)
            const now = Date.now() / 1000
            // console.log("hour now: " + now)
            // console.log("token hour: ", decodedToken)

            if (now > decodedToken.exp) {
                // console.log("token expire")
                localStorage.removeItem('token')
                window.location.reload()
            } else {
                config.headers.Authorization = `Bearer ${token}`
            }
        }

        return config;
    },
    (error) => Promise.reject(error)
)



export const findAllFoldersLogs = createAsyncThunk(
    "get/findAllFoldersLogs",
    async ( user,thunkApi) => { 
        
       // let token = localStorage.getItem('token')
        
         try {
             const response = await api.get(MESSAGERIE_API, {
                 params: {
                     type: "0"
                 }
             })
          
             if (user.roles.length === 1) {
                 const foldersFiltered = response.data["hydra:member"]?.filter((item) => item.dossier.user.email === user.username)
                 return foldersFiltered
             }
             if (user.roles.length === 2) {

                 return response.data["hydra:member"]
             }
           
          
         } catch (error) {
           //  console.log(error)
             return thunkApi.rejectWithValue(error.message)
         }
    }  
)
export const findLawerLogs = createAsyncThunk(
    "get/findLawerLogs",
    async (thunkApi) => { 
        
       // let token = localStorage.getItem('token')
         try {
             const response = await api.get(MESSAGERIE_API, {
                 params: {
                     type: "0"
                 }
             })
             let lawerLogs = response.data["hydra:member"]
            // console.log("response:", response)
          return   lawerLogs.filter(
                 (log) =>
                     moment(log.dateAudience).format("YYYY-MM-DD") >=
                     moment().format("YYYY-MM-DD")
             )
         } catch (error) {
            // console.log(error)
             return thunkApi.rejectWithValue(error.message)
         }
    }
)

export const sendNewMessageLogs = createAsyncThunk(
    "post/sendNewMessageLogs",
    async ({ newMessage, selectedFiles }, thunkApi) => {

       
        if (selectedFiles.length === 0) {
            try {
                const response = await api.post(MESSAGERIE_API, newMessage)

                return response.data
            } catch (error) {
               //  console.log("send message without file", error)
                return thunkApi.rejectWithValue(error.message)
            }
        }

        if (selectedFiles.length > 0) {
            try {
                
                const response = await api.post(MESSAGERIE_API, newMessage)
                let dossierLog = response.data
                 // console.log("dossier log", dossierLog)
                const user = await api.get(USERS_API + "/" + dossierLog.dossier.user.id)
              //  console.log("user find", user)
                const allAgences = await api.get(AGENCES_API + '/?timestamp=' + new Date().getTime())
                  //console.log("all agences", allAgences)
                const agenceUser = allAgences?.data['hydra:member'].find((it) => {
                    if (String(it['@id']) === String(user?.data.agence['@id'])) {
                        return it
                    }
                    return null
                    // return String(it['@id']) === String(user?.data.agence['@id'])
                })
                // console.log("agence user: ",agenceUser)

                await Promise.all(
                    selectedFiles.map(async (f) => {

                        const formData = new FormData()
                        formData.append("file_path", f)
                        formData.append("rep", agenceUser.codeAgence)
                        formData.append("target", agenceUser.codeAgence)
                        formData.append("createdAt", new Date().getTime())
                        formData.append("dossierLogsId", dossierLog.id)
                       // console.log('send file', Object.fromEntries(formData.entries()))
                        const fileCreated = await api.post(MEDIA_API, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'

                            },
                        })
                        //  console.log("dossier file created", dossierFile)
                        return fileCreated


                    })

                )
                return response.data

            } catch (error) {
               // console.log("send message with file", error)
                return thunkApi.rejectWithValue(error.message)
            }
        }

    }
)
export const findFoldersLogsLimit = createAsyncThunk(
    "gets/findFoldersLogsLimit",
    async (limit,thunkApi) => {
        //const token = localStorage.getItem("token")
        try {
            const response = await api.get(DOSSIERLOGS_API + "/user?", {
                params: {
                    limit:limit
                }
            })
        // console.log("count logs ", response)
            return response.data.data
        } catch (error) {
         //  console.log(error)
            return thunkApi.rejectWithValue(error.message)
        }
    }
)
export const countAllAudiences = createAsyncThunk(
    "gets/countAllAudiences",
    async (thunkApi) => {
       // const token = localStorage.getItem("token")
        try {
            const response = await api.get(MESSAGERIE_API + "/countaudiences/3")
            // console.log("count ", response)
            return response.data
        } catch (error) {
           // console.log(error)
            return thunkApi.rejectWithValue(error.message)
        }
    }
)

const initialState = {
    allFoldersLogs: [],
    lawerLogs: [],
    loading: false,
    numberAudiences: null,
    logsLimit:[],
    error: null,
}

const messagerieSlice = createSlice({
   name: "messagerie",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(findAllFoldersLogs.pending, (state, action) => {
                state.loading = true
            })
            .addCase(findAllFoldersLogs.fulfilled, (state, action) => {
                state.allFoldersLogs = action.payload
                state.loading = false
            })
            .addCase(findAllFoldersLogs.rejected, (state, action) => {
               state.error = action.payload
            })
            .addCase(findLawerLogs.pending, (state, action) => {
                state.loading = true
            })
            .addCase(findLawerLogs.fulfilled, (state, action) => {
                state.lawerLogs = action.payload
                state.loading = false
            })
            .addCase(findLawerLogs.rejected, (state, action) => {
               state.error = action.payload
            })
            .addCase(findFoldersLogsLimit.pending, (state, action) => {
                state.loading = true
            })
            .addCase(findFoldersLogsLimit.fulfilled, (state, action) => {
                state.logsLimit = action.payload
                state.loading = false
            })
            .addCase(findFoldersLogsLimit.rejected, (state, action) => {
               state.error = action.payload
            })
            .addCase(sendNewMessageLogs.pending, (state, action) => {
                state.loading = true
            })
            .addCase(sendNewMessageLogs.fulfilled, (state, action) => {
                state.lawerLogs.push(action.payload)
                state.loading = false
            })
            .addCase(sendNewMessageLogs.rejected, (state, action) => {
               state.error = action.payload
            })
            .addCase(countAllAudiences.pending, (state, action) => {
                state.loading = true
            })
            .addCase(countAllAudiences.fulfilled, (state, action) => {
                state.loading = false
                state.numberAudiences = action.payload
            })
            .addCase(countAllAudiences.rejected, (state, action) => {
                state.error = action.payload
            })
    }
})

export default messagerieSlice.reducer