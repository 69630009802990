
import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import Sidebar from '../../components/Sidebar'
import Topbar from '../../components/Topbar'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { findAllUsers } from './userSlice'
import Loader from '../../components/Loader'
import ReactPaginate from 'react-paginate'

const ListUsers = () => {

    const { allUsers } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [authToken, setAuthToken] = useState(null)
    const [currentPage, setCurrentPage] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [searchText, setSearchText] = useState("") 

    useEffect(() => {
        if (localStorage.getItem('token')) {
            setAuthToken(localStorage.getItem('token'))
            dispatch(findAllUsers())

        } else {
            navigate("/")
        }
     
    }, [dispatch,navigate])
    const offset = currentPage * perPage
    const filteredUsers = allUsers.filter((user) => {
        const lowerCaseSearchText = searchText.toLowerCase()
        return user.nom.toLowerCase().includes(lowerCaseSearchText)
    })
    const allCurrentUsers = searchText ? filteredUsers : allUsers
   
    const pageCount = allCurrentUsers ? Math.ceil(allCurrentUsers.length / perPage) : 0
    const currentUsers = allCurrentUsers ? allCurrentUsers.slice(offset, offset + perPage) : []

    if (authToken) {
        return (
            <div className='dashboard_wrapper'>
                <Sidebar />
                <div className='main_content'>
                    <Topbar />
                    <div className='content'>
                        <div className="content_header text-light">
                            <h1 className="fs-2 kanit-font">Utilisateurs</h1>
                            <div className="flex opacity-5">
                                <i className="bi-house-fill"></i>
                                <span>- Tables - Utilisateurs</span>
                            </div>
                        </div>
                        {/* <!-- right here is a wide card where the majority of thing will be placed --> */}
                        <div className="card bg-white">
                            <div className="card-header bg-light">
                                <div className="d-flex justify-content-between">
                                    <h2 className="fs-5 m-0 p-2">Liste des utilisateurs</h2>
                                    <Link to="/admin/utilisateurs/new" className="btn_custom">Créer un utilisateur</Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-flex justify-content-end mb-3 mt-2">
                                    <div className="d-flex align-items-center col-5">
                                        <div className="input-group me-3">
                                            <input type="text" className="form-control" placeholder="Recherche"
                                                aria-label="Recherche" aria-describedby="basic-addon2"
                                                value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-sortable">
                                        <thead>
                                            <tr className='small'>
                                                <th data-type="alpha" className='text-secondary'>Nom</th>
                                                <th data-type="alpha" className='text-secondary'>Prénom</th>
                                                <th data-type="alpha" className='text-secondary'>Email</th>
                                                <th data-type="alpha" className='text-secondary'>Agence</th>
                                                <th className='text-secondary'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(currentUsers).length ?
                                                    currentUsers.map((user) => {
                                                        return (
                                                            <tr key={user.id}>
                                                                <td className='small'>{user.nom}</td>
                                                                <td className='small'>{user.prenom}</td>
                                                                <td className='small'>{user.email}</td>
                                                                <td className='small'>{user.agence?.nom}</td>
                                                                <td className='d-flex gap-1'>
                                                                    <Link to={`/admin/utilisateurs/${user.id}`} state={user}
                                                                        className="btn btn-primary" style={{ backgroundColor: "#5e72e4" }}
                                                                        data-toggle="tooltip"
                                                                        data-placement="top" title="Editer l'utilisateur">
                                                                        <i className="bi bi-gear-fill text-white"></i>
                                                                    </Link>
                                                                    <Link to={`/admin/transfertdossiers/${user.id}`} state={user}
                                                                        className="btn btn-secondary  bg-secondary" data-toggle="tooltip"
                                                                        data-placement="top"
                                                                        title="Transfert des dossiers">
                                                                        <i className="bi bi-database-fill "></i>
                                                                    </Link>
                                                                    <Link to={`/admin/userByDossier/${user.id}`}
                                                                        className="btn btn-secondary"
                                                                        style={{ backgroundColor: "#135b6c" }} data-toggle="tooltip"
                                                                        data-placement="top"
                                                                        title="Liste des dossiers">
                                                                        <i className="bi bi-list text-white"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : <Loader colSpan={5} />
                                            }

                                        </tbody>
                                    </table>
                               </div>
                                <ReactPaginate
                                    previousLabel={'previous'}
                                    nextLabel={'next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={4}
                                    onPageChange={(data) => {
                                        setCurrentPage(data.selected);
                                    }}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>

            </div>
        )
    } else {
        return null
    }
}

export default ListUsers



