import React, { useEffect } from 'react'
import { countAllFoldersActifs } from '../features/dossiers/folderSlice'
import { useDispatch, useSelector } from 'react-redux'
import { countAllClientsActifs } from '../features/users/userSlice'
import { countAllAudiences, findAllFoldersLogs, findFoldersLogsLimit } from '../features/messageries/messagerieSlice'
import { countAllAgences } from '../features/agences/agenceSlice'

const Statistics = () => {
    
    const dispatch = useDispatch()
    const { numberFoldersActifs } = useSelector((state) => state.folder)
    const { numberClientsActifs } = useSelector((state) => state.user)
    const { numberAudiences} = useSelector((state) => state.messagerie)
    const { numberAgence } = useSelector((state) => state.agence)
    
    useEffect(() => { 
       dispatch(countAllFoldersActifs())
        dispatch(countAllClientsActifs())
        dispatch(countAllAudiences())
        dispatch(countAllAgences())
        dispatch(findAllFoldersLogs())
         dispatch(findFoldersLogsLimit(30))
    },[dispatch])
    
  return (
      <div className="row mb-5">
          <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
              <div className="stat_card">
                  <div
                      className="d-flex align-items-start justify-content-between mb-5"
                  >
                      <div className="stat_numbers">
                          <div className="opacity-5 d-block small">
                              DOSSIERS ACTIFS
                          </div>
                          <div className="kanit-font fs-2">{ numberFoldersActifs }</div>
                      </div>
                       <div className="stat_icon">
                          <i className="bi-folder"></i>
                      </div>
                  </div>
                  <div className="progression">
                      <div
                          className="progression_status"
                          style={{ width: '60%' }} 
                      ></div>
                  </div>
              </div>
          </div>

          <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
              <div className="stat_card orange">
                  <div
                      className="d-flex align-items-start justify-content-between mb-5"
                  >
                      <div className="stat_numbers">
                          <div className="opacity-5 d-block small">
                              CLIENTS ACTIFS
                          </div>
                          <div className="kanit-font fs-2">{ numberClientsActifs }</div>
                      </div>
                      <div className="stat_icon">
                          <i className="bi-people"></i>
                      </div>
                  </div>
                  <div className="progression">
                      <div
                          className="progression_status"
                          style={{ width: '60%' }}
                      ></div>
                  </div>
              </div>
          </div>

          <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
              <div className="stat_card cyan">
                  <div
                      className="d-flex align-items-start justify-content-between mb-5"
                  >
                      <div className="stat_numbers">
                          <div className="opacity-5 d-block small">
                              NOMBRE D"AUDIENCES
                          </div>
                          <div className="kanit-font fs-2">{ numberAudiences }</div>
                      </div>
                      <div className="stat_icon">
                          <i className="bi-calendar2-week"></i>
                      </div>
                  </div>
                  <div className="progression">
                      <div
                          className="progression_status"
                          style={{ width: '60%' }}
                      ></div>
                  </div>
              </div>
          </div>

          <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
              <div className="stat_card violet">
                  <div
                      className="d-flex align-items-start justify-content-between mb-5"
                  >
                      <div className="stat_numbers">
                          <div className="opacity-5 d-block small">
                              NOMBRE D'AGENCES
                          </div>
                          <div className="kanit-font fs-2">{ numberAgence}</div>
                      </div>
                      <div className="stat_icon">
                          <i className="bi-buildings"></i>
                      </div>
                  </div>
                  <div className="progression">
                      <div
                          className="progression_status"
                          style={{ width: '60%' }}
                      ></div>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default Statistics