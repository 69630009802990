import React from 'react'

const Footer = () => {
  return (
      <>
          <footer className="copyrights small">
              Copyright &copy; 2023 Cabinet Elbaz et Associés. Tous droits réservés.
          </footer>
      </>
  )
}

export default Footer