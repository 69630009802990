import React, { useState } from 'react'
import logo from '../../images/logo-script.png'
import logoMobile from '../../images/logo-script-mobile.png'
import { useNavigate } from 'react-router'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { API_URL } from '../../config'
import { Link } from 'react-router-dom'
const LoginForm = () => {
  

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault()
  
    if (email === "" || password === "") {
      toast.error('Veuillez remplir tous les champs !', {
        position: toast.POSITION.TOP_CENTER
      })
    } else {
 let credentials = {
      password: password,
      username: email
     
      }
      
    try {
      const response = await axios.post(API_URL +"login_check", credentials)
    
      localStorage.setItem('token', response.data.token)     
       navigate("/admin/dashboard")
    } catch (error) {
      toast.error('Email ou mot de passe incorrect !', {
        position: toast.POSITION.TOP_CENTER
      })
    
      
      }
      setEmail("")
      setPassword("")
    }    
    
  }
  
  return (
    <div className="login_wrapper">
      <div className="form_content">
        <div className="logo_lg my-5 text-center">
          <img
            src={logo}
            srcSet={`${logoMobile} 480w, ${logo} 768w`}
            alt="Logo Elbaz Gabay Cohen"
            className="h-cover"
          />
        </div>

        <form className="p-4" onSubmit={handleSubmit}>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1"
            ><i className="bi-envelope-fill"></i></span
            >
            <input
              type="email"
              value={email}
              className="form-control"
              placeholder="Email"
              aria-label="Email"
              aria-describedby="basic-addon1"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1"
            ><i className="bi-lock-fill"></i></span
            >
            <input
              type="password"
             value={password}
              className="form-control"
              placeholder="Password"
              aria-label="Password"
              aria-describedby="basic-addon1"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="text-center">
            <button type='submit' className="btn_custom">Login</button>
          </div>
          <ToastContainer />
        </form>
        <div className='ms-4'>
          <Link to="/admin/forgotpassword" className="small">Mot de passe oublié ?</Link>
        </div>
      </div>

      <div className="copyrights small">
        Copyright &copy; 2023 Cabinet Elbaz et Associés. Tous droits réservés.
      </div>
    </div>
  )
}

export default LoginForm