import React, { useEffect, useState } from 'react'
import Topbar from '../../components/Topbar'
import Sidebar from '../../components/Sidebar'
import { Button, Col } from 'reactstrap'
import Footer from '../../components/Footer'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { updateMessage } from './messageSlice'

const UpdateMessage = () => {

    const dispatch = useDispatch()
    const [authToken, setAuthToken] = useState(null)
    const navigate = useNavigate()
    const location = useLocation()
    const singleMessage = location.state
      // console.log("Single message", singleMessage)
    const [message, setMessage] = useState({
       // "@context": "/api/contexts/MessageType",
       // "@id": singleMessage["@id"],
       // "@type": singleMessage["@type"],
        id: singleMessage.id,
        titre: singleMessage.titre,
        message: singleMessage.message,
       // messageState: "valid",
        createdAt: singleMessage.createdAt
    })

    useEffect(() => {
        if (localStorage.getItem('token')) {
            setAuthToken(localStorage.getItem('token'))
        } else {
            navigate("/")
        }

    }, [dispatch, navigate])

    const handleUpdate = (event) => {
        event.preventDefault()

        if (message.message === "" || message.titre === "") {
            toast.error('Veuillez remplir tous les champs !', {
                position: toast.POSITION.TOP_CENTER
            })
        } else {
            if (message.titre.length < 4) {
                toast.error('Le titre du message doit avoir au moins 4 caractères" !', {
                    position: toast.POSITION.TOP_CENTER
                })
            } else {
            
                dispatch(updateMessage(message))
                 navigate("/admin/dashboard")
            }
          

        }

    }

  if (authToken) {
      return (
          <div className='dashboard_wrapper'>
              <Sidebar />
              <div className='main_content'>
                  <Topbar />
                  <div className='content'>
                      <div className="content_header text-light">
                          <h1 className="fs-2 kanit-font">Message</h1>
                          <div className="flex opacity-5">
                              <i className="bi-house-fill"></i>
                              <span>- Forms - Message</span>
                          </div>
                      </div>
                      <div className="card bg-white">
                          <div className="card-header bg-light">
                              <div className="d-flex justify-content-between">
                                  <h2 className="fs-5 m-0 p-2">Modifier un message</h2>
                              </div>
                          </div>
                          <div className="card-body">
                              <div className="px-0 px-md-4 px-lg-5 py-4">
                                  <form onSubmit={handleUpdate} >
                                      <div className="row">
                                          <div className="col-12 col-lg-6 mb-3">
                                              <label htmlFor="title" className="large fw-bolder mb-2"
                                              >Titre</label >
                                              <input
                                                  type="text"
                                                  value={message.titre}
                                                  placeholder="Titre"
                                                  name="title"
                                                  className="form-control text-secondary" style={{ height: "50px" }}
                                                  onChange={(e) => setMessage({ ...message, titre: e.target.value })}
                                              />
                                          </div>
                                          <Col md="6">
                                              <label
                                                  className="form-control-labels"
                                                  htmlFor="adresse"
                                              >
                                                  Message
                                              </label>
                                              <textarea className="form-control mt-2" id="exampleFormControlTextarea1" rows="5"
                                                  value={message.message}
                                                  placeholder="Message"
                                                  name="message"
                                                  onChange={(e) => setMessage({ ...message, message: e.target.value })}
                                              ></textarea>

                                          </Col>

                                      </div>
                                      <div className="text-center">
                                          <Button type='submit' className="btn_custom col-2 d-flex justify-content-center " style={{ backgroundColor: "#5e72e4" }}>Valider</Button>
                                      </div>
                                      <ToastContainer />
                                  </form>
                              </div>
                          </div>
                      </div>

                  </div>

                  <Footer />
              </div>

          </div>
      )
  } else {
    return null
  }
}

export default UpdateMessage