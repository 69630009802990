import React, { useState } from 'react'
import logo from '../../images/logo-script.png'
import logoMobile from '../../images/logo-script-mobile.png'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import { API_URL, RESET_PASSWORD } from '../../config'
import axios from 'axios'
import { Link } from 'react-router-dom'

const ResetPassword = () => {

    const [email, setEmail] = useState('')
    const navigate = useNavigate()


    const handleSubmit = async (event) => {
        event.preventDefault()

        if (email === "") {
            toast.error('Veuillez renseigner un mail !', {
                position: toast.POSITION.TOP_CENTER
            })
        } else {

            try {
                const response = await axios.post(RESET_PASSWORD, { email: email })
                  if (response.data) {
                      toast.info(" Votre nouveau mot de passe a été envoyé à l'adresse mail renseigné !!", {
                          position: toast.POSITION.TOP_CENTER
                      })
                }
                  else {
                      toast.error(" Le mail saisi n'existe pas !!", {
                          position: toast.POSITION.TOP_CENTER
                      })  
                }
            } catch (error) {
              
                toast.error(" Erreur !!", {
                    position: toast.POSITION.TOP_CENTER
                })  

            }
            setEmail("")
        
        }

    }
    return (
        <div className="login_wrapper">
            <div className="form_content">
                <div className="logo_lg my-5 text-center">
                    <img
                        src={logo}
                        srcSet={`${logoMobile} 480w, ${logo} 768w`}
                        alt="Logo Elbaz Gabay Cohen"
                        className="h-cover"
                    />
                </div>

                <form className="p-4" onSubmit={handleSubmit}>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"
                        ><i className="bi-envelope-fill"></i></span
                        >
                        <input
                            type="email"
                            value={email}
                            className="form-control"
                            placeholder="Email"
                            aria-label="Email"
                            aria-describedby="basic-addon1"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                  
                    <div className="text-center">
                        <button type='submit' className="btn_custom">Envoyé</button>
                    </div>

                    <ToastContainer />
                </form>
                <div className='ms-4'>
                    <Link to="/" className="small">Connectez-vous</Link>
                </div>
            </div>

            <div className="copyrights small">
                Copyright &copy; 2023 Cabinet Elbaz et Associés. Tous droits réservés.
            </div>
        </div>
    )
}

export default ResetPassword