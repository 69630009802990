import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import { Link, useNavigate } from 'react-router-dom'
import Sidebar from '../../components/Sidebar'
import Topbar from '../../components/Topbar'
import { useDispatch, useSelector } from 'react-redux'
import { archiveFolder, findAllFoldersActifs } from './folderSlice'
import Loader from '../../components/Loader'
import ReactPaginate from 'react-paginate'
import moment from 'moment/moment'
import { findAllUsers } from '../users/userSlice'
import jwt_decode from "jwt-decode"
import SidebarUser from '../../components/user_normal/SidebarUser'

const ListFolders = () => {

    const [authToken, setAuthToken] = useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(0)
    const [perPage, setPerPage] = useState(10)  
    const [searchText, setSearchText] = useState("")
    const token = localStorage.getItem('token')
    const decodedToken = token ? jwt_decode(token) : null

    const { allFoldersActifs } = useSelector((state) => state.folder)

    const handleArchive = (id, status) => { 
        let body = {
            status:false
        }
        dispatch(archiveFolder({ id, body }))
        navigate("/admin/dashboard")
       
    }
    

    useEffect(() => {
        if (localStorage.getItem('token')) {
            setAuthToken(localStorage.getItem('token'))
            dispatch(findAllFoldersActifs())
           // dispatch(findAllFolders())
            dispatch(findAllUsers())

        } else {
            navigate("/")
        }

    }, [dispatch, navigate])
   
    const sortedFolders = allFoldersActifs.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    const offset = currentPage * perPage
    const filteredFolders = sortedFolders?.filter((folder) => {
        const lowerCaseSearchText = searchText?.toLowerCase()
        return folder.identiteDebiteur?.toLowerCase().includes(lowerCaseSearchText)
    })
    const allCurrentFolders = searchText ? filteredFolders : sortedFolders
  
    const pageCount = allCurrentFolders ? Math.ceil(allCurrentFolders.length / perPage) : 0
    const currentFolders = allCurrentFolders ? allCurrentFolders.slice(offset, offset + perPage) : [] 
    // console.log(currentFolders)
    if (authToken) {
        return (
            <div className='dashboard_wrapper'>
                {
                    decodedToken?.roles.length > 1 ? <Sidebar /> : <SidebarUser />
                }
                <div className='main_content'>
                    <Topbar />
                    <div className='content'>
                        <div className="content_header text-light">
                            <h1 className="fs-2 kanit-font">Dossiers</h1>
                            <div className="flex opacity-5">
                                <i className="bi-house-fill"></i>
                                <span>- Consultations - Dossiers</span>
                            </div>
                        </div>
                        {/* <!-- right here is a wide card where the majority of thing will be placed --> */}
                        <div className="card bg-white">
                            <div className="card-header bg-light">
                                <div className="d-flex justify-content-between">
                                    <h2 className="fs-5 m-0 p-2">Liste des dossiers</h2>
                                    <Link to="/admin/dossiers/new"  className="btn_custom">Créer un nouveau dossier</Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-flex justify-content-end mb-3 mt-2">
                                    <div className="d-flex align-items-center col-5">
                                        <div className="input-group me-3">
                                            <input type="text" className="form-control" placeholder="Recherche"
                                                aria-label="Recherche" aria-describedby="basic-addon2"
                                                value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-sortable">
                                        <thead>
                                            <tr className='small'>
                                                <th data-type="alpha" className='text-secondary'>RÉFÉRENCE</th>
                                                <th data-type="alpha" className='text-secondary'>ADRESSE</th>
                                                <th data-type="alpha" className='text-secondary'>VILLE</th>
                                                <th data-type="alpha" className='text-secondary'>	DÉBITEUR</th>
                                                <th data-type="alpha" className='text-secondary'>	TYPE DE DOSSIER</th>
                                                <th data-type="alpha" className='text-secondary'>	DATE CRÉATION</th>
                                                <th className='text-secondary '>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(currentFolders).length ?
                                                    currentFolders.map((folder) => {
                                                        return (
                                                            <tr key={folder.id}>
                                                                <td className='small'>{folder.refDossier}</td>
                                                                <td className='small'>{folder.adresse}</td>
                                                                <td className='small'>{folder.ville}</td>
                                                                <td className='small'>{folder.identiteDebiteur}</td>
                                                                <td className='small'>{folder.type}</td>
                                                                <td className='small'>{moment(folder.createdAt).format("DD/MM/YYYY")}</td>
                                                                <td className='d-flex gap-1'>
                                                                    <Link to={`/admin/dossiers/${folder.id}`} state={folder} className="btn btn-primary" style={{ backgroundColor: "#5e72e4" }} data-toggle="tooltip"
                                                                        data-placement="top" title="Editer le dossier">
                                                                        <i className="bi bi-gear-fill text-white"></i>
                                                                    </Link>
                                                                    <button className="btn btn-secondary bg-secondary" data-toggle="tooltip" data-placement="top"
                                                                        title="Archiver"
                                                                        onClick={() => handleArchive(folder.id, folder.status)}
                                                                    >
                                                                        <i className="bi bi-database-fill "></i>
                                                                    </button>
                                                                    <Link to={`/admin/dossiers/view/${folder.id}`} state={folder} className="btn btn-secondary" style={{ backgroundColor: "#135b6c" }} data-toggle="tooltip" data-placement="top"
                                                                        title="Consulter le dossier">
                                                                        <i className="bi bi-list text-white"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : <Loader colSpan={7} />
                                            }




                                        </tbody>
                                    </table>
                                </div>
                                <ReactPaginate
                                    previousLabel={'previous'}
                                    nextLabel={'next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={3}
                                    onPageChange={(data) => {
                                    setCurrentPage(data.selected);
                                    }}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>

            </div>
        )
    } else {
        return null
    }
}

export default ListFolders