import React, { useEffect } from 'react'
import Footer from '../../components/Footer'
import { Button, Form, Input } from 'reactstrap'
import Sidebar from '../../components/Sidebar'
import Topbar from '../../components/Topbar'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { findAllUsers } from '../users/userSlice'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'
import { addFolder } from './folderSlice'
import SidebarUser from '../../components/user_normal/SidebarUser'
import jwt_decode from "jwt-decode"

const AddFolder = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [authToken, setAuthToken] = useState(null)
    const { allUsers } = useSelector((state) => state.user)
    const [selectedFiles, setSelectedFiles] = useState([])
    const [user, setUser] = useState("")
    const [adresse, setAdresse] = useState("")
    const [adresseCreancier, setAdresseCreancier] = useState("")
    const [adresseDebiteur, setAdresseDebiteur] = useState("")
    const [codePostal, setCodePostal] = useState("")
    const [hasFile, setHasFile] = useState(false)
    const [identiteCreancier, setIdentiteCreancier] = useState("")
    const [identiteDebiteur, setIdentiteDebiteur] = useState("")
    const [refDossier, setRefDossier] = useState("")
    const [montantDette, setMontantDette] = useState(0)
    const [observation, setObservation] = useState("")
    const [origineDette, setOrigineDette] = useState("")
    const [ville, setVille] = useState("")
    const [type, setType] = useState("")
    const [status, setStatus] = useState(true)

    const [decodedToken, setDecodedToken] = useState()

    const typeDossier = [
        {
        id: "RFE",
        text: "Expertise"
        },
        {
        id: "DHY",
            text: "Hypothèque"
        },
        {
        id: "DMC",
            text: "Mise en conformité"
        },
        {
        id: "LIP",
            text: "Loyer impayé/expulsion"
        },
        {
        id: "ANG",
            text: "Annulation d'assemblée générale"
        },
        {
        id: "DSI",
            text: "Saisie immobilière"
        },
        {
        id: "RCR",
            text: "Recouvrement de Créance"
        },
        {
        id: "DRC",
            text: "Recouvrement de charges"
        },
    ]


    const handleFileSelect = (event) => {
       // setHasFile(true)
        setSelectedFiles([...selectedFiles, ...event.target.files])
    }

    const handleFileDrop = (event) => {
        event.preventDefault()
        event.stopPropagation()
       // setHasFile(true)
        setSelectedFiles([...selectedFiles, ...event.dataTransfer.files])
    }
    useEffect(() => {
        if (selectedFiles.length > 0) {
            setHasFile(true)
        } else {
            setHasFile(false)
        }
    }, [selectedFiles])
    useEffect(() => {
        if (localStorage.getItem('token')) {
            setAuthToken(localStorage.getItem('token'))
            setDecodedToken(jwt_decode(localStorage.getItem('token')))
            dispatch(findAllUsers())

        } else {
            navigate("/")
        }

    }, [dispatch, navigate])
    const handleFormSubmit =  (event) => {
        event.preventDefault()       
      
        if (adresse === "" || adresseCreancier === "" || adresseDebiteur === "" || codePostal === "" ||
            identiteCreancier === "" || identiteDebiteur === "" || observation === "" || origineDette === "" ||
            refDossier === "" || type === "" || ville === "" || user === "") {
            toast.error('Veuillez remplir tous les champs !', {
                position: toast.POSITION.TOP_CENTER
            })
        } else {
            let body = {
                adresse,
                adresseCreancier,
                adresseCreancierState: "valid",
                adresseDebiteur,
                adresseDebiteurState: "valid",
                adresseState: "valid",
                capital: 0,
                codePostal,
                codePostalState: "valid",
                createdAt: moment().format(),
                dateAg: moment().format(),
                dateCritique: moment().format(),
                dateRc: moment().format(),
                hasFile,
                identiteCreancier,
                identiteCreancierState: "valid",
                identiteDebiteurState: "valid",
                identiteDebiteur,
                lotConcerne: "",
                montantDette: parseFloat(montantDette),
                montantDetteState: "valid",
                observation,
                observationState: "valid",
                origineDette,
                origineDetteState: "valid",
                rcs: "",
                refDossier,
                refDossierState: "valid",
                siege: "",
                societe: "",
                status,
                type,
                typeState: "valid",
                updatedAt: moment().format(),
                user,
                userState: "valid",
                ville,
                villeState: "valid"


            }

        dispatch(addFolder({ body,selectedFiles}))
            navigate("/admin/dashboard")

        }
    }

   
    if (authToken) {
        return (
            <div className='dashboard_wrapper'>
                {
                    decodedToken?.roles.length > 1 ? <Sidebar /> : <SidebarUser />
                }
                <div className='main_content'>
                    <Topbar />
                    <div className='content'>
                        <div className="content_header text-light">
                            <h1 className="fs-2 kanit-font">Dossiers</h1>
                            <div className="flex opacity-5">
                                <i className="bi-house-fill"></i>
                                <span>- Forms - Dossiers</span>
                            </div>
                        </div>
                        <div className="card bg-white">
                            <div className="card-header bg-light">
                                <div className="d-flex justify-content-between">
                                    <h2 className="fs-5 m-0 p-2">Créer un dossier</h2>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="px-0 px-md-4 px-lg-5 py-4">
                                    <form onSubmit={handleFormSubmit} >
                                        <div className="row">
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="user" className="large fw-bolder mb-2"
                                                >Utilisateur</label >
                                                <select
                                                    name="user"
                                                    id="user"
                                                    value={user}
                                                    onChange={(e) => setUser(e.target.value)}
                                                    className="form-control text-secondary"
                                                    style={{ height: "50px" }}
                                                >
                                                    <option value="">-- Sélectionner un utilisateur --</option>
                                                    {allUsers.map((user) => (
                                                        <option key={user.id} value={user["@id"]}>{user.nom + " " + user.prenom }</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="type-dossier" className="large fw-bolder mb-2"
                                                >Type de dossier</label >
                                                <select name="type" id="type"
                                                    value={type}
                                                    onChange={(e) => setType(e.target.value)}
                                                    className="form-control text-secondary"
                                                    style={{ height: "50px" }}
                                                >
                                                  
                                                    <option value="">-- Sélectionner le type du dossier --</option>
                                                    {typeDossier.map((dossier) => (
                                                        <option key={dossier.id} value={dossier.id}>{dossier.text}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="reference" className="large fw-bolder mb-2"
                                                >Référence</label >
                                                <input
                                                    type="text"
                                                    value={refDossier}
                                                    placeholder="Référence"
                                                    name="reference"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setRefDossier(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="adress" className="large fw-bolder mb-2"
                                                >Adresse</label >
                                                <input
                                                    type="text"
                                                    value={adresse}
                                                    placeholder="Adresse"
                                                    name="adress"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setAdresse(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="code-postal" className="large fw-bolder mb-2"
                                                >Code postal</label >
                                                <input
                                                    type="text"
                                                    value={codePostal}
                                                    placeholder="Code postal"
                                                    name="code-postal"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setCodePostal(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="ville" className="large fw-bolder mb-2"
                                                >Ville</label >
                                                <input
                                                    type="text"
                                                    value={ville}
                                                    placeholder="Ville"
                                                    name="ville"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setVille(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="observation" className="large fw-bolder mb-2"
                                                >Observation</label >
                                                <input
                                                    type="text"
                                                    value={observation}
                                                    placeholder="Observation"
                                                    name="observation"
                                                    className="form-control text-secondary" style={{ height: "60px" }}
                                                    onChange={(e) => setObservation(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="identite-creancier" className="large fw-bolder mb-2"
                                                >Identité du créancier</label >
                                                <input
                                                    type="text"
                                                    value={identiteCreancier}
                                                    placeholder="Identité du créancier"
                                                    name="identite-creancier"
                                                    className="form-control text-secondary" style={{ height: "60px" }}
                                                    onChange={(e) => setIdentiteCreancier(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="adress-creancier" className="large fw-bolder mb-2"
                                                >Adresse du créancier</label >
                                                <input
                                                    type="text"
                                                    value={adresseCreancier}
                                                    placeholder="Adresse du créancier"
                                                    name="adress-creancier"
                                                    className="form-control text-secondary" style={{ height: "60px" }}
                                                    onChange={(e) => setAdresseCreancier(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="identite-debiteur" className="large fw-bolder mb-2"
                                                >Identité du débiteur</label >
                                                <input
                                                    type="text"
                                                    value={identiteDebiteur}
                                                    placeholder="Identité du débiteur"
                                                    name="identite-debiteur"
                                                    className="form-control text-secondary" style={{ height: "60px" }}
                                                    onChange={(e) => setIdentiteDebiteur(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="adress-debiteur" className="large fw-bolder mb-2"
                                                >Adresse du débiteur</label >
                                                <input
                                                    type="text"
                                                    value={adresseDebiteur}
                                                    placeholder="Adresse du débiteur"
                                                    name="adress-debiteur"
                                                    className="form-control text-secondary" style={{ height: "60px" }}
                                                    onChange={(e) => setAdresseDebiteur(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="societe" className="large fw-bolder mb-2"
                                                >Société</label >
                                                <input
                                                    type="text"
                                                    placeholder="Société"
                                                    name="societe"
                                                    disabled={true}
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="capital" className="large fw-bolder mb-2"
                                                >Capital</label >
                                                <input
                                                    type="text"
                                                    placeholder="Capital"
                                                    name="capital"
                                                    disabled={true}
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="rcs" className="large fw-bolder mb-2"
                                                >RCS</label >
                                                <input
                                                    type="text"
                                                    placeholder="RCS"
                                                    name="rcs"
                                                    disabled={true}
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="siege" className="large fw-bolder mb-2"
                                                >Siège</label >
                                                <input
                                                    type="text"
                                                    placeholder="Siège"
                                                    name="siege"
                                                    disabled={true}                                            
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="lot-concerne" className="large fw-bolder mb-2"
                                                >Lot concerné</label >
                                                <input
                                                    type="text"
                                                    disabled={true}
                                                    placeholder="Lot concerné"
                                                    name="lot-concerne"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="origine-dette" className="large fw-bolder mb-2"
                                                >Origine de la dette</label >
                                                <input
                                                    type="text"
                                                    value={origineDette}
                                                    placeholder="Origine de la dette"
                                                    name="origine-dette"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setOrigineDette(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="date-rc" className="large fw-bolder mb-2"
                                                >Date RC</label >
                                                <input
                                                    type="date"
                                                    disabled={true}
                                                    placeholder="Date RC"
                                                    name="date-rc"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="date-assemblee" className="large fw-bolder mb-2"
                                                >Date de l'assemblée générale</label >
                                                <input
                                                    type="date"
                                                    disabled={true}
                                                    name="date-assmeblee"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                />
                                            </div>

                                            <div className="col-12 col-lg-6 mb-3">
                                                <label htmlFor="montant-dette" className="large fw-bolder mb-2"
                                                >Montant de la dette</label >
                                                <input
                                                    type="number"
                                                    value={montantDette}
                                                    name="montant-dette"
                                                    className="form-control text-secondary" style={{ height: "50px" }}
                                                    onChange={(e) => setMontantDette(e.target.value)}

                                                />
                                            </div>


                                        </div>
                                        <div className='col-12 col-lg-6 mb-3'>
                                            <label className="form-check-label mb-2" htmlFor="admin-oui">
                                                Statut
                                            </label>
                                            <div className="form-check">
                                                <Input className="form-check-input" type="radio" name="status" id="actif-oui"
                                                    value={true}
                                                    onChange={(e) => setStatus(e.target.value === "true")}
                                                    
                                                />
                                                <label className="form-check-label" htmlFor="actif-oui">
                                                    Actif
                                                </label>
                                            </div>
                                            <div className="form-check mb-2">
                                                <Input className="form-check-input" type="radio" name="status" id="archive-non"
                                                    value={false}
                                                    onChange={(e) => setStatus(e.target.value === "false")}
                                                    
                                                /> 
                                                <label className="form-check-label" htmlFor="archive-non">
                                                    Archivé
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-6 mb-3 ' controlId="file-upload">
                                            <label className='mb-3'>
                                                Sélectionnez ou déposez des fichiers ici
                                            </label>
                                            <Input type="file"
                                                multiple
                                                onChange={handleFileSelect}
                                                onDrop={handleFileDrop}
                                            />
                                        </div>
                                        {selectedFiles.length > 0 && (
                                            <ul>
                                                {selectedFiles.map((file, index) => (
                                                    <div key={file.index} className='file-preview d-flex'>
                                                        <li>{file.name}</li>
                                                    
                                                            <img src={URL.createObjectURL(file)} alt={file.name} style={{ maxWidth: '30%', maxHeight: '100px' }} />
                                                        
                                                      
                                                        <Button className='btn btn-danger ms-2 p-1 tex-small mb-2 ' onClick={() => {

                                                            const newFiles = [...selectedFiles]
                                                            newFiles.splice(index, 1)
                                                            setSelectedFiles(newFiles)
                                                        }}>
                                                            <i class="bi bi-trash-fill"></i>
                                                            Supprimer</Button>
                                                    </div>
                                                ))}
                                            </ul>
                                        )}
                                        <div >
                                            <Button className="btn_custom col-2 d-flex justify-content-center "
                                                style={{ backgroundColor: "#5e72e4" }}>Valider
                                            </Button>
                                        </div>
                                        <ToastContainer />
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>

                    <Footer />
                </div>

            </div>
        )
    } else {
        return null
    }
}

export default AddFolder