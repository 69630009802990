import axios from "axios"
import jwtDecode from "jwt-decode"
export const API_URL = 'http://api.elbaz-associes.com/api/'



export const AGENCES_API = API_URL + 'agences'
export const DOSSIERS_API = API_URL + 'dossiers'
export const USERS_API = API_URL + "users"
export const MESSAGES_API = API_URL + "message_types"
export const MESSAGERIE_API = API_URL + 'dossier_logs'
export const MEDIA_API = API_URL + 'media_objects'
export const DOSSIERLOGS_API = API_URL + 'dossiers_logs'
export const DOSSIERS_TYPE = API_URL + 'dossiers/types'
export const RESET_PASSWORD = API_URL + 'users/reset-password'
export const SEND_MAIL_SUPPORT = API_URL + 'message/envoyer'
export const MAIL_ADMIN = 'fmienagata@gmail.com'



 export const api = axios.create({
    baseURL: API_URL,
})
// to check if token is expired before doing a request
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token')

        if (token) {
            const decodedToken = jwtDecode(token)
            const now = Date.now() / 1000
            // console.log("hour now: " + now)
            // console.log("token hour: ", decodedToken)

            if (now > decodedToken.exp) {
                // console.log("token expire")
                localStorage.removeItem('token')
                window.location.reload()
            } else {
                config.headers.Authorization = `Bearer ${token}`
            }
        }

        return config;
    },
    (error) => Promise.reject(error)
)
