import React from 'react'
import Fullcalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import Footer from './Footer'
import Sidebar from './Sidebar'
import SidebarUser from './user_normal/SidebarUser'
import jwt_decode from "jwt-decode"
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Topbar from './Topbar'
import allLocales from '@fullcalendar/core/locales-all'
import { FormGroup, Modal } from 'react-bootstrap'
import { Input } from 'reactstrap'
import { findAllFoldersLogs } from '../features/messageries/messagerieSlice'
import moment from 'moment'
import 'moment-timezone'

const Agenda = () => {

    
    const [showModalEvent, setShowModalEvent] = useState(false)
    const [authToken, setAuthToken] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const token = localStorage.getItem('token')
    const decodedToken = token ? jwt_decode(token) : null
    const [showEvent, setShowEvent] = useState()
    
    const { allFoldersLogs } = useSelector((state) => state.messagerie)


     
    const handleDateSelect = (arg) => {
      
        setShowEvent({
            reference: arg.event._def.title,
            message: arg.event._def.extendedProps.message
        })
        setShowModalEvent(true)      
      
    } 
    useEffect(() => {
        if (localStorage.getItem('token')) {
            setAuthToken(localStorage.getItem('token'))
            dispatch(findAllFoldersLogs(decodedToken))


        } else {
            navigate("/")
        }
    }, [authToken, navigate, dispatch])
  
    const eventData = allFoldersLogs?.map((folder) => {
        return { title: folder?.dossier?.refDossier, start: folder?.dateAudience, message: folder?.message}
    })

    const eventDataUpdate = eventData
        ?.map((item) => {
            if (item.start === undefined) {
                return null; // Exclut les objets avec 'start' égal à 'undefined'
            }
            const start = moment(item.start).tz('Europe/Paris').format('YYYY-MM-DD')
            return { ...item, start }
        })
        .filter(Boolean); // Supprime les valeurs 'null' du tableau
    
   if (authToken) {
       return (
           <div className='dashboard_wrapper'>
               {
                   decodedToken?.roles.length > 1 ? <Sidebar /> : <SidebarUser />
               }
               <div className='main_content'>
                   <Topbar />
                   <div className='content'>
                       <div className="content_header text-light">
                           <h1 className="fs-2 kanit-font">Agenda</h1>                          
                       </div>
                       <div className='bg-white p-2'>
                           <Fullcalendar
                               plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                               initialView={"dayGridMonth"}
                               locales={allLocales}
                               locale={'fr'} 
                               headerToolbar={{
                                  start: "today prev,next", // will normally be on the left. if RTL, will be on the right
                                   center: "title",
                                   end: "dayGridMonth,timeGridWeek,timeGridDay", // will normally be on the right. if RTL, will be on the left
                               }}
                               eventContent={(arg) => {
                                   return (
                                       <div className="fc-event-main" style={{ backgroundColor: "green" }}>
                                           <div className="fc-event-title-container">
                                               <div className="fc-event-title fc-sticky">{arg.event.title}</div>
                                           </div>
                                       </div>
                                   );
                               }}
                               events={eventDataUpdate}
                               height={"90vh"}
                               eventClick={handleDateSelect}
                               eventClassNames="cursor-pointer hoverable"
                           />
                        
                       </div>
                       <Modal show={showModalEvent} onHide={() => setShowModalEvent(false)}>
                           <Modal.Header closeButton>
                           </Modal.Header>
                           <Modal.Body>
                           
                               <FormGroup>
                                   <label className="form-control-label">
                                       La référence du dossier
                                   </label>
                                   <Input
                                       className="form-control-alternative edit-event--title"
                                       readOnly="readOnly"
                                       type="text"
                                       value={showEvent?.reference}
                                       
                                   />
                               </FormGroup>
                               <label className="form-control-label">
                                   Message
                               </label>
                                   <div className="col-12 col-lg-12 mb-3">
                                       <textarea className="form-control mt-2" id="exampleFormControlTextarea1" rows="6"
                                       value={showEvent?.message}
                                       readOnly="readOnly"
                                       ></textarea>
                                   </div>
                                   
                                  
                            
                           </Modal.Body>
                       </Modal>

                   </div>

                   <Footer />
               </div>

           </div>

       )
   } else {
       return null
   }
}

export default Agenda