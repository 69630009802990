import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import jwtDecode from "jwt-decode"
import { AGENCES_API, API_URL, USERS_API } from "../../config"

const api = axios.create({
    baseURL: API_URL,
})
// to check if token is expired before doing a request
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token')

        if (token) {
            const decodedToken = jwtDecode(token)
            const now = Date.now() / 1000
            // console.log("hour now: " + now)
            // console.log("token hour: ", decodedToken)

            if (now > decodedToken.exp) {
                // console.log("token expire")
                localStorage.removeItem('token')
                window.location.reload()
            } else {
                config.headers.Authorization = `Bearer ${token}`
            }
        }

        return config;
    },
    (error) => Promise.reject(error)
)

export const findAllAgences = createAsyncThunk(
    "gets/findAllAgences",
    async ( thunkApi) => {
   
  
    try {
       // const token = localStorage.getItem('token')
        const response = await api.get(AGENCES_API + '/?timestamp=' + new Date().getTime())
        
       
        return response.data['hydra:member']
    } catch (error) {
        return thunkApi.rejectWithValue(error.message)
    }
    })
export const findAgence = createAsyncThunk(
    "gets/findAgence",
    async (id ,thunkApi) => {
    try {
       // const token = localStorage.getItem('token')
        const response = await api.get(AGENCES_API + '/' + id + '/?timestamp=' + new Date().getTime())
    
        return response.data
    } catch (error) {
        return thunkApi.rejectWithValue(error.message)
    }
    })

export const addAgence = createAsyncThunk(
    "posts/addAgence",
    async (agenceData, thunkApi) => {
        try {
           // const token = localStorage.getItem('token')
            const response = await api.post(AGENCES_API,agenceData)
            return response.data
        } catch (error) {
          
            return thunkApi.rejectWithValue(error.message)
        }
    }
)
export const updateAgence = createAsyncThunk(
    "puts/updateAgence",
    async (agenceData, thunkApi) => {
     
        try {
            const response = await api.put(AGENCES_API + '/' + agenceData.id, agenceData)
            return response.data
        } catch (error) {

            return thunkApi.rejectWithValue(error.message)
        }
    }
)
export const usersByAgence = createAsyncThunk(
    "gets/usersByAgence",
    async (agence, thunkApi) => {
        try {
          
            //const token = localStorage.getItem('token')
            const response = await api.get(USERS_API, {
                params: { agence: agence['id'] },
            })
           //  console.log(response)
            return response.data['hydra:member']
        } catch (error) {

            return thunkApi.rejectWithValue(error)
        }
     }
)
    
export const countAllAgences = createAsyncThunk(
    "gets/countAllAgences",
    async (thunkApi) => {
       // const token = localStorage.getItem("token")
        try {
            const response = await api.get(AGENCES_API + '/count')
            // console.log("count ", response)
            return response.data
        } catch (error) {
            //console.log(error)
            return thunkApi.rejectWithValue(error.message)
        }
    }
)

const initialState = {
    allAgences: [],
    usersByAgences: [],
    loading: false,
    oneAgence: {},
    numberAgence: null,
     error: null,
}

const agenceSlice = createSlice({
    name: "agences",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(findAllAgences.pending, (state, action) => {
             state.loading = true
            })
            .addCase(findAllAgences.fulfilled, (state, action) => { 
                
                state.allAgences = action.payload
            })
            .addCase(findAllAgences.rejected, (state, action) => { 
                     state.error = action.payload
            })
            .addCase(findAgence.pending, (state, action) => {
             state.loading = true
            })
            .addCase(findAgence.fulfilled, (state, action) => { 
                
                state.oneAgence = action.payload
            })
            .addCase(findAgence.rejected, (state, action) => { 
                     state.error = action.payload
            })
            .addCase(addAgence.pending, (state, action) => {
             state.loading = true
            })
            .addCase(addAgence.fulfilled, (state, action) => { 
                
                state.allAgences.push(action.payload)
            })
            .addCase(addAgence.rejected, (state, action) => { 
                     state.error = action.payload
            })
            .addCase(updateAgence.pending, (state, action) => {
             state.loading = true
            })
            .addCase(updateAgence.fulfilled, (state, action) => {
                const updateAgence = action.payload
                const index = state.allAgences.findIndex(a => a.id === updateAgence.id)
                 if (index !== -1) {
                    state.allAgences[index] = updateAgence
                 }
            })
            .addCase(updateAgence.rejected, (state, action) => { 
                     state.error = action.payload
            })
            .addCase(usersByAgence.pending, (state, action) => {
             state.loading = true
            })
            .addCase(usersByAgence.fulfilled, (state, action) => {
                state.usersByAgences = action.payload
            })
            .addCase(usersByAgence.rejected, (state, action) => { 
                     state.error = action.payload
            })
            .addCase(countAllAgences.pending, (state, action) => {
                state.loading = true
            })
            .addCase(countAllAgences.fulfilled, (state, action) => {
                state.loading = false
                state.numberAgence = action.payload
            })
            .addCase(countAllAgences.rejected, (state, action) => {
                state.error = action.payload
            })
      }
 })

export default agenceSlice.reducer